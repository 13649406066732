import 'react-dat-gui/dist/index.css';
import '../css/dat-gui.css';
import React, {Component} from 'react';
import {useStore} from "./store";

// eslint-disable-next-line
import DatGui, {
	DatBoolean,
	DatButton,
	DatColor,
	DatFolder,
	DatNumber,
	DatPresets,
	DatSelect,
	DatString
} from 'react-dat-gui';

/**
 * Demonstrates presets that extend the default preset (initial state)
 * as well as presets which extend the current state
 */
class DatGUI extends Component {
	constructor(props) {
		super(props);

		const initialState = {
			collapseGUI: false,
			lastClickedButton: null,
			toggleMapinfo: false,
			toggleLabel: true,
			toggleBunkers: false,
			toggleBunkersD3: false,
			toggleBunkersRadius: false,
			toggleBunkersRadiusUnion: false,
			toggleBunkersPoly: false,
			toggleBunkersDynamic: false,
			toggleBunkersSVG: false,
			toggleBunkersSVGvariants: false,
			toggleBunkersCircleAll: true,
			toggleBunkersCircle: true,
			toggleContour: true,
			toggleSatellite: false,
			toggleLabels: false,
			opacityAllNotIT: 0.4,
			opacityITnotST: 0.5,
			opacitySperren: 0.0,
			opacityVallo: 0.0,
			opacitySatellite: 0.0,
			animationSpeed: 1.0,
			number: 80,
			number1: 40,
			number2: 60,
			select: 'one',
			color: '#2FA1D6',
			random: 0,
			randomUpdate: 0,
			string: 'Hello World',
			nested: {
				string: 'Nested Hello World'
			},
			toggleStroke_desc: useStore.getState().stroke_desc,
			toggleStroke_sel: useStore.getState().stroke_sel,

			toggleColors_notbuilt: useStore.getState().colors_notbuilt,
			toggleColors_built: useStore.getState().colors_built,
			toggleColor0: useStore.getState().colors_0,
			toggleColors1: useStore.getState().colors_1,
			toggleColors2: useStore.getState().colors_2,
			toggleColors3: useStore.getState().colors_3,

			toggleStroke_notbuilt: useStore.getState().stroke_notbuilt,
			toggleStroke_built: useStore.getState().stroke_built,
			toggleStroke0: useStore.getState().stroke_0,
			toggleStroke1: useStore.getState().stroke_1,
			toggleStroke2: useStore.getState().stroke_2,
			toggleStroke3: useStore.getState().stroke_3,
		};

		this.state = {
			showGUI: false,
//			showGUI: true,
			data: initialState,
			defaultData: initialState
		};
	}

	// Update random number for current state
	handleButtonClose = (d) => {
//		console.log("close button clicked");

		let toggle = this.state.showGUI ? false : true;

		document.getElementsByClassName("openMenu")[0].style.visibility = this.state.showGUI ? 'visible' : 'hidden';
		document.getElementsByClassName("openMenu")[0].style.height = this.state.showGUI ? '16px' : '0px';

		this.setState({showGUI: toggle, random: Math.random()})
	}

	handleButtonClick = (d) => {
//		console.log("button clicked:", d);
		this.props.dataCallbackButton(d);
		this.setState(prevState => ({
			data: {...prevState.data, random: Math.random(), lastClickedButton: d}
		}))
	};

	// Update current state with changes from controls
	handleUpdate = newData => {
		this.props.dataCallback(newData);
		this.setState(prevState => ({
			data: {...prevState.data, ...newData}
		}))
	};

	handleUpdateFromApp = newData => {
		this.setState(prevState => ({
			data: {...prevState.data, ...newData}
		}))

	}

	componentDidMount() {
		this.handleUpdate(this.state.data);
		if (this.state.showGUI === true) {
			document.getElementsByClassName("openMenu")[0].style.visibility = 'hidden';
			document.getElementsByClassName("openMenu")[0].style.height = '1px';
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.guiData.collapseGUI && this.props.guiData.collapseGUI !== prevProps.guiData.collapseGUI) {
			if (this.props.guiData.collapseGUI === true) {
				this.handleButtonClose();
			}
		}

		// update datGUI from fetch via APP
		if (this.props.guiData.randomUpdate !== undefined && this.props.guiData.randomUpdate !== 0
			&& prevProps.guiData.randomUpdate !== this.props.guiData.randomUpdate) {

//			console.log("update GUI data from APP");
			this.handleUpdateFromApp(this.props.guiData);
			this.setState({randomUpdate: this.props.guiData.randomUpdate})
		}

//		console.log("GUI updated", this.props);
	}

	render() {

//		console.log('this.state.showGUI: ',this.state.showGUI);

		const {data, defaultData} = this.state;
		// eslint-disable-next-line
		const presets = [
			// Preset A doesn't extend any other presets
			{
				A: {
					string: 'Preset A',
					minMaxNumber: 33,
					number: 40,
					toggleBunkers: false,
					select: 'one',
					color: '#e61d5f',
					random: Math.random(),
					nested: {
						string: 'Nested Preset A'
					}
				}
			},
			{
				B: {
					string: 'Preset B',
					minMaxNumber: 12,
					number: 68,
					toggleBunkers: true,
					select: 'three',
					color: '#2FD654',
					random: Math.random(),
					nested: {
						string: 'Nested Preset B'
					}
				}
			},
			// Preset C extends the default preset
			{'C (extends Default)': {...defaultData, string: 'Preset C'}},
			// Preset D extends the current state
			{'D (extends current state)': {...data, string: 'Preset D'}}
		];

		return (

//			<DatGUI labelWidth="40%">
//				<DattoggleBunkers labelWidth="200px" />
//			</DatGUI>

			<main style={{marginRight: '350px', marginTop: '10px'}}>
				{/*<Stats data={data} />*/}
				{this.state.showGUI === true && this.props.debug > -1 &&
					<DatGui data={data} onUpdate={this.handleUpdate} labelWidth="50%">
						{/*<DatPresets*/}
						{/*	label="Presets"*/}
						{/*	options={presets}*/}
						{/*	onUpdate={this.handleUpdate}*/}
						{/*/>*/}
						<DatButton
							className="quickTour"
							style={{
								textAlign: 'center',
								background: '#6b6b6b',
								fontSize: '1.5em',
								opacity: 1,
								borderLeft: '0px solid #f0f'
							}}
							label="Quicktour"
							onClick={() => this.handleButtonClick('Quicktour')}
						/>
						<DatNumber
							path="animationSpeed"
							label="animationspeed"
							min={0}
							max={2}
							step={0.1}
						/>
						<DatFolder title="set opacity of layers" closed={true}>
							<DatBoolean path="toggleSatellite" label="show satellite"/>
							<DatNumber
								path="opacitySatellite"
								label="opacity Satellite"
								min={0}
								max={1}
								step={0.01}
							/>
							<DatBoolean path="toggleLabels" label="show bunker labels"/>
							<DatNumber
								path="opacityAllNotIT"
								label="opacity all but not italy"
								min={0}
								max={1}
								step={0.01}
							/>
							<DatNumber
								path="opacityITnotST"
								label="opacity italy but not ST"
								min={0}
								max={1}
								step={0.01}
							/>
							<DatNumber
								path="opacitySperren"
								label="opacity image Sperren"
								min={0}
								max={1}
								step={0.01}
							/>
							<DatNumber
								path="opacityVallo"
								label="opacity image Vallo"
								min={0}
								max={1}
								step={0.01}
							/>
						</DatFolder>
						<DatFolder title="show layers" closed={true} labelWidth="80%">
							<DatBoolean path="toggleMapinfo" label="show Lng, Lat & Zoom"/>
							<DatBoolean path="toggleLabel" label="show bunker labels at zoom > 13"/>
							<DatBoolean path="toggleBunkersDynamic" label="show dynamic bunkers-SVGs in timeline"/>
							<DatBoolean path="toggleBunkers" label="show bunkers (with tooltip)"/>
							<DatBoolean path="toggleBunkersD3" label="show bunkers D3"/>
							<DatBoolean path="toggleBunkersRadius" label="show bunkers shooting range overlay"/>
							<DatBoolean path="toggleBunkersRadiusUnion" label="show bunkers shooting range merged"/>
							<DatBoolean path="toggleBunkersSVG" label="show bunkers SVG"/>
							<DatBoolean path="toggleBunkersSVGvariants" label="show bunkers SVG variants"/>
							<DatBoolean path="toggleBunkersPoly" label="show bunkers poly"/>
							<DatBoolean path="toggleBunkersCircleAll" label="show All bunkers circle"/>
							<DatBoolean path="toggleBunkersCircle" label="show timeslider-selected bunkers circle"/>
							<DatBoolean path="toggleContour" label="show contourline"/>
						</DatFolder>
						<DatFolder title="colors" closed={true} labelWidth="80%">
							<DatColor path='toggleStroke_desc' label='with desc stroke'/>
							<DatColor path='toggleStroke_sel' label='selected stroke'/>
							<DatColor path='toggleColor0' label='kein fill'/>
							<DatColor path='toggleStroke0' label='kein stroke'/>
							<DatColor path='toggleColors1' label='geplant fill'/>
							<DatColor path='toggleStroke1' label='geplant stroke'/>
							<DatColor path='toggleColors2' label='begonnen fill'/>
							<DatColor path='toggleStroke2' label='begonnen stroke'/>
							<DatColor path='toggleColors3' label='fertig fill'/>
							<DatColor path='toggleStroke3' label='fertig stroke'/>
							<DatColor path='toggleColors_built' label='akt Baubeginn fill'/>
							<DatColor path='toggleStroke_built' label='akt Baubeginn stroke'/>
							<DatColor path='toggleColors_notbuilt' label='Bau fortg./geplant fill'/>
							<DatColor path='toggleStroke_notbuilt' label='Bau fortg./geplant stroke'/>
						</DatFolder>
						{/*<DatFolder title="misc" closed={true}>*/}
						{/*	<DatButton*/}
						{/*		className="drawData"*/}
						{/*		style={{*/}
						{/*			textAlign: 'center',*/}
						{/*			background: '#6b6b6b',*/}
						{/*			fontSize: '1.5em',*/}
						{/*			opacity: 1,*/}
						{/*			borderLeft: '0px solid #f0f'*/}
						{/*		}}*/}
						{/*		label="alert draw data"*/}
						{/*		onClick={() => this.handleButtonClick('drawData')}*/}
						{/*	/>*/}
						{/*</DatFolder>*/}

						{/*/>*/}
						{/*<DatString path="string" label="String"/>*/}
						{/*<DatNumber path="number1" label="Number1"/>*/}
						{/*<DatNumber path="number2" label="Number2"/>*/}
						{/*<DatSelect*/}
						{/*	label="Select"*/}
						{/*	path="select"*/}
						{/*	options={['two', 'three', 'four']}*/}
						{/*/>*/}
						{/*<DatColor label="Color" path="color"/>*/}
						{/*<DatFolder title="Folder">*/}
						{/*	<DatString path="string" label="String"/>*/}
						{/*	<DatNumber*/}
						{/*		path="minMaxNumber"*/}
						{/*		label="Number"*/}
						{/*		min={0}*/}
						{/*		max={100}*/}
						{/*		step={1}*/}
						{/*	/>*/}
						{/*	<DatFolder title="Nested Folder">*/}
						{/*		<DatNumber*/}
						{/*			path="minMaxNumber"*/}
						{/*			label="Number"*/}
						{/*			min={0}*/}
						{/*			max={100}*/}
						{/*			step={1}*/}
						{/*		/>*/}
						{/*		<DatString path="nested.string" label="String"/>*/}
						{/*		<DatFolder title="Another Nested Folder">*/}
						{/*			<DatColor label="Color" path="color"/>*/}
						{/*			<DatString path="nested.string" label="Nested String"/>*/}
						{/*		</DatFolder>*/}
						{/*	</DatFolder>*/}
						{/*</DatFolder>*/}
						<DatButton
							className="closeMenu"
							style={{
								textAlign: 'center',
								background: '#000',
								fontSize: '1em',
								opacity: 0.6,
								borderLeft: '0px solid #f0f'
							}}
							label="&#x25B2;"
							onClick={() => this.handleButtonClose('closebutton')}
						/>
					</DatGui>
				}
				{this.props.debug === 1 &&
					<DatGui labelWidth="40px">
						<DatButton
							className="openMenu"
							style={{
								textAlign: 'center',
								background: '#000',
								fontSize: '1em',
								opacity: 0.4,
								borderLeft: '0px solid #f0f',
								width: "30px",
								marginLeft: "0%"
							}}
							label="&#x25BC;"
							onClick={() => this.handleButtonClose('closebutton')}
						/>
					</DatGui>
				}

			</main>
		);
	}
}

export default DatGUI;
