import React from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from "@turf/turf";
import {useStore} from "./store";

//import {toUnique} from "../helpers";

class Bunkers extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mapLoaded: false,
			currDataLoaded: false,
			initDone: false
		}
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		const {map} = this.props.states;

//		const setSelected = useStore(state => state.setSelected);

		if (this.props.states.mapLoaded !== prevProps.states.mapLoaded && this.props.states.mapLoaded === true) {
			this.setState({mapLoaded: true});
		}

		if (prevProps.states.currData !== this.props.states.currData) {
			this.setState({currDataLoaded: true});
		}

		// ==================================================================================
		//  DATA and MAP are loaded, starting to add layers & layout
		// ==================================================================================

		if (this.state.initDone === false && this.state.mapLoaded === true && this.state.currDataLoaded === true) {

			// bunker2.svg somewhere in the middle
			map.addSource('point', {
				'type': 'geojson',
				'data': {
					'type': 'FeatureCollection',
					'features': [
						{
							'type': 'Feature',
							'geometry': {
								'type': 'Point',
								'coordinates': [11.12, 46.62]
							}
						}
					]
				}
			});

			const data = this.props.states.currData;

			var features = data.map((d) => {
				let bunker = {type: 'Feature'};
				let b_year = d.built_year ? parseInt(d.built_year) : 0;
				let b_month = d.built_month ? parseInt(d.built_month) : 0;
				let b = b_year+b_month/12;

				bunker.properties = {
					icon: 'stadium',
					built: b,
					id: d.draw_id,
					db_id: d.id,
					desc_cnt: d.numOfDesc !== '0' ? 1 : 0,
					progress: d.progress ? d.progress : 0,
					title: d.title,
					description: "<strong>Bunker " + d.id + "</strong> <a href='http://www.mindfactor.at' target='_blank' title='Opens in a new window'>" + d.title + "</a> Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>",
				}
				bunker.geometry = {
					type: 'Point',
					coordinates: [parseFloat(d.longi), parseFloat(d.lati)]
				}
				return bunker;
			});


			//console.log("features: ",features);


			let featuresPoly = data.map((d) => {
//				let offsetLo = 0.03;
				let offsetLo = 0.015;
				let offsetLa = offsetLo * 0.7;
				let bunker = {type: 'Feature'};

				let b_year = d.built_year ? parseInt(d.built_year) : 0;
				let b_month = d.built_month ? parseInt(d.built_month) : 0;

				let b = b_year+b_month/12;

				//console.log(b);

				bunker.properties = {
//					built: d.built_year+d.built_month/12
					built: b
//					built: 1940
				}
				bunker.geometry = {
					type: 'Polygon',
					coordinates:
						[[
							[parseFloat(d.longi) - offsetLo, parseFloat(d.lati) - offsetLa],
							[parseFloat(d.longi) - offsetLo, parseFloat(d.lati) + offsetLa],
							[parseFloat(d.longi) + offsetLo, parseFloat(d.lati) + offsetLa],
							[parseFloat(d.longi) + offsetLo, parseFloat(d.lati) - offsetLa],
							[parseFloat(d.longi) - offsetLo, parseFloat(d.lati) - offsetLa]
						]]
				}
				return bunker;
			});

			// console.log("features poly: ",featuresPoly);

			// ==================================================================================
			// add bunker markers
			// ==================================================================================

			var bunkerFeatureColl = {'type': 'FeatureCollection', 'features': features};

			map.addSource('bunker', {
				'type': 'geojson',
				'cluster': false,
				'clusterMaxZoom': 12, // Max zoom to cluster points on
				'clusterRadius': 1, // Radius of each cluster when clustering points (defaults to 50)
				'data': {
					'type': 'FeatureCollection',
					'features': features
				}
			});

			map.addLayer({
				'id': 'bunker-icons',
				'type': 'symbol',
				'source': 'bunker',
				'layout': {
					'icon-image': '{icon}-15',
//					'icon-allow-overlap': true,
					"icon-ignore-placement": true,
					'icon-size': 1.1
				},
				'paint': {
					'icon-color': '#59ff00'
				}
			});

//			map.setLayoutProperty('bunker-icons', 'visibility', 'visible');
			map.setLayoutProperty('bunker-icons', 'visibility', 'none');

			// ==================================================================================
			// add bunker polylayers
			// ==================================================================================

			map.addSource('poly', {
				'type': 'geojson',
				'data': {
					'type': 'FeatureCollection',
					'features': featuresPoly
				}
			});
			map.addLayer({
				'id': 'bunker-poly',
				'type': 'fill',
				'source': 'poly',
				'layout': {},
				'paint': {
					'fill-color': '#ff0000',
					'fill-opacity': 0.05
				}
			});

			map.setLayoutProperty('bunker-poly', 'visibility', 'none');


			// ==================================================================================
			// add bunker circles
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle-all',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 2],[8, 3], [11, 5], [16, 8]]
					},
					'circle-opacity': 1,
//					'circle-color': '#ff4d2f'
					'circle-color': useStore.getState().colors_notbuilt,
//					'circle-color': '#d67b0e',
//					'circle-color': '#883333',
//					'circle-stroke-color': '#772d2d',
					'circle-stroke-color': useStore.getState().colors_notbuilt,
					'circle-stroke-width': 0.5,
//					'circle-color': '#fff600'
//					'circle-color': '#b20025'
//					'circle-color': '#74006d'
//					'circle-color': '#7a6f47'
//					'circle-color': '#457a58'
				}
			});

//			map.setLayoutProperty('bunker-circle-all', 'visibility', 'visible');
			map.setLayoutProperty('bunker-circle-all', 'visibility', 'none');

			// ==================================================================================
			// add bunker circles
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 2],[8, 3], [11, 5], [16, 8]]
					},
					'circle-opacity': 1,
//					'circle-color': '#ff4d2f'
//					'circle-color': '#f78c0d'
					'circle-color': useStore.getState().colors_built,
					'circle-stroke-color': useStore.getState().stroke_built,
//					'circle-stroke-color': '#cefc08',
					'circle-stroke-width': 0.5,
//					'circle-color': '#fff600'
//					'circle-color': '#b20025'
//					'circle-color': '#74006d'
//					'circle-color': '#7a6f47'
//					'circle-color': '#457a58'
				}
			});

			map.setLayoutProperty('bunker-circle', 'visibility', 'none');

			// ==================================================================================
			// add bunker circles progress 0
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle-p0',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 2],[8, 3], [11, 5], [16, 8]]
					},
					'circle-opacity': 1,
					'circle-color': useStore.getState().colors_0,
					'circle-stroke-color': useStore.getState().stroke_0,
					'circle-stroke-width': 0.5,
				}
			});

			map.setLayoutProperty('bunker-circle-p0', 'visibility', 'none');

			// ==================================================================================
			// add bunker circles progress 1
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle-p1',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 2],[8, 3], [11, 5], [16, 8]]
					},
					'circle-opacity': 1,
					'circle-color': useStore.getState().colors_1,
					'circle-stroke-color': useStore.getState().stroke_1,
					'circle-stroke-width': 0.5,
				}
			});

			map.setLayoutProperty('bunker-circle-p1', 'visibility', 'none');

			// ==================================================================================
			// add bunker circles progress 2
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle-p2',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 2],[8, 3], [11, 5], [16, 8]]
					},
					'circle-opacity': 1,
					'circle-color': useStore.getState().colors_2,
					'circle-stroke-color': useStore.getState().stroke_2,
					'circle-stroke-width': 0.5,
				}
			});

			map.setLayoutProperty('bunker-circle-p2', 'visibility', 'none');

			// ==================================================================================
			// add bunker circles progress 3
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle-p3',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 2],[8, 3], [11, 5], [16, 8]]
					},
					'circle-opacity': 1,
					'circle-color': useStore.getState().colors_3,
					'circle-stroke-color': useStore.getState().stroke_3,
					'circle-stroke-width': 0.5,
				}
			});

			map.setLayoutProperty('bunker-circle-p3', 'visibility', 'none');


			map.setFilter('bunker-circle-p0',
				['all',
					['>=', ['to-number', ['get', 'progress']], ['literal', -1]],
				]
			);

			map.setFilter('bunker-circle-p1',
				['all',
					['==', ['to-number', ['get', 'progress']], ['literal', 1]],
				]
			);

			map.setFilter('bunker-circle-p2',
				['all',
					['==', ['to-number', ['get', 'progress']], ['literal', 2]],
				]
			);

			map.setFilter('bunker-circle-p3',
				['all',
					['==', ['to-number', ['get', 'progress']], ['literal', 3]],
				]
			);





			// ==================================================================================
			// add bunker circles
			// ==================================================================================

			map.addLayer({
				'id': 'bunker-circle-outline',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 7],[8, 9], [11, 12], [16, 18]]
					},
					'circle-opacity': 0,
					'circle-stroke-color': useStore.getState().stroke_sel,
					'circle-stroke-width': 4,
					'circle-stroke-opacity': 0,
					"circle-stroke-opacity-transition": {
						"duration": 1000,
						"delay": 0
					},
				}
			});

			map.setLayoutProperty('bunker-circle-outline', 'visibility', 'visible');
//			map.setLayoutProperty('bunker-circle-all', 'visibility', 'none');

			// circle around bunkers with description
			map.addLayer({
				'id': 'bunker-circle-outline-desc',
				'type': 'circle',
				'source': 'bunker',
				'layout': {},
				'paint': {
					'circle-radius': {
						stops: [[3, 4],[8, 7], [11, 8], [16, 12]]
					},
					'circle-opacity': 0,
					'circle-stroke-color': useStore.getState().stroke_desc,
					'circle-stroke-width': 1.5,
//					'circle-stroke-opacity': useStore.getState().opacity_desc,
					"circle-stroke-opacity-transition": {
						"duration": 1000,
						"delay": 0
					},
				}
			});

			map.setLayoutProperty('bunker-circle-outline-desc', 'visibility', 'visible');
			map.setFilter('bunker-circle-outline-desc', ['!=', ['get', 'desc_cnt'], 0]);

//			map.setLayoutProperty('bunker-circle-all', 'visibility', 'none');

			// click handler for whole map, catches empty clicks to reset selection
			map.on('click', (e) => {
				e.preventDefault();
//				console.log(`A click event has occurred at ${e.lngLat}`);
				map.setPaintProperty('bunker-circle-outline', 'circle-stroke-opacity', 0);
				useStore.getState().setSelected(-1);
			});

			// ==================================================================================
			// bunker labels
			// ==================================================================================


			map.addLayer({
				"id": "bunker-label",
				"type": "symbol",
				"source": "bunker",
				"minzoom": 12, // Set zoom level to whatever suits your needs
				"layout": {
					"text-field": "{title}",
					"text-font": [
						"DIN Offc Pro Medium",
						"Arial Unicode MS Bold"
					],
//					"text-size": 10,
//					'text-size': ['step', ['get', 'point_count'], 18, 10, 14, 100, 12],
					'text-offset': [0, 1],
					"text-anchor": "top",
					"text-size": {
						"stops": [
							[0, 0],
							[13.5, 0],
							[14, 10]
						]
					}

				}
			});

			map.setLayoutProperty('bunker-label', 'visibility', 'visible');

			// ==================================================================================
			// bunker sidebar on circles
			// ==================================================================================

			// Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
			map.on('mouseenter', 'bunker-circle-all', () => {
				map.getCanvas().style.cursor = 'pointer';
			});

			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'bunker-circle-all', () => {
				map.getCanvas().style.cursor = '';
			});

			// Change it back to a pointer when it leaves.
			map.on('click', 'bunker-circle-all', (e) => {
				e.preventDefault();
//				console.log(map, useStore.getState(), this.props)
//				console.log('b=',map.getBearing(), 'p=', map.getPitch())
//				console.log(this.props)
//				const camera = map.getFreeCameraOptions();
//				const cameraPosition = camera._position.toLngLat();
//				console.log(cameraPosition)

				var coordinates = e.features[0].geometry.coordinates.slice();
				var description = e.features[0].properties.description;
				var draw_id = e.features[0].properties.id;

				const selected = data.find((d)=>d.draw_id === draw_id);

				map.setPaintProperty(
					'bunker-circle-outline',
					'circle-stroke-opacity',
					['match', ['get', 'id'], draw_id, 1 , 0.0]
				)

				useStore.getState().setSelected(selected);

				// elevation
				const elevation = map.queryTerrainElevation(coordinates);
//				console.log('elevation', elevation)
				useStore.getState().setSelectedElevation(elevation);

				// get nearest point: works, but does not remove self
//				var targetPoint = turf.point(coordinates);
//				const nearestBunker = turf.nearest(targetPoint, bunkerFeatureColl);
//				console.log('nearest:',nearestBunker)

				this.props.bunkerCallback({sidebarsAction: 'open right'});

//				console.log(selected);
//				console.log(e, draw_id);

			});

//			console.log(data);

			// ==================================================================================
			// bunker sidebar on SVG
			// ==================================================================================

			// Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
			map.on('mouseenter', 'bunker-SVG', () => {
				map.getCanvas().style.cursor = 'pointer';
			});

			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'bunker-SVG', () => {
				map.getCanvas().style.cursor = '';
			});

			map.on('click', 'bunker-SVG', (e) => {
				e.preventDefault();
				var coordinates = e.features[0].geometry.coordinates.slice();
				var description = e.features[0].properties.description;
				var draw_id = e.features[0].properties.id;

				const selected = data.find((d)=>d.draw_id === draw_id);

				map.setPaintProperty(
					'bunker-circle-outline',
					'circle-stroke-opacity',
					['match', ['get', 'id'], draw_id, 1 , 0.0]
				)

				useStore.getState().setSelected(selected);

				// elevation
				const elevation = map.queryTerrainElevation(coordinates);
//				console.log('elevation', elevation)
				useStore.getState().setSelectedElevation(elevation);

				this.props.bunkerCallback({sidebarsAction: 'open right'});

//				console.log(selected);
//				console.log(e, draw_id);

			});


			// ==================================================================================
			// bunker tooltips
			// ==================================================================================

			// When a click event occurs on a feature in the places layer, open a popup at the
			// location of the feature, with description HTML from its properties.
			map.on('click', 'bunker-icons', (e) => {
				e.preventDefault();
				var coordinates = e.features[0].geometry.coordinates.slice();
				var description = e.features[0].properties.description;

				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
				}

				new mapboxgl.Popup()
					.setLngLat(coordinates)
					.setHTML(description)
					.addTo(map);
			});

			// Change the cursor to a pointer when the mouse is over the places layer.
			map.on('mouseenter', 'bunker-icons', (e) => {
				map.getCanvas().style.cursor = 'pointer';

			});

			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'bunker-icons', () => {
				map.getCanvas().style.cursor = '';
			});


			// ==================================================================================
			// add one bunkerradius svg
			// ==================================================================================

			const getSector = (id, obj) => {

//				console.log(obj);

				var center = turf.point([obj.lng, obj.lat]);
				var sector = turf.sector(center, obj.radius, obj.bearing1, obj.bearing2);
				sector.properties.id = id;
				return sector;
			}

			var sectors = [
				{
					id: 1,
					lng: 11.228574563816,
					lat: 46.503645034518,
					radius: 8,
					bearing1: 94.9,
					bearing2: 154.9
				},
				{
					id: 2,
					lng: 11.228574563816,
					lat: 46.503645034518,
					radius: 8,
					bearing1: 108.4,
					bearing2: 168.4
				},
				{
					id: 3,
					lng: 11.228574563816,
					lat: 46.503645034518,
					radius: 1,
					bearing1: 130.8,
					bearing2: 190.8
				},
				{
					id: 4,
					lng: 11.228574563816,
					lat: 46.503645034518,
					radius: 1,
					bearing1: 296.6,
					bearing2: 356.6
				},
			];

//			var sectors2 = [
//				{
//					id: 6,
//					lng: 11.6294,
//					lat: 46.7764,
//					radius: 5,
//					bearing1: 80,
//					bearing2: 140
//				},
//				{
//					id: 7,
//					lng: 11.6294,
//					lat: 46.7764,
//					radius: 2,
//					bearing1: -50,
//					bearing2: -40
//				}
//			];

			var sectors2 = [
				{
					id: 6,
					lng: 11.6294,
					lat: 46.7764,
					radius: 0.1,
					bearing1: 80,
					bearing2: 140
				},
				{
					id: 7,
					lng: 11.6294,
					lat: 46.7764,
					radius: 0.1,
					bearing1: -50,
					bearing2: -40
				}
			];




			// union sectors to one polygon instead of overlay sectors
			var mergedsectors = getSector(1, sectors[0]);

			sectors.map((d, i) => {
				mergedsectors = turf.union(mergedsectors, getSector(d.id, d));
			});

			map.addSource('bunkersRadiusMerged', {
				"type": "geojson",
				"data": mergedsectors
			});

			map.addLayer({
				"id": "bunker-radius-merged",
				"source": "bunkersRadiusMerged",
				"type": "fill",
				"text-allow-overlap": true,
				"paint": {
					"fill-outline-color": '#0747b4',
					"fill-color": "#32c2fb",
					'fill-opacity': 0.3
				}
			});

			// overlay sectors
			var allsectorsArr = [];

			sectors.map((d, i) => {
				allsectorsArr.push(getSector(d.id, d))
			});

			map.addSource('bunkersradius', {
				'type': 'geojson',
				'cluster': false,
//				'clusterMaxZoom': 12, // Max zoom to cluster points on
//				'clusterRadius': 1, // Radius of each cluster when clustering points (defaults to 50)
				'data': {
					'type': 'FeatureCollection',
					'features': allsectorsArr
				}
			});

			map.addLayer({
				"id": "bunker-radius",
				"source": "bunkersradius",
				"type": "fill",
				"text-allow-overlap": true,
				"paint": {
//					"fill-outline-color": '#0747b4',
//					"fill-outline-color": '#b56a01',
					"fill-outline-color": '#883333',
//					'fill-color': '#a990d7',
//					'fill-color': '#fde67a',
//					"fill-color": "#32c2fb",
//					"fill-color": "#e79c06",
					"fill-color": "#d45b37",
//					"fill-color": "#ed1c24",
//					"fill-color": "#ed1c5e",
//					"fill-color": "#db2c2e",
//					"fill-color": "#cf070f",
					'fill-opacity': 0.3
				}
			});
//			}, firstSymbolId);
//			}, choroplethId);

			// overlay sectors
			var moresectorsArr = [];

			sectors2.map((d, i) => {
				moresectorsArr.push(getSector(d.id, d))
			});

			map.addSource('bunkersradius2', {
				'type': 'geojson',
				'cluster': false,
//				'clusterMaxZoom': 12, // Max zoom to cluster points on
//				'clusterRadius': 1, // Radius of each cluster when clustering points (defaults to 50)
				'data': {
					'type': 'FeatureCollection',
					'features': moresectorsArr
				}
			});

			map.addLayer({
				"id": "bunker-radius2",
				"source": "bunkersradius2",
				"type": "fill",
				"text-allow-overlap": true,
				"paint": {
					"fill-outline-color": '#0747b4',
					"fill-color": "#d2510b",
					'fill-opacity': 0.3
				}
			});
//			}, firstSymbolId);
//			}, choroplethId);


			map.setLayoutProperty('bunker-radius', 'visibility', 'none');

			map.setLayoutProperty('bunker-radius2', 'visibility', 'none');

			// ==================================================================================
			// everything is added, time to say it is done!
			// ==================================================================================

			this.props.bunkerCallback({bunkerLoaded: true});
			this.setState({initDone: true});

		}
	}

	render() {
		return (
			<div className='Bunkers'>
			</div>)
	};
}

export default Bunkers
