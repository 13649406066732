import '../css/draw.css';
import React, {useState} from "react";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as d3 from "d3";
import DataGrid from 'react-data-grid';
import {TextEditor} from 'react-data-grid';

// How to add new columns to table:
// add in const columns below, search for XXX
// dont forget to add it database and in backend->editor_model->put_point

const columns = [
	{key: 'id', name: 'ID', sortable: true},
	{key: 'title', name: 'Title', editor: TextEditor, editable: true, sortable: true},
	{key: 'longi', name: 'longitude', editor: TextEditor, editable: false, sortable: true},
	{key: 'lati', name: 'latitude', editor: TextEditor, editable: false, sortable: true},
	{key: 'built_year', name: 'built_year', editor: TextEditor, editable: true, sortable: true},
	{key: 'built_month', name: 'built_month', editor: TextEditor, editable: true, sortable: true},
	{key: 'progress', name: 'progress', editor: TextEditor, editable: true, sortable: true},
	{key: 'sperre', name: 'sperre', editor: TextEditor, editable: true, sortable: true}
	// XXX add more columns here
	// ...
];


class Draw extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mapLoaded: false,
			currDataLoading: false,
			initDone: false,
			drawingBunkers: false,
			showGrid: true,
			refreshedData: false,
			invokeRefresh: false,
			lastPoint: null,
			scrollTo: -1,
			gridRows: {},
			lastSuccess: '',
			currentAction: '',
			value: ''
		}
		this.draw = null;
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState({value: event.target.value});
	}

	showGrid() {
		this.setState({showGrid: this.state.showGrid ? false : true});
	}

	savebunkers() {

		let myurl = process.env.REACT_APP_PUBLIC_PATH + '/api/editor/putit/';

//		console.log("lastPoint", this.state.lastPoint);

		fetch(myurl, {
			method: 'PUT',
			body: JSON.stringify(this.state.lastPoint),
			headers: {
				'X-API-KEY': this.props.editorKey,
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then(response => {
				return response.ok ? response.json() : response;
			})
			.then(result => {
				if (result.properties.title) {
					this.setState({lastSuccess: result.properties.title + " successfully saved", invokeRefresh: true});
				}
			});
		return;
	}

	deletebunker() {

		let myurl = process.env.REACT_APP_PUBLIC_PATH + '/api/editor/deleteit/';

		fetch(myurl, {
			method: 'PUT',
			body: JSON.stringify(this.state.lastPoint),
			headers: {
				'X-API-KEY': this.props.editorKey,
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then(response => {
				return response.ok ? response.json() : response;
			})
			.then(result => {
				if (result.properties.title) {
					this.setState({
						invokeRefresh: true,
						lastPoint: null,
						lastSuccess: result.properties.title + " successfully deleted"
					});
				}
			});
		return;
	}


	handleSubmit(event) {
//		alert('A name was submitted: ' + this.state.value);

		// store title in point, will be shown in input field on select
		this.draw.setFeatureProperty(this.state.lastPoint.id, 'title', this.state.value);

		d3.select(".draw-button").style("visibility", "visible");

		event.preventDefault();

//		console.log(this.state.lastPoint);

		// save on next cycle
		let newstate = {...this.state};
		newstate.saveMe = true;
		newstate.lastPoint.properties.title = this.state.value;
		newstate.currentAction='saveButton';
		this.setState(newstate);

	}

	async loadData() {

		let myurl = process.env.REACT_APP_PUBLIC_PATH + '/api/rest/cachedbunkers';
		fetch(myurl, {
			method: 'GET',
			headers: {
				'X-API-KEY': process.env.REACT_APP_API_KEY,
//				'Content-Type': 'application/x-www-form-urlencoded',
				'Accept': 'application/json'
			}
		})
			.then(response => {
				return response.json();
			})
			.then(result => {
				this.setState((currRecord, currData) => ({
					currData: result,
					refreshData: true
				}));
			});
		return;
	}

	getBunkersData = () => {

		// get data from all older bunkers
		const data = this.state.currData;

		let rows = [];

		data.map((d) => {
			let row = {
				id: d.id,
				draw_id: d.draw_id,
				title: d.title,
				longi: parseFloat(d.longi),
				lati: parseFloat(d.lati),
				built_year: d.built_year === null ? '' : d.built_year,
				built_month: d.built_month === null ? '' : d.built_month,
				progress: d.progress === null ? '' : d.progress,
				sperre: d.sperre === null ? '' : d.sperre
				// XXX add more columns here
				// ....
			};
			rows.push(row);
		});

		this.setState({gridRows: rows});

	}

	drawBunkerLabels = () => {
		const {map} = this.props;

		// draw all current bunkers
		const data = this.state.currData;

		var feat=[];

		let features = data.map((d) => {

			var feature = {
				id: d.draw_id,
				type: 'Feature',
				properties: {
					title: d.title,
					count: d.id
				},
				geometry: {type: 'Point', coordinates: [parseFloat(d.longi), parseFloat(d.lati)]}
			};
			feat.push(feature);
		});

		setTimeout(()=>{
//			console.log("features: ",feat);

			if (map.getLayer('bunkerLabels')) {
				map.removeLayer('bunkerLabels');
			}
			if (map.getSource('labelPoints')) {
				map.removeSource('labelPoints');
			}

			map.addSource('labelPoints', {
				'type': 'geojson',
				'data': {
					'type': 'FeatureCollection',
					'features': feat
				}
			});

			map.addLayer({
				'id': 'bunkerLabels',
				'type': 'symbol',
				'source': 'labelPoints',
				'layout': {
					'icon-image': 'custom-marker',
					// get the title name from the source's "title" property
					'text-field': ['get', 'title'],
					'text-allow-overlap': false,
					'text-size': 12,
					'text-offset': [0, 1.25],
					'text-anchor': 'top'
				}
			});

		}, 10)
	}


	drawExistingBunkers = () => {

		const {map} = this.props;

		// draw all older bunkers
		const data = this.props.states.currData;

//		console.log("data: ",data);
//		console.log("data2: ",this.state.currData);

		var feat=[];

		let features = data.map((d) => {

			var feature = {
				id: d.draw_id,
				type: 'Feature',
				properties: {
					title: d.title,
					count: d.id
				},
				geometry: {type: 'Point', coordinates: [parseFloat(d.longi), parseFloat(d.lati)]}
			};
			var featureIds = this.draw.add(feature);

			feat.push(feature);

		});

		setTimeout(()=>{
			this.drawBunkerLabels();
		}, 2000)
		setTimeout(()=>{
			map.setLayoutProperty('bunkerLabels', 'visibility', 'none');
		}, 3000)

	}


	prepareDrawBunkers = () => {

		const {map} = this.props;

		if (this.state.drawingBunkers === true) {
			d3.select(".draw-button").style("background-color", "orange");
			d3.select("svg").style("pointer-events", "auto");
			map.getCanvas().style.cursor = "inherit";
			this.draw.changeMode('simple_select');
			this.setState({drawingBunkers: false})
		} else {
			d3.select(".draw-button").style("background-color", "red");
			d3.select("svg").style("pointer-events", "none");

			map.getCanvas().style.cursor = "crosshair";

			this.draw.changeMode('lots_of_points');
			this.setState({drawingBunkers: true})
		}
	}

	handleRowChange = (rows, i) => {

//		console.log("row has changed", rows, i);

		let changedRow = rows[i.indexes[0]];
		let changedCell = i.column.key;
		let changedRowIndex = i.indexes[0];
		let drawId = changedRow['draw_id'];

//		console.log("changed row", rows[i.indexes[0]]);
//		console.log("changed cell", i.column.key);
//		console.log("new value", changedRow[changedCell]);
//		console.log("changedRowIndex value", changedRowIndex);

		let newstate = {...this.state};

		// set lastPoint (used by saveMe)
		newstate.lastPoint = this.draw.get(changedRow['draw_id']);
		newstate.lastPoint.properties.title = changedRow['title'];
		newstate.lastPoint.geometry.coordinates[0] = parseFloat(changedRow['longi']);
		newstate.lastPoint.geometry.coordinates[1] = parseFloat(changedRow['lati']);
		newstate.lastPoint.properties.built_year = !changedRow['built_year'] || changedRow['built_year'] === '' ? null : parseInt(changedRow['built_year']);
		newstate.lastPoint.properties.built_month = !changedRow['built_month'] || changedRow['built_month'] === '' ? null : parseInt(changedRow['built_month']);
		newstate.lastPoint.properties.progress = !changedRow['progress'] || changedRow['progress'] === '' ? null : parseInt(changedRow['progress']);
		newstate.lastPoint.properties.sperre = !changedRow['sperre'] || changedRow['sperre'] === '' ? null : parseInt(changedRow['sperre']);
		// set more properties for lastpoint
		// XXX add more columns here
		// ....

		// save last point to db
		newstate.saveMe = true;
		newstate.currentAction = 'rowChange';

		// change in draw layer (set this only if we need to draw the change)
		this.draw.setFeatureProperty(drawId, 'title', changedRow['title']);
		// set more properties for draw layer
		// ....

		// select point (programmatically)
		this.draw.changeMode('simple_select', {featureIds: [changedRow['draw_id']]});

		// fetch title to input field
		newstate.value = changedRow['title'];

		// change cell values for the whole row in data grid
		newstate.gridRows[changedRowIndex] = changedRow;
		this.setState(newstate);
	}

	onGridRowsUpdated = (d) => {
//		console.log("row updated", d);
	}

	sortRows = (initialRows, sortColumn, sortDirection) => rows => {
//		console.log("sort rows called", initialRows, sortColumn, sortDirection);
		const comparer = (a, b) => {
			if (sortDirection === "ASC") {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			}
			else if (sortDirection === "DESC") {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};
		return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
	};

	gridRef = (d,i,e) => {
		if (d && this.state.scrollTo > -1) {
//			console.log("gridref: ", d);
//			console.log("scrollTo: ", this.state.scrollTo);
//			console.log("scrollTo: ", this.state.currData.length);
//			console.log("scrollTo: ", this.state.currData);
			if (this.state.scrollTo === this.state.currData.length-1) {
//				console.log("extended");
				setTimeout(()=> {
					d.scrollToRow(this.state.currData.length);
				}, 2000)
			}
			else {

				for (let x = 0; x < this.state.currData.length; x++) {
					if (this.state.scrollTo == this.state.currData[x].id) {
//						console.log("scrollto found:", x);
						d.scrollToRow(x);
					}
				}
//						d.scrollToRow(this.state.scrollTo-1);

			}
			this.setState({scrollTo: -1})
		}
	}

	setRows = (d, i) => {
//		console.log("set rows called", d, this.state.gridRows, i);
//		this.setState({gridRows: d()});
		return this.setState({gridRows: d(this.state.gridRows)});
//		return this.state.gridRows;
//		return d(this.state.gridRows);
	}

	rowGetter(row: Row) {
//		console.log("row getter: ", row);
		return row.id;
	}

	selectedByRow = (d,i) => {
//		console.log(d,i, i.id);

//		const {map} = this.props;

		this.draw.changeMode('simple_select', {featureIds: [i.draw_id]})

		this.setState({
			lastPoint: i,
			value: i.title,
			lastSuccess: "'" + i.title + "' selected!"
		});
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

//		console.log("%c ---> DRAW: state updated", "color: blue", this.state);

		if (this.state.saveMe === true) {

//			console.log("saving: ", this.state.lastPoint);
//			console.log("saving: ", this.state);
			this.savebunkers();

			if (this.state.currentAction === "saveButton") {
				this.setState({saveMe: false, scrollTo: this.state.lastPoint.properties.count-1});
			}
			else {
				// do not scroll
				this.setState({saveMe: false, currentAction: ''});
			}

		}

		if (this.state.initDone === true && this.state.invokeRefresh === true) {

//			console.log("refresh initiated");

			if (!this.state.refreshData && this.state.currDataLoading === false) {
				this.loadData().then(() => {
					this.setState({currDataLoading: true})
				});
			} else if (this.state.refreshData === true) {
				this.getBunkersData();
				this.setState({refreshData: false, invokeRefresh: false, currDataLoading: false});
			}
		}

		if (this.props.init !== prevProps.init && this.props.initDraw === true) {

//			console.log("DRAW initialized");

			const {map} = this.props;

			var BunkerDrawMode = {};


			// When the mode starts this function will be called.
			// The `opts` argument comes from `draw.changeMode('lotsofpoints', {count:7})`.
			// The value returned should be an object and will be passed to all other lifecycle functions
			BunkerDrawMode.onSetup = function (opts) {
				var state = {};
				state.count = opts.count || 0;
//				state.title = opts.title || 'bbb';
				return state;
			};

			let that = this;

			function getRandomInt(min, max) {
				min = Math.ceil(min);
				max = Math.floor(max);
				return Math.floor(Math.random() * (max - min)) + min;
			}

			// Whenever a user clicks on the map,Draw will call `onClick`
			BunkerDrawMode.onClick = function (state, e) {
				// `this.newFeature` takes geojson and makes a DrawFeature
				var point = this.newFeature({
					id: getRandomInt(9999999999, 99999999999),
					type: 'Feature',
					properties: {
						title: "new point",
						count: that.state.currData.length
					},
					geometry: {
						type: 'Point',
						coordinates: [e.lngLat.lng, e.lngLat.lat]
					}
				});

				this.addFeature(point); // puts the point on the map
				that.draw.setFeatureProperty(point.id, 'title', point.id);

//				console.log("point", point);
//				console.log(map);

				d3.select(".draw-button").style("background-color", "orange");
				d3.select(".draw-button").style("visibility", "hidden");
				d3.select("svg").style("pointer-events", "auto");

//				console.log(map.queryRenderedFeatures({layers: ['points-are-red2.cold']}));

				map.getCanvas().style.cursor = "inherit";
				that.draw.changeMode('simple_select', {featureIds: [point.id]})
				document.getElementById("input-field").focus();
				that.setState({lastPoint: point, drawingBunkers: false});
			};


//			BunkerDrawMode.onMouseMove = function(state, e) {
//				this.map.getCanvas().style.cursor = "crosshair";
//			}

			// Whenever a user clicks on a key while focused on the map, it will be sent here
			BunkerDrawMode.onKeyUp = function (state, e) {
				if (e.keyCode === 27) return this.changeMode('simple_select');
			};

			// This is the only required function for a mode.
			// It decides which features currently in Draw's data store will be rendered on the map.
			// All features passed to `display` will be rendered, so you can pass multiple display features per internal feature.
			// See `styling-draw` in `API.md` for advice on making display features
			BunkerDrawMode.toDisplayFeatures = function (state, geojson, display) {
				display(geojson);
//				console.log(state, geojson);
			};

			// Add the new draw mode to the MapboxDraw object
			this.draw = new MapboxDraw({
				defaultMode: 'simple_select',
				// Adds the BunkerDrawMode to the built-in set of modes
				modes: Object.assign({
					lots_of_points: BunkerDrawMode,
				}, MapboxDraw.modes),
				controls: {
					polygon: false,
					line_string: false,
					point: false,
					combine_features: false,
					uncombine_features: false

				},
				styles: [
					{
						'id': 'highlight-active-points',
						'type': 'circle',
						'filter': ['all',
							['==', '$type', 'Point'],
							['==', 'meta', 'feature'],
							['==', 'active', 'true']],
						'paint': {
							'circle-radius': 7,
							'circle-color': '#ff00ff'
						}
					},
					{
						'id': 'points-are-red',
						'type': 'circle',
						'filter': ['all',
							['==', '$type', 'Point'],
							['==', 'meta', 'feature'],
							['==', 'active', 'false']],
						'paint': {
							'circle-radius': 5,
							'circle-color': 'red'
						}
					}
				]
			});

			map.addControl(this.draw);


			map.on('draw.delete', (e) => {
				//console.log("delete detected", e.features[0]);
				if (e.features[0]) {
					this.deletebunker();
				}
			});

			map.on('draw.update', (e) => {
				if (e.features[0]) {
					this.setState({lastPoint: e.features[0], value: e.features[0].properties.title});
					this.setState({
						lastPoint: e.features[0],
						value: e.features[0].properties.title,
						lastSuccess: "'" + e.features[0].properties.title + "' moved, click SAVE to store new position!"
					});
				}
			});

			map.on('draw.selectionchange', (e) => {
				//console.log(" changed", e);

				if (e.features[0]) {
					//console.log("changed", e.features[0]);
					this.setState({
						scrollTo: e.features[0].properties.count,
						lastPoint: e.features[0],
						value: e.features[0].properties.title,
						lastSuccess: "'" + e.features[0].properties.title + "' selected!"
					});
				}

			});

			this.drawExistingBunkers();

			this.setState({initDone: true, invokeRefresh: true});

			//console.log(this.draw.getAll());
		}

	}


	render() {
		return (
			<div className='Draw'>
				<div className='draw-toolbar'>
					<div className="orangeButton" onClick={() => {
						this.showGrid();
					}}> {this.state.showGrid ? 'hide grid' : 'show grid'} </div>
					<div className="draw-button orangeButton" onClick={() => {
						this.drawBunkerLabels();
					}}> refresh
					</div>
					<div className="draw-button orangeButton" onClick={() => {
						this.prepareDrawBunkers();
					}}> add Bunker
					</div>
					<div className="submitfield">
						<form onSubmit={this.handleSubmit}>
							<label>
								Title:
								<input id="input-field" type="text" size={50} value={this.state.value}
								       onChange={this.handleChange}/>
							</label>
							<input className="orangeButton2" type="submit" value="save"/>
						</form>
						<div className="break"></div>
						<div>{this.state.lastSuccess ? 'log: ' + this.state.lastSuccess : ""}</div>
					</div>
				</div>
				{this.state.showGrid && this.state.gridRows.length !== undefined &&
				<div className="datagrid">
					<DataGrid
						ref={this.gridRef}
						columns={columns}
						rows={this.state.gridRows}
						rowKeyGetter={this.rowGetter}
						minHeight={150}
						onRowClick={ this.selectedByRow }
						onRowsChange={this.handleRowChange}
						onGridRowsUpdated={this.onGridRowsUpdated}
						onSort={(sortColumn, sortDirection) => {
							//console.log("sort clicked");
							this.setRows(this.sortRows(this.state.gridRows, sortColumn, sortDirection))
						}
						}
					/>
				</div>
				}
			</div>
		)
	};
}

export default Draw

