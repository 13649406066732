const bordercoords3 =
[
[
	[10, 46],
	[10, 47.3],
	[12.8, 47.3],
	[12.8, 46],
	[10, 46]
]
]
export default bordercoords3;
