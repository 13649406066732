//176 STATION 1: 112_BRE_004_39-01 & 40-02
//11.500285907329, 46.996273057453
//242 STATION 2: 117_TEN_012_40-10
//11.435055276027, 46.931265943668
//784 STATION 3: 211_MUL_005_40-10
//11.500504989432, 46.851972309596
//957 STATION 4: 305_FOR_003_40-xy
//11.621777829547, 46.778479709053
//1053 STATION 5: 321_BOL_014_40-10
//11.324010055649, 46.46769158528
//1098 STATION 6: 321_BOL_057_40-10
//11.228574563816, 46.503645034518

const directions = [
	{
		id: 1,
		time: 100,
		action: "showContent",
		bunker: -1,
		content:
			[
				{title_de: "Geschichte Vallo Alpino"},
				{
					text_de: "" +
						"Mussolini ließ Ende der 1930er Jahre die Landesgrenzen Italiens absichern. Aus Misstrauen vor Hitler " +
						"entstand auch an der Grenze zum verbündeten Deutschen Reich ein massiver Wall mit hunderten Bunkern. " +
						"Der Alpenwall blieb unvollendet und wurde nie in Betrieb genommen. Erst nach dem Zweiten Weltkrieg wurde " +
						"ein Drittel der Anlagen für den Kalten Krieg adaptiert und schließlich in den 1990er Jahren " +
						"endgültig aufgelassen. Das Militär übergab die Bunkeranlagen der Südtiroler Landesverwaltung, die " +
						"sie bis auf wenige Ausnahmen an Privatpersonen veräußerte."
				},
				{title_de: "Die Quicktour"},
				{
					text_de: "" +
						"Wir führen Sie nun vom nördlichsten zum südlichsten Bunker Südtirols. Dabei machen wir an sechs Stationen " +
						"Halt und stellen ihnen sehr unterschiedliche Bunker vor. Die Dauer des Aufenthalts an einer Station " +
						"bestimmen Sie selbst. Erst wenn Sie auf „Weiter“ klicken, wird die Tour fortgesetzt."
				},
				{
					text_de: "" +
						"Mit einem Klick auf \"Starten\" beginnen Sie eine interaktive Tour."
				},
				{
					text_de: "" +
						"Wir wünschen Ihnen eine gute Reise!"
				},

				{title_en: "History of the Vallo Alpino"},
				{
					text_en: "" +
						"Mistrustful of Hitler, Mussolini decided at the end of the 1930s to secure Italy’s national borders. The Duce therefore had a massive wall, with hundreds of bunkers, built along the border with his supposed ally, the German Reich. The Alpine Wall remained unfinished and never saw action. It was only after the Second World War that one third of the structures were adapted for Cold War purposes, finally being abandoned in the 1990s. The military handed over the bunkers to the provincial authorities of South Tyrol, which (with a few exceptions) then sold them to private individuals."
				},
				{title_en: "The Quicktour"},
				{
					text_en: "" +
						"We will now take you from the northernmost to the southernmost bunker in South Tyrol, stopping at six stations on the way so as to introduce you to a variety of very different bunkers. You decide how long you want to stay at a particular station: only when you click on “Continue” will the tour resume."
				},
				{
					text_en: "" +
						"We wish you a pleasant journey!"
				},
				{title_it: "Storia del Vallo Alpino"},
				{
					text_it: "" + "Alla fine degli anni 1930, Mussolini fece mettere in sicurezza i confini italiani. Non fidandosi di Hitler, " +
						"suo alleato, fece erigere un imponente vallo di centinaia di bunker anche lungo la frontiera con il Reich. Il Vallo Alpino " +
						"restò incompiuto e non venne mai utilizzato. Solo dopo la Seconda Guerra Mondiale, un terzo delle opere venne adattato per la " +
						"Guerra Fredda e definitivamente dismesso negli anni 1990. I bunker sono stati trasferiti dallo Stato all’Amministrazione provinciale " +
						"che, salvo rare eccezioni, li ha ceduti a soggetti privati."
				},
				{title_it: "Il Quicktour"},
				{
					text_it: "" +
						"Vi accompagniamo dal bunker più settentrionale sino a quello più meridionale dell’Alto Adige, facendo tappa in sei stazioni e presentandovi opere molto diverse tra loro. E sarete voi a decidere quanto tempo dedicare a ogni stazione: il tour, infatti, prosegue solo cliccando su “Avanti”."
				},
				{
					text_it: "" +
						"Buon viaggio!"
				},
				{
					action: {
						buttonName: "start",
						buttonAction: "next"
					}
				}
			]
	},
	{
		id: 1,
		time: 10,
		action: 'move',
		bunker: 176,
		fly: {
			center: [
				//STATION 1: 112_BRE_004_39-01 & 40-02
				11.500285907329, 46.996273057453
			],
//						speed: 0.3,
			speed: 0.3,
			zoom: 16.5,
			pitch: 79,
			bearing: -153,
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 1,
		time: 100,
		action: "showContent",
		bunker: 176,
		content:
			[
				{title_de: "STATION 1"},
				{title_en: "STATION 1"},
				{title_it: "STATION 1"},

				{desc: '176'},

//				{title_de: "Werk Nr. 4 der Sperre „Brennero Fondovalle"},
//				{image: "images/STATION-1-FOTO-1.jpg"},
//				{
//					text_de: "" +
//						"Werk 4 ist das am nördlichsten gelegene Einzelwerk Italiens und Teil des ersten Verteidigungsgürtels. Der Bunker liegt in einem Felsvorsprung ca. ein Kilometer südlich der Staatsgrenze. Aus seiner Entstehungsgeschichte werden die großen Schwankungen in der Verteidigungsstrategie Italiens erkenntlich. Im Bild ist die Schiessscharte für ein Maschinengewehr zu erkennen, die in täuschend echt aussehendem, künstlichem Felsen sitzt."
//				},
//				{
//					text_en: "" +
//						"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. " +
//						"Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. " +
//						"venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. "
//				},
//				{
//					text_it: "" +
//						"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. " +
//						"Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. " +
//						"venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. "
//				},
//				{image: "images/STATION-1-FOTO-1.jpg"},
				{
					action: {
						buttonName: "next",
						buttonAction: "next"
					}
				}
			]
	},
	{
		id: 2,
		time: 500,
		action: 'move',
		bunker: 242,
		fly: {
			center: [
				//STATION 2: 117_TEN_012_40-10
				11.435055276027, 46.931265943668
			],
//						speed: 0.3,
			curve: 1.3,
			speed: 0.2,
//			speed: 0.3,
			zoom: 15.5,
			pitch: 70,
			bearing: -165,
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 3,
		time: 100,
		action: "showContent",
		bunker: 242,
		content:
			[
				{title_de: "STATION 2"},
				{title_en: "STATION 2"},
				{title_it: "STATION 2"},

				{desc: '242'},

				{
					action: {
						buttonName: "next",
						buttonAction: "next"
					}
				}
			]
	},

	{
		id: 4,
		time: 0,
		action: 'move',
		bunker: 784,
		ease: {
			bearing: 170,
			zoom: 14.5,
			duration: 4000,
			pitch: 70,
			easing: x => x,
//			easeOutQuint: function (t) {
//				return 1 - Math.pow(1 - t, 5);
//			}
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 5,
		time: 0,
		action: 'move',
		bunker: 784,
		pan: {
			destination: [11.5071, 46.8544],
			animation: {
				easing: x => x,
				duration: 6000,
				essential: true // this animation is considered essential with respect to prefers-reduced-motion
			},
			duration: 3000,
		}
	},
	{
		id: 6,
		time: 0,
		action: 'move',
		bunker: 784,
		fly: {
			center: [
				//STATION 3: 211_MUL_005_40-10
//				11.500504989432, 46.851972309596
				11.5044, 46.8539
			],
			speed: 0.3,
			curve: 1.0,
			pitch: 75,
			bearing: -156,
			zoom: 15.7,
			easing: x => x,
//			easing: function (t) {
//				if (t * 1000 % 50 < 2) {
////					console.log(t, map.getCenter());
//				}
//				return t;
//			},
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 7,
		time: 100,
		action: "showContent",
		bunker: 784,
		content:
			[
				{title_de: "STATION 3"},
				{title_en: "STATION 3"},
				{title_it: "STATION 3"},

				{desc: '784'},

				{
					action: {
						buttonName: "next",
						buttonAction: "next"
					}
				}
			]
	},
	{
		id: 8,
		time: 0,
		action: 'move',
		bunker: 957,
		ease: {
			bearing: 150,
			zoom: 15.8,
			duration: 2000,
			easing: x => x,
//			easeOutQuint: function (t) {
//				return 1 - Math.pow(1 - t, 5);
//			}
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 9,
		time: 0,
		bunker: 957,
		action: 'move',
		fly: {
			center: [
				//STATION 4: 305_FOR_003_40-xy
				11.621777829547, 46.778479709053
			],
			speed: 0.3,
//							speed: 1.3,
			curve: 1.2,
			pitch: 75,
			bearing: 128,
			zoom: 18,
			easing: function (t) {
				return t;
			},
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},

	{
		id: 10,
		time: 100,
		action: "showContent",
		bunker: 957,
		content:
			[
				{title_de: "STATION 4"},
				{title_en: "STATION 4"},
				{title_it: "STATION 4"},

				{desc: '957'},

				{
					action: {
						buttonName: "next",
						buttonAction: "next"
					}
				}
			]
	},
	{
		id: 11,
		time: 500,
		action: 'move',
		ease: {
			bearing: 110,
			zoom: 13.5,
			duration: 5000,
//			easeOutQuint: function (t) {
//				return 1 - Math.pow(1 - t, 5);
//			}
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 12,
		time: 0,
		action: 'move',
		bunker: 1053,
		fly: {
			center: [
				//STATION 5: 321_BOL_014_40-10
				11.324010055649, 46.46769158528
			],
			speed: 0.3,
//							speed: 1.3,
			curve: 1.2,
			pitch: 75,
			bearing: -90,
			zoom: 12,
			easing: x => x,
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},

	{
		id: 13,
		time: 0,
		action: 'move',
		bunker: 1053,
		ease: {
			bearing: 22,
			zoom: 16,
			duration: 5000,
			easeOutQuint: function (t) {
				return 1 - Math.pow(1 - t, 5);
			},
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 14,
		time: 100,
		action: "showContent",
		bunker: 1053,
		content:
			[
				{title_de: "STATION 5"},
				{title_en: "STATION 5"},
				{title_it: "STATION 5"},

				{desc: '1053'},

				{
					action: {
						buttonName: "next",
						buttonAction: "next"
					}
				}
			]
	},
	{
		id: 15,
		time: 500,
		action: 'move',
		bunker: 1098,
		ease: {
			bearing: -50,
			zoom: 15.5,
			duration: 3000,
//			easeOutQuint: function (t) {return 1 - Math.pow(1 - t, 5);},
			easing: x => x,
//			easeInQuad: function (x)  {return -x * x;},
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},

	{
		id: 16,
		time: 0,
		action: 'move',
		bunker: 1098,
		fly: {
			center: [
				//STATION 6: 321_BOL_057_40-10
				11.228574563816, 46.503645034518
			],
			speed: 0.3,
//							speed: 1.3,
			curve: 1,
			pitch: 75,
			bearing: -60,
			zoom: 13,
//			easeOutQuint: function (t) {return 1 - Math.pow(1 - t, 5);},
			easing: x => x,
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 17,
		time: 0,
		action: 'move',
		bunker: 1098,
		ease: {
			bearing: 100,
			zoom: 17,
			duration: 8000,
			easeOutQuint: function (t) {return 1 - Math.pow(1 - t, 5);},
//			easing: x => x,
//			easeInOutCubic: function (x)    {
//				return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
//			}
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 18,
		time: 0,
		action: 'move',
		bunker: 1098,
		ease: {
			bearing: 130,
			zoom: 15,
			pitch: 75,
			duration: 3000,
			easeOutQuint: function (t) {
				return 1 - Math.pow(1 - t, 5);
			},
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 19,
		time: 100,
		action: "showContent",
		bunker: 1098,
		content:
			[
				{title_de: "STATION 6"},
				{title_en: "STATION 6"},
				{title_it: "STATION 6"},

				{desc: '1098'},

				{
					text_de: "" +
						""
				},
				{
					text_en: "" +
						""
				},
				{
					text_it: "" +
						""
				},


				{
					action: {
						buttonName: "range",
						buttonAction: "shootingRange"
					}
				},
				{
					action: {
						buttonName: "next",
						buttonAction: "next"
					}
				}
			]
	},
	{
		id: 20,
		time: 0,
		action: 'move',
		bunker: -1,
		ease: {
			bearing: 0,
			zoom: 9,
			pitch: 0,
			duration: 12000,
			easeOutQuint: function (t) {
				return 1 - Math.pow(1 - t, 5);
			},
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		}
	},
	{
		id: 21,
		time: 500,
		action: "end",
		bunker: -1,
	}
];

export default directions;
