import Slider, { Range } from 'rc-slider';
import React from "react";
//import 'rc-slider/assets/index.css';
//import '../css/slider1.css';

const SatSlider = React.memo((props) => {

	const handleChange = (e) => {
//		console.log(e)
		props.satSliderCallback(e)
	}

	return (
		<div>
			<Slider
				onChange={handleChange}
				defaultValue={0}
				railStyle={{}}
				style={{width: '80px'}}
				trackStyle={{backgroundColor: 'grey', height: 12, border: 0, borderRadius: '1px'}}
				handleStyle={{}}
			/>
		</div>
	)
})

export default SatSlider;
