import React, {Fragment, Suspense, useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import {useStore} from "./store";
import LoremIpsum, {loremIpsum} from 'react-lorem-ipsum';
import ShowAudio from "./Audio";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {t} from '../helpers';

import Trans from './Trans';

//import createDOMPurify from 'dompurify'
//import {JSDOM} from 'jsdom'
import '../css/sidebars.css';

//const window = (new JSDOM('')).window
//const DOMPurify = createDOMPurify(window)

//const BunkerDesc = React.memo(props => {
const BunkerDesc = () => {

	const selected = useStore(state => state.selected);
	const setSelected = useStore(state => state.setSelected);
	const selectedElevation = useStore(state => state.selectedElevation);
	//console.log("selected",selected)

	const lang = useStore(state => state.language);
	const [language, setLanguage] = useState(lang);

	//console.log('bunkedesc re-rendered');

	useEffect(()=>{
		//console.log("language changed to ", lang);
		setLanguage(useStore.getState().language)
	}, [useStore.getState().language])

	//console.log(selected)

	return (
		<div className={'contentbox'}>
			{selected !== -1 ?
				<div>
					<h3>
						Bunker(#{selected.id}): {selected.title}
					</h3>
						<hr />
					<p>
						{/*Fertiggestellt: <Trans t={'Completed'} id={'comp'} /> */}
						{t("Completed")}: {selected.built_year} / {selected.built_month}
					</p>
					<p>
						{t('Barrier')}: {selected.name}
						{/*({selected.sperreguertel})*/}
					</p>
					<p>
						{t('Elevation')}: {Math.floor(selectedElevation).toLocaleString()}m
					</p>
						<p>{t('Latitude')}: {selected.lati}</p>
						<p>{t('Longitude')}: {selected.longi}</p>
						<hr />

					{/*<LoremIpsum p={2} startWithLoremIpsum={false}></LoremIpsum>*/}
					<ContentLoader lang={language} id={selected.id} side={'right'} style={{ fontSize: '12px'}}/>
					<br />
				</div>
				:
				<div>
					<h3>
						{t('no bunker selected')}
					</h3>
				</div>
			}
		</div>
	)
}
//)


export function ContentLoader(props) {

//	const id = props.id
//	const side = props.side

	const bunkerData = process.env.REACT_APP_PUBLIC_PATH + '/api/rest/bunker/id/' + props.id;
	const [content, setContent] = useState();
	const [lightboxOpen, setLightboxOpen] = useState(false);
	const [img, setImg] = useState();

	// console.log(props)

	const lang = useStore(state => state.language);
	const [language, setLanguage] = useState(lang);

	//console.log('bunkedesc re-rendered');

	useEffect(()=>{
//		console.log("language changed to ", lang);
		setLanguage(useStore.getState().language)
	}, [useStore.getState().language])

	const handleImageClick = (e) => {
		e.preventDefault();
		const content = useStore.getState().content;
		const images = content['media'].filter((f) =>
			f.filename.split('.').pop().toLowerCase() !== 'pdf'
			&& f.filename.split('.').pop().toLowerCase() !== 'mp3'
		)
		const image = images.filter((f) => f.id == e.target.id)
		setImg([image[0].filename]);
		setLightboxOpen(true);
	}

	const getmedia = (data, id) => {
		let currmedia = data['media'].find(x => id == x.id);
		if (currmedia) {
			let ext = currmedia.filename.split('.').pop().toLowerCase();
			if (ext === 'pdf') {
				return (
					<p>
						{t('Document')}: <a style={{color: 'black'}}
						href={process.env.REACT_APP_PUBLIC_PATH + '/api/uploads/' + currmedia.filename}>{currmedia.filename}</a>
					</p>
				)
			} else if (ext === 'mp3') {
				return (
					<div>
						<p>
							{t('Audio')}: {currmedia.filename}
						</p>
						<ShowAudio key={'aud' + id} filename={currmedia.filename}></ShowAudio>
						{/*Audio: <a href={process.env.REACT_APP_PUBLIC_PATH + '/api/uploads/' + currmedia.filename}>{currmedia.filename}</a>*/}
					</div>
				)
			} else {
				return (
					<div
					>
						<img
							id={id}
							key={id}
							src={process.env.REACT_APP_PUBLIC_PATH + "/api/uploads/" + currmedia.filename}
							width={props.side === 'right' ? '410px' : '400px'}
							onClick={handleImageClick}
						/>
					</div>)
			}
			return <></>
		}
	}
	const getlinks = (data, id) => {
		let currmedia = data['links'].find(x => id == x.id);

		if (currmedia) {
			// normal link
			if (currmedia.type === '0') {
				return (
					<>
						{lang === "DE" &&
							<p>
								{t('Ext. link')}: <a style={{color: 'black'}} href={currmedia.url_de ? currmedia.url_de : currmedia.url} target="_blank">
								{currmedia.label_de}</a>
							</p>
						}
						{lang === "EN" &&
							<p>
								{t('Ext. link')}: <a style={{color: 'black'}} href={currmedia.url_en ? currmedia.url_en : currmedia.url} target="_blank">
								{currmedia.label_en}</a>
							</p>
						}
						{lang === "IT" &&
							<p>
								{t('Ext. link')}: <a style={{color: 'black'}} href={currmedia.url_it ? currmedia.url_it : currmedia.url} target="_blank">
								{currmedia.label_it}</a>
							</p>
						}
					</>
				)
				// vimeo link
			} else {
				return (
					<>
						<div style={{padding: "56.25% 0 0 0", position: "relative", marginBottom: "10px" }}>
						<iframe src={currmedia.url}
							    style={{position:"absolute", top:0, left:0, width:"100%", height:"100%"}} frameBorder="0"
							        allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
						{/*	<a href={currmedia.url}>{currmedia.label_de}</a>*/}
						</div>
					</>
				)
			}
		}
		return <></>
	}

	useEffect(() => {
		fetcher(bunkerData).then(data => {
			useStore.setState({content: data})
			let afterCopyright = 0
			let follows = false
			let htmlout = data['content'].map((d,i) => {

					// to let some room between two pictures, track if copyright follows a caption
					let follows = d.type === 'caption' && i === afterCopyright+1 && i > 2 ? true : false
					afterCopyright = d.type === 'copyright' ? i : 0
					let nextpicture = false;
					let next = data['content'][i+1] ? data['content'][i+1] : ''
					let nextMedia = data['media'].find(x => next.this_id == x.id)
					if (nextMedia) {
						let ext = nextMedia.filename.split('.').pop().toLowerCase();
							if (ext === 'jpg' || ext === 'png' ) {
								nextpicture = true;
							}
					}
////
////						console.log(d)
////					console.log("next",next)
//					console.log("next",nextpicture)
//
//					let nextImage = d.type === 'caption' && data[d]

					return (
						<div key={"d_" + d.id}>
							{/*<LoremIpsum*/}
							{/*	startWithLoremIpsum={false}*/}
							{/*/>*/}
							{/*{d.type === 'texts' && data['texts'][d.this_id].description}*/}
							{/*{d.type === 'links' && data['links'][d.this_id].label_de}*/}

							{d.type === 'texts' && lang === "DE" && <p>{data['texts'].find(x => d.this_id == x.id).description_de}</p>}
							{d.type === 'texts' && lang === "EN" && <p>{data['texts'].find(x => d.this_id == x.id).description_en}</p>}
							{d.type === 'texts' && lang === "IT" && <p>{data['texts'].find(x => d.this_id == x.id).description_it}</p>}

							{d.type === 'heading' && lang === "DE" && <h4>{data['texts'].find(x => d.this_id == x.id).description_de}</h4>}
							{d.type === 'heading' && lang === "EN" && <h4>{data['texts'].find(x => d.this_id == x.id).description_en}</h4>}
							{d.type === 'heading' && lang === "IT" && <h4>{data['texts'].find(x => d.this_id == x.id).description_it}</h4>}

							{d.type === 'caption' && lang === "DE" && <div className={'caption'}
							               style={{paddingTop: follows ? '20px' : '0px'}}>
								<small>{t(nextpicture ? 'Fig' : '')}
									{data['texts'].find(x => d.this_id == x.id).description_de}</small></div>}
							{d.type === 'caption' && lang === "EN" && <div className={'caption'} style={{paddingTop: follows ? '20px' : '0px'}}>
								<small>{t(nextpicture ? 'Fig' : '')}
									{data['texts'].find(x => d.this_id == x.id).description_en}</small></div>}
							{d.type === 'caption' && lang === "IT" && <div className={'caption'} style={{paddingTop: follows ? '20px' : '0px'}}>
								<small>{t(nextpicture ? 'Fig' : '')}
									{data['texts'].find(x => d.this_id == x.id).description_it}</small></div>}

							{d.type === 'copyright' && lang === "DE" && <small><i>&copy; {data['texts'].find(x => d.this_id == x.id).description_de}</i></small>}
							{d.type === 'copyright' && lang === "EN" && <small><i>&copy; {data['texts'].find(x => d.this_id == x.id).description_en}</i></small>}
							{d.type === 'copyright' && lang === "IT" && <small><i>&copy; {data['texts'].find(x => d.this_id == x.id).description_it}</i></small>}

							{d.type === 'links' && getlinks(data, d.this_id)}
							{d.type === 'media' && getmedia(data, d.this_id)}
						</div>)
				}
			);
			setContent(htmlout);
		}).catch(error => error.message);
	}, [props.id, lang]);

	return (<>
		{content ? content : '...'}
		{lightboxOpen && img[0] &&
			<Lightbox
				mainSrc={process.env.REACT_APP_PUBLIC_PATH + "/api/uploads/" + img[0]}
				onCloseRequest={() => setLightboxOpen(false)}
			/>
		}
	</>)
}

const fetcher = async (url) => {
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'X-API-KEY': process.env.REACT_APP_API_KEY,
//						'Content-Type': 'application/x-www-form-urlencoded',
			'Accept': 'application/json'
		}
	});
	if (!response.ok) {
		const message = `An error has occured: ${response.status}`;
		throw new Error(message);
	}
	const data = await response.json();
//	console.log(data);
	return data
}

export default BunkerDesc
