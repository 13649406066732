//import '../assets/index.less';
import 'rc-tooltip/assets/bootstrap.css';
import '../css/slider.css';
import React, {useEffect, useState} from 'react';
import Slider, {SliderTooltip} from 'rc-slider';
import {useStore} from "./store";
import {helpers} from "@turf/turf";
import {getYear, getRoundedYear, getNumber} from '../helpers';

const lower = 17;
const upper = 22;
//const limitupper = 80.337 // 1945-04-15


const {createSliderWithTooltip} = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const {Handle} = Slider;

var intervalID = null;

const handle = props => {
	const {value, dragging, index, ...restProps} = props;
	return (
		<SliderTooltip
			prefixCls="rc-slider-tooltip"
			overlay={`${value} %`}
			visible={dragging}
			placement="top"
			key={index}
		>
			<Handle value={value} {...restProps} />
		</SliderTooltip>
	);
};


const Pause = ({onPlayerClick}) => {
	return (
		<svg className="button" viewBox="0 0 60 60" onClick={onPlayerClick}>
			<g id="pause-icon">
			<circle cx="30" cy="30" r="60" fill="green" fillOpacity="0" />
			<polygon points="0,0 15,0 15,60 0,60"/>
			<polygon points="25,0 40,0 40,60 25,60"/>
			</g>
		</svg>
	)
}

const Play = ({onPlayerClick}) => {

	const setIsOnboarding = useStore(state => state.setIsOnboarding);

	const [init, setInit] = useState(false)

//	console.log({onPlayerClick})


	useEffect(() => {
		if (init) {
			setIsOnboarding(false)
		}
	}, [init]);


	return (
		<svg className="button" viewBox="0 0 60 60" onClick={()=>{ setInit(true); onPlayerClick()}}>
			<g id="play-icon" >
			<circle cx="30" cy="30" r="70" fill="green" fillOpacity="0" />
			<polygon points="0,0 50,30 0,60"/>
			</g>
		</svg>
	)
}


class TimeSlider extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			value: [lower, upper],  // [1931, 1940]
			marks: {},
			playerSpeed: 0.25,
			makeSliderCallbacks: false,
//			min: 3,
		};
	}

	handlePlayerClick = () => {

		if (!this.state.playing) {
			// rewind
			if (this.state.value[1] >= 100) {
//			if (this.state.value[1] >= limitupper) {
//				this.handleChange([0, this.state.value[1] - this.state.value[0]]);
				this.handleChange([lower, upper]);
			}

			// play
			intervalID = setInterval(() => {
				if (this.state.value[1] < 100) {
//				if (this.state.value[1] < limitupper) {
					this.handleChange([this.state.value[0] + this.state.playerSpeed, this.state.value[1] + this.state.playerSpeed]);
					this.setState({playing: true});
				} else {
					// stop if end is reached
					clearInterval(intervalID);
					this.setState({playing: false, rewind: true})
				}
			}, 50);

		} else {
			// pause
			clearInterval(intervalID)
			this.setState({playing: false})
		}
	}

	handleChange = value => {

		let newvalue = [lower, value[1]]

//		if (value[1] >= limitupper) newvalue = [lower, limitupper]

		this.filterBunkers(newvalue);
		// callback to App.js, turn on in states if needed
		if (this.state.makeSliderCallbacks) this.props.sliderCallback({sliderSelected: newvalue});
		this.setState({
			value: newvalue,
		});
	};


	filterBunkers = value => {

		const lowerBound = getYear(value[0]);
		const upperBound = getYear(value[1]);

		useStore.getState().setTimesliderStart(lowerBound);
		useStore.getState().setTimesliderStop(upperBound);

		const {map} = this.props;

		var mapLayer = map.getLayer('bunker-circle');

		if (typeof mapLayer !== 'undefined') {

//			console.log(this.props);

			if (this.props.bunkerDynamic === true) {

//				console.log("dynamic", this.props.bunkerDynamic);

//				map.setFilter('bunker-icons',
//					['all',
//						['>=', ['to-number', ['get', 'built']], ['literal', lowerBound]],
//						['<=', ['to-number', ['get', 'built']], ['literal', upperBound]]
//					]
//				);

				map.setFilter('bunker-SVG',
					['all',
						['>=', ['to-number', ['get', 'built']], ['literal', lowerBound]],
						['<=', ['to-number', ['get', 'built']], ['literal', upperBound]]
					]
				);
//				map.setLayoutProperty('bunker-poly', 'visibility', 'none');
			return
			}


//			map.setLayoutProperty('bunker-poly', 'visibility', 'none');
			map.setFilter('bunker-circle',
				['all',
					['>=', ['to-number', ['get', 'built']], ['literal', lowerBound]],
					['<=', ['to-number', ['get', 'built']], ['literal', upperBound]]
				]
			);
		}
	}

	componentDidMount = () => {

		const marks2add = [1880, 1900, 1920, 1940, 1941, 1942, 1943, 1944, 1975, 2000, 2020];

		var {marks} = this.state;

		marks2add.map((year) => {
			return marks = {...marks, [getNumber(year)]: year};
		})

		this.setState({marks: marks});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
//		console.log("%c ---> Slider: state updated", "color: blue", this.state);

		if (prevProps.bunkerLoaded !== this.props.bunkerLoaded) {
			this.filterBunkers(this.state.value);
		}
	}


	render() {
		return (
			<div>
				<div className='SliderContainer'>
					<div className="player">
						{this.state.playing ? <Pause onPlayerClick={this.handlePlayerClick}/> :
							<Play onPlayerClick={this.handlePlayerClick}/>}
					</div>
					<Range
						allowCross={false}
						draggableTrack={true}
						value={this.state.value}
						marks={this.state.marks}
						step={0.2}
//						trackStyle={[ {backgroundColor:'red'} ]}
//						railStyle={ {backgroundColor: 'black' } }
						tipFormatter={value => `${(getRoundedYear(value))}`}
						onChange={this.handleChange}
						handle={handle}
					/>
				</div>
			</div>
		)
	};
}

export default TimeSlider
