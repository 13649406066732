import React from 'react';
import mapboxgl from 'mapbox-gl';

import * as turf from "@turf/turf";

//import bordercoords2 from '../data/borders2';
import bordercoords3 from "../data/borders3";
import settlement_data from "../data/data";

import {toUnique} from "../helpers";

class BasicLayer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
//			lng: 11.5,
//			lat: 46.6,
//			zoom: 8.5,
			lng: this.props.states.lng,
			lat: this.props.states.lat,
			zoom: this.props.states.zoom
		}
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

//		console.log("--------------",this.props);

		const {map} = this.props.states;

		// ==================================================================================
		//  MAP is loaded, starting to add layers & layout
		// ==================================================================================

		if (this.props.states.mapLoaded !== prevProps.states.mapLoaded) {

			// check movements
			map.on('move', () => {
				this.setState({
					lng: map.getCenter().lng.toFixed(4),
					lat: map.getCenter().lat.toFixed(4),
					zoom: map.getZoom().toFixed(2),
					pitch: map.getPitch().toFixed(1),
					bearing: map.getBearing().toFixed(1)
				});
			});

//			console.log("%c---> basic layer: map loaded", " color: orangered", this.props);

//			console.log("%c" + string, "color: blue");


			map.setLayoutProperty("data-driven-lines", 'visibility', 'none');


			var layers = map.getStyle().layers;
//			console.log(layers); // Find the index of the first symbol layer in the map style
			// eslint-disable-next-line
			var firstSymbolId;
			var choroplethId;
			for (var i = 0; i < layers.length; i++) {
//				if (layers[i].type === 'symbol') {
//					firstSymbolId = layers[i].id;
//					break;
//				}
				if (layers[i].id === 'choropleth-fill') {
					choroplethId = layers[i].id;
					break;
				}
			}

			const bounds = [-180, 0, +180, 90]; // wsen

			// ==================================================================================
			// color some countries
			// ==================================================================================

			map.addLayer(
				{
					id: 'country-boundaries',
					source: {
						type: 'vector',
						url: 'mapbox://mapbox.country-boundaries-v1',
					},
					'source-layer': 'country_boundaries',
					type: 'fill',
					paint: {
						'fill-color': '#fff',
						'fill-opacity': 0.3,
					},
				},
				'country-label'
			);

			map.setFilter('country-boundaries',
				[
					'!=', ['get', 'iso_3166_1_alpha_3'], 'ITA'
				]);


			// mask
			function polyMask(mask, bounds) {
				var bboxPoly = turf.bboxPolygon(bounds);
				return turf.difference(bboxPoly, mask);
			}

//			var mask = turf.polygon(bordercoords2);
			var mask = turf.polygon(bordercoords3);

			map.addSource('mask', {
				"type": "geojson",
				"data": polyMask(mask, bounds)
			});

			map.addLayer({
				"id": "zmask",
				"source": "mask",
				"type": "fill",
				"text-allow-overlap": true,
				"paint": {
//					"fill-outline-color": '#0747b4',
					"fill-color": "white",
					'fill-opacity': 0.5
				}
//			}, firstSymbolId);
			}, choroplethId);


			// ==================================================================================
			// recolor all settlements in trentin
			// ==================================================================================

			var featIds = [];

			settlement_data.forEach((d) => {
				featIds.push(d.id);
			});

			featIds = toUnique(featIds);

			let lightcolor = '#777777';

			map.setPaintProperty('settlement-minor-label copy', 'text-color',
				['case',
					['!=', ['get', 'iso_3166_2'], 'IT-32'], lightcolor,
					['==', ['get', 'name'], 'Trento'], lightcolor,
					['match', ['id'], featIds, lightcolor, '#000000']
				]
			);

			map.setLayoutProperty("msmuc-7ay4yckz", 'visibility', 'visible');
			map.setLayoutProperty("msmuc-5f8eyat9", 'visibility', 'visible');

			// ==================================================================================
			// fill suedtirol
			// ==================================================================================

			/* * * *
			map.addSource('border', {
				'type': 'geojson',
				'data': {
					'type': 'Feature',
					'properties': {'name':'ST'},
					'geometry': {
						'type': 'Polygon',
						'coordinates':
						bordercoords2
					}
				}
			});

			map.addLayer({
				'id': 'border',
				'type': 'line',
				'source': 'border',
				'layout': {},
				'paint': {
					'line-color': '#210099',
					'line-width': 1,
					'line-dasharray': [3, 3]
				}
			});
			* * * */

			// ==================================================================================
			// show images
			// ==================================================================================

//				var a1 = 10.31;
//				var a2 = a1+2.25;
//				var b1 = 46.205;
//				var b2 = b1+1;
//
//				map.addSource("myImageSource", {
//					"type": "image",
//					"url": "sperren.jpg",
//					"coordinates": [
//						[a1, b2],
//						[a2+0.03, b2-0.025],
//						[a2, b1-0.06],
//						[a1, b1]
//					]
//				});
//
//				map.addLayer({
//					"id": "overlay",
//					"source": "myImageSource",
//					"type": "raster",
//					"paint": {
//						"raster-opacity": 0.2
//					}
//				});

		}
	}




	render() {
		return (
				<>
				{ this.props.debug > 0 &&
				<div>
					<div className='sidebarStyle' style={this.props.states.hideMapinfo ? {} : {display: 'none'}}>
					<div>Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}
						| Pitch: {this.state.pitch} | Bearing: {this.state.bearing}
					</div>
				</div>
					<div className='Layer'></div>
				</div>
				}
				</>

		)
	};
}

export default BasicLayer
