import React, {Fragment, Suspense, useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import {useStore} from "./store";
import LoremIpsum, {loremIpsum} from 'react-lorem-ipsum';
import BunkerDesc, {ContentLoader} from "./BunkerDesc";
import {t} from '../helpers';

//import createDOMPurify from 'dompurify'
//import {JSDOM} from 'jsdom'
import '../css/sidebars.css';

//const window = (new JSDOM('')).window
//const DOMPurify = createDOMPurify(window)

class Sidebars extends React.PureComponent {

	constructor(props) {
		super(props);
		this.scrollRef = React.createRef();
		this.state = {
			lang: this.props.lang,
			activateSidebars: this.props.activateSidebars,
			sidebarData: {
				leftCollapsed: true,
				rightCollapsed: true,
				movingStatus: "stopped"
			}
		}
	}

	handleData = newdata => {
		this.setState(prevState => ({
			sidebarData: {...prevState.sidebarData, ...newdata}
		}));
	}

	getSidebarState = (id) => {
		var elem = document.getElementById(id);
		if (elem === null) {
			return
		}
		var classes = elem.className.split(' ');

		var collapsed = classes.indexOf('collapsed') !== -1;
		return collapsed
	}

	toggleSidebar = (id) => {

		const {map} = this.props;

		map.fire('sideswipestart');

		var elem = document.getElementById(id);
		var classes = elem.className.split(' ');

		var collapsed = classes.indexOf('collapsed') !== -1;

		var padding = {};

		if (collapsed) {
			// Remove the 'collapsed' class from the class list of the element, this sets it back to the expanded state.
			classes.splice(classes.indexOf('collapsed'), 1);

			padding[id] = id === "left" ? 500 : 500; // In px, matches the width of the sidebars set in .sidebar CSS class
			map.easeTo({
				padding: padding,
				duration: 2000 // In ms, CSS transition duration property for the sidebar matches this value
			}, {sidebar: 1});


			this.handleData(id === "left" ? {leftCollapsed: false, movingStatus: "moving"} : {
				rightCollapsed: false,
				movingStatus: "moving"
			});

		} else {
			padding[id] = 0;
			// Add the 'collapsed' class to the class list of the element
			classes.push('collapsed');

			map.easeTo({
				padding: padding,
				duration: 2000
			}, {sidebar: 2});

			this.handleData(id === "left" ? {leftCollapsed: true, movingStatus: "moving"} : {
				rightCollapsed: true,
				movingStatus: "moving"
			});

		}

		// Update the class list on the element
		elem.className = classes.join(' ');
	}


	closeSidebar = (id) => {

		const {map} = this.props;

		map.fire('sideswipestart');

		var elem = document.getElementById(id);
		var classes = elem.className.split(' ');

		var collapsed = classes.indexOf('collapsed') !== -1;

		var padding = {};

			padding[id] = 0;
			// Add the 'collapsed' class to the class list of the element
			classes.push('collapsed');

			map.easeTo({
				padding: padding,
				duration: 2000
			}, {sidebar: 2});

			this.handleData(id === "left" ? {leftCollapsed: true, movingStatus: "moving"} : {
				rightCollapsed: true,
				movingStatus: "moving"
			});


		// Update the class list on the element
		elem.className = classes.join(' ');
	}

	openSidebar = (id) => {

		const {map} = this.props;

		map.fire('sideswipestart');

		var elem = document.getElementById(id);
		var classes = elem.className.split(' ');

		var collapsed = classes.indexOf('collapsed') !== -1;

		var padding = {};

			// Remove the 'collapsed' class from the class list of the element, this sets it back to the expanded state.
			classes.splice(classes.indexOf('collapsed'), 1);

			padding[id] = id === "left" ? 500 : 500; // In px, matches the width of the sidebars set in .sidebar CSS class
			map.easeTo({
				padding: padding,
				duration: 2000 // In ms, CSS transition duration property for the sidebar matches this value
			}, {sidebar: 1});


			this.handleData(id === "left" ? {leftCollapsed: false, movingStatus: "moving"} : {
				rightCollapsed: false,
				movingStatus: "moving"
			});


		// Update the class list on the element
		elem.className = classes.join(' ');
	}


	action = (d) => {
		// console.log("remoteCallback", d);
		this.props.storyRemoteCallback(d);
	}

	componentDidMount() {
		// INIT sidebars
		this.handleData();
	}

	componentDidUpdate(prevProps, prevState) {

//		console.log("%c ---> Sidebars: state updated", "color: blue", this.state, prevProps, this.props, this.state.sidebarData.leftCollapsed);
//		console.log("%c left ::: ", "color: blue", this.state.sidebarData.leftCollapsed);
//		console.log("%c act  ::: ", "color: blue", this.state.sidebarData.activateSidebars);
//		console.log("%c prev ::: ", "color: blue", prevProps.sidebarsAction);
//		console.log("%c this ::: ", "color: blue", this.props.sidebarsAction);
//		console.log("%c props ::: ", "color: blue", this.props);

		if (prevProps.sidebarsAction === "close left" && this.props.sidebarsAction === "close left" && this.props.storyRemoteAction === 'end') {
			if (this.getSidebarState('left')) {
				this.props.sidebarsCallback({sidebarsAction: "none"});
//				console.log('toggle sidebar called')
				setTimeout(() => {
//					console.log('toggle_001')
//					this.toggleSidebar('left');
				}, 2000)
			}
		}

		if (prevProps.sidebarsAction === "close left" && this.props.sidebarsAction === "close left" &&
			(prevProps.storyRemoteAction === 'end' || this.props.storyRemoteAction === 'none')
		) {
				setTimeout(() => {
					if (this.getSidebarState('left') === false) {
						this.closeSidebar('left');
						this.props.sidebarsCallback({sidebarsAction: "none"});
					}
				}, 2500)
			return
		}

		if (this.props.storyRemoteAction === 'end') {
			setTimeout(() => {
				this.props.storyRemoteCallback("end");
				this.props.map.off('moveend')
			}, 0)
			return
		}

		if (prevProps.sidebarsAction && prevProps.sidebarsAction !== this.props.sidebarsAction) {

			const {sidebarsAction} = this.props;

			if (sidebarsAction !== "none") {
				switch (sidebarsAction) {
					case "open left":
						if (this.getSidebarState('left') === true && useStore.getState().remoteAction !== 'ending') {
							this.scrollRef.current.scrollTo(0, 0);
							this.props.sidebarsCallback({sidebarsAction: "none"});
							//wait a little bit so content can render meanwhile
//							setTimeout(()=>{this.toggleSidebar('left'); console.log('toggle_003')}, 1000);
							this.openSidebar('left')
						} else {
							this.props.sidebarsCallback({sidebarsAction: "none"});
						}
						break;
					case "close left":
						if (this.getSidebarState('left') === false) {
							this.props.sidebarsCallback({sidebarsAction: "none"});
							this.closeSidebar('left');
						} else {
							// console.log("left sidebar already closed");
						}
						break;
					case "open right":
						if (this.getSidebarState('right') === true) {
							this.props.sidebarsCallback({sidebarsAction: "none"});
							this.openSidebar('right');
						} else {
							this.props.sidebarsCallback({sidebarsAction: "none"});
							// console.log("right sidebar already opened");
						}
						break;
					case "close right":
						if (this.getSidebarState('right') === false) {
							this.props.sidebarsCallback({sidebarsAction: "none"});
							this.closeSidebar('right');
						} else {
							// console.log("right sidebar already closed");
						}
						break;

					// XXX buggy, stops flight
					case "close both":
						if (this.getSidebarState('left') === false) {
							this.closeSidebar('left');
						}
						if (this.getSidebarState('right') === false) {
							this.closeSidebar('right');
						}
						this.props.sidebarsCallback({sidebarsAction: "none"});
						break;

					default:
						break;
				}
			}
		}

		if (prevProps.activateSidebars !== this.props.activateSidebars && this.props.activateSidebars === true) {

//			console.log("::: START SWIPE HANDLERS");

			const {map} = this.props;

			var flyroute = 0;

			var swiping = false;

			map.on('sideswipestart', (e, d) => {
				swiping = true;
				// console.log("Sidebars swiping started: ", flyroute, e);
			});

			map.on('sideswipeend', (e, d) => {
				swiping = false;
				// console.log("Sidebars swiping stopped: ", flyroute, e);
			});

		}
	}

	render() {

		const rarrow = this.getSidebarState('right') ? '&lsaquo;' : '&rsaquo;';
		const larrow = this.getSidebarState('left') ? '&rsaquo;' : '&lsaquo;';

		return (
			<div className='Sidebars' style={{marginTop: '55px'}}>
				<div id="left" className="leftsidebar flex-center left collapsed">
					<div className="sidebar-content rounded-rect flex-center">
						<div className="sidebar-toggle rounded-rect left" onClick={() => this.toggleSidebar('left')}>
							<div dangerouslySetInnerHTML={{__html: larrow}}/>
						</div>
						<Suspense fallback={<h3>loading</h3>}>
						<div ref={this.scrollRef} className="content contentbox">
							{this.props.leftSidebarsContent.map((item, i) => (
								<div key={i} className="entry">
									{item.title_de && this.props.lang === 'DE' && <h3>{item.title_de}</h3>}
									{item.title_en && this.props.lang === 'EN' && <h3>{item.title_en}</h3>}
									{item.title_it && this.props.lang === 'IT' && <h3>{item.title_it}</h3>}
									{item.text_de && this.props.lang === 'DE' && <p>{item.text_de}</p>}
									{item.text_en && this.props.lang === 'EN' && <p>{item.text_en}</p>}
									{item.text_it && this.props.lang === 'IT' && <p>{item.text_it}</p>}
									{/*{item.text && <p>{item.text}</p>}*/}
									{item.image && <img src={item.image} width={'400px'}/>}
									{item.desc && <div className={'contentbox'}> <ContentLoader id={item.desc} side={'left'}/> </div>}
									{item.action && item.action.buttonAction &&
									<div className="button-container">
										<div className="content-button"
										     onClick={() => this.action(item.action.buttonAction)}>{t(item.action.buttonName)}
										</div>
									</div>
									}
								</div>
							))}

						</div>
						</Suspense>
					</div>
				</div>
				<div id="right" className="rightsidebar flex-center right collapsed">
					<div className="sidebar-content rounded-rect flex-center">
						{/*{<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.leftSidebarsContent)}}/>}*/}
						{/*{this.props.leftSidebarsContent}*/}
						<div className="content">
							<BunkerDesc></BunkerDesc>
						</div>
						<div className="sidebar-toggle rounded-rect right" onClick={() => this.toggleSidebar('right')}>
							<div dangerouslySetInnerHTML={{__html: rarrow}}/>
						</div>
					</div>
				</div>
			</div>
		)
	};
}

export default Sidebars
