
const settlement_data =

	[{"id": 647768380, "name": "Cles", "coords": [11.033363342285156, 46.36517296998505]}, {
		"id": 647766120,
		"name": "Malé",
		"coords": [10.912857055664062, 46.35166725060853]
	}, {"id": 647767230, "name": "Dimaro", "coords": [10.870628356933594, 46.32630539389572]}, {
		"id": 647765250,
		"name": "Rabbi",
		"coords": [10.844879150390625, 46.400935144390445]
	}, {"id": 647766170, "name": "Peio", "coords": [10.6732177734375, 46.36280378698507]}, {
		"id": 647765540,
		"name": "Vermiglio",
		"coords": [10.691413879394531, 46.29689936725342]
	}, {
		"id": 17963575820,
		"name": "Madonna di Campiglio",
		"coords": [10.8270263671875, 46.226877974151705]
	}, {"id": 43113629890, "name": "Pinzolo", "coords": [10.764884948730469, 46.161761044983535]}, {
		"id": 647769000,
		"name": "Spiazzo",
		"coords": [10.739479064941406, 46.10370875598025]
	}, {
		"id": 647768530,
		"name": "San Lorenzo in Banale",
		"coords": [10.908393859863281, 46.076564991185734]
	}, {"id": 647769880, "name": "Molveno", "coords": [10.963668823242188, 46.1420206172383]}, {
		"id": 647767570,
		"name": "Andalo",
		"coords": [11.003494262695312, 46.166041167354564]
	}, {"id": 647766350, "name": "Mezzolombardo", "coords": [11.09344482421875, 46.21215024064955]}, {
		"id": 647765910,
		"name": "Campodenno",
		"coords": [11.033363342285156, 46.25774588045684]
	}, {"id": 20000254040, "name": "Segno", "coords": [11.074562072753906, 46.30567539999592]}, {
		"id": 9820510120,
		"name": "Peio Fonti",
		"coords": [10.662875175476074, 46.35468855662495]
	}, {"id": 12934931670, "name": "Cogolo", "coords": [10.692057609558105, 46.35320754513441]}, {
		"id": 12934931640,
		"name": "Celledizzo",
		"coords": [10.701842308044434, 46.34497238930234]
	}, {"id": 12934931540, "name": "Comasine", "coords": [10.705575942993164, 46.32858725736904]}, {
		"id": 21347871410,
		"name": "Cortina",
		"coords": [10.701069831848145, 46.300961422141455]
	}, {"id": 647769860, "name": "Pellizzano", "coords": [10.75960636138916, 46.30970715961112]}, {
		"id": 13936732710,
		"name": "Termenago",
		"coords": [10.76711654663086, 46.31652490084065]
	}, {"id": 13936732700, "name": "Castello", "coords": [10.77260971069336, 46.321800656710906]}, {
		"id": 13936754270,
		"name": "Ortisè",
		"coords": [10.784368515014648, 46.32701663428014]
	}, {"id": 647766210, "name": "Mezzana", "coords": [10.801019668579102, 46.31732518867284]}, {
		"id": 3312593640,
		"name": "Marilleva 1400",
		"coords": [10.81003189086914, 46.30238455256742]
	}, {"id": 13042048790, "name": "Piano", "coords": [10.827970504760742, 46.31999273025008]}, {
		"id": 13042048800,
		"name": "Almazzago",
		"coords": [10.84475040435791, 46.31919248143174]
	}, {"id": 647769900, "name": "Monclassico", "coords": [10.888781547546387, 46.334810037576204]}, {
		"id": 13041925890,
		"name": "Montes",
		"coords": [10.874919891357422, 46.3392248189044]
	}, {"id": 13041925860, "name": "Bolentina", "coords": [10.882859230041504, 46.344379884206376]}, {
		"id": 647766190,
		"name": "Croviana",
		"coords": [10.904617309570312, 46.34435025878298]
	}, {"id": 14355632630, "name": "Cagliari", "coords": [10.881357192993164, 46.37497437266077]}, {
		"id": 14355631690,
		"name": "Pozze",
		"coords": [10.867366790771484, 46.37852731686266]
	}, {"id": 3275803610, "name": "Folgarida", "coords": [10.867152214050293, 46.299360356195336]}, {
		"id": 43935142820,
		"name": "Fazzon",
		"coords": [10.762910842895508, 46.29686971610991]
	}, {
		"id": 57440962540,
		"name": "Campo Carlo Magno",
		"coords": [10.839986801147461, 46.24373875230856]
	}, {
		"id": 14316386910,
		"name": "Sant'Antonio di Mavignola",
		"coords": [10.785441398620605, 46.18960559735362]
	}, {"id": 647765990, "name": "Carisolo", "coords": [10.759177207946777, 46.16877551564616]}, {
		"id": 10970326070,
		"name": "Passo Tonale",
		"coords": [10.58682918548584, 46.2602086285043]
	}, {"id": 12934931570, "name": "Celentino", "coords": [10.71789264678955, 46.329742970084936]}, {
		"id": 18017631730,
		"name": "Fucine",
		"coords": [10.73115348815918, 46.30982573628077]
	}, {"id": 647766290, "name": "Ossana", "coords": [10.737719535827637, 46.3064462005286]}, {
		"id": 18017631720,
		"name": "Cusiano",
		"coords": [10.746002197265625, 46.310922558297165]
	}, {"id": 3312594930, "name": "Marilleva 900", "coords": [10.812520980834961, 46.314746441531895]}, {
		"id": 34037009750,
		"name": "Mestriago",
		"coords": [10.839557647705078, 46.322008119860726]
	}, {"id": 10984244660, "name": "Costa Rotian", "coords": [10.862388610839844, 46.31999273025008]}, {
		"id": 13042048830,
		"name": "Deggiano",
		"coords": [10.857024192810059, 46.32787603740641]
	}, {"id": 24284302340, "name": "Carciato", "coords": [10.883331298828125, 46.32399379878018]},
		{"id": 13968390320, "name": "Patascoss", "coords": [10.817971229553223, 46.2317764453297]}, {
		"id": 6005302440,
		"name": "Vadaione",
		"coords": [10.767889022827148, 46.151891716160435]
	}, {"id": 647768480, "name": "Giustino", "coords": [10.769476890563965, 46.14844297917256]}, {
		"id": 647766220,
		"name": "Massimeno",
		"coords": [10.773124694824219, 46.14175300256434]
	}, {"id": 647765670, "name": "Caderzone Terme", "coords": [10.757460594177246, 46.13021459975624]}, {
		"id": 4621283200,
		"name": "Ches",
		"coords": [10.737676620483398, 46.09606104244722]
	}, {"id": 647766560, "name": "Pelugo", "coords": [10.723857879638672, 46.08850155956725]}, {
		"id": 647769610,
		"name": "Vigo Rendena",
		"coords": [10.721969604492188, 46.07989915549703]
	}, {"id": 15838245140, "name": "Javrè", "coords": [10.711455345153809, 46.068258473704134]}, {
		"id": 647769480,
		"name": "Villa Rendena",
		"coords": [10.711069107055664, 46.06170765002042]
	}, {"id": 15838224620, "name": "Verdesina", "coords": [10.713214874267578, 46.05482844852369]}, {
		"id": 647768980,
		"name": "Tione di Trento",
		"coords": [10.72690486907959, 46.03630096985506]
	}, {"id": 18444581210, "name": "Polin", "coords": [10.720725059509277, 46.02608139019583]}, {
		"id": 6740372940,
		"name": "Breguzzo",
		"coords": [10.699396133422852, 46.007663500444835]
	}, {"id": 647767970, "name": "Bondo", "coords": [10.691971778869629, 46.000300589154364]}, {
		"id": 647767370,
		"name": "Roncone",
		"coords": [10.668196678161621, 45.98270910221842]
	}, {"id": 647767430, "name": "Lardaro", "coords": [10.661158561706543, 45.97018294192685]}, {
		"id": 647767000,
		"name": "Zuclo",
		"coords": [10.751667022705078, 46.03463259612505]
	}, {"id": 11696560620, "name": "Saone", "coords": [10.766258239746094, 46.04255692274327]}, {
		"id": 647766600,
		"name": "Preore",
		"coords": [10.758275985717773, 46.04625058137151]
	}, {"id": 11696559960, "name": "Cort", "coords": [10.753726959228516, 46.051731039059774]}, {
		"id": 11696563300,
		"name": "Larzana",
		"coords": [10.751795768737793, 46.05911688253116]
	}, {"id": 11683682420, "name": "Binio", "coords": [10.757203102111816, 46.06721634920643]}, {
		"id": 11683118430,
		"name": "Cerana",
		"coords": [10.764455795288086, 46.05459019242474]
	}, {"id": 647767670, "name": "Ragoli", "coords": [10.777287483215332, 46.053607375151216]}, {
		"id": 10024173670,
		"name": "Coltura",
		"coords": [10.794196128845215, 46.05834260658429]
	}, {"id": 15926340030, "name": "Irone", "coords": [10.81303596496582, 46.064000526733565]}, {
		"id": 4324404650,
		"name": "Agrone",
		"coords": [10.651159286499023, 45.955595342458]
	}, {"id": 44853869380, "name": "Vermongoi", "coords": [10.557775497436523, 45.97343405102055]}, {
		"id": 7324857530,
		"name": "Pracul",
		"coords": [10.542840957641602, 45.977430565818906]
	}, {"id": 42648584910, "name": "Lert", "coords": [10.534515380859375, 45.987659136695555]}, {
		"id": 23697428560,
		"name": "Angleri",
		"coords": [10.59257984161377, 45.94658431335219]
	}, {"id": 18582370740, "name": "Daone", "coords": [10.621075630187988, 45.94712143561489]}, {
		"id": 23702923860,
		"name": "Praso",
		"coords": [10.636825561523438, 45.94921019493927]
	}, {"id": 23702923850, "name": "Bersone", "coords": [10.633392333984375, 45.94303320745294]}, {
		"id": 38966476660,
		"name": "Creto",
		"coords": [10.637898445129395, 45.93798964933259]
	}, {"id": 5772458450, "name": "Por", "coords": [10.648455619812012, 45.93986984593636]}, {
		"id": 647769690,
		"name": "Prezzo",
		"coords": [10.631976127624512, 45.93500507936153]
	}, {"id": 4324379330, "name": "Cologna", "coords": [10.638198852539062, 45.930438376403885]}, {
		"id": 647766050,
		"name": "Cimego",
		"coords": [10.612835884094238, 45.91189907444513]
	}, {"id": 647765660, "name": "Brione", "coords": [10.589790344238281, 45.895205419237755]}, {
		"id": 647768070,
		"name": "Condino",
		"coords": [10.60081958770752, 45.88929125696529]
	}, {"id": 647766830, "name": "Tiarno di Sopra", "coords": [10.66948413848877, 45.88839511683253]}, {
		"id": 647768920,
		"name": "Tiarno di Sotto",
		"coords": [10.686478614807129, 45.89368213462703]
	}, {"id": 4806778760, "name": "Ca' Rossa", "coords": [10.566744804382324, 45.85471964557496]}, {
		"id": 4698691350,
		"name": "Darzo",
		"coords": [10.549449920654297, 45.84201507917459]
	}, {"id": 4698691360, "name": "Lodrone", "coords": [10.535545349121094, 45.82966644991649]}, {
		"id": 647767950,
		"name": "Bezzecca",
		"coords": [10.717720985412598, 45.89693773134931]
	}, {"id": 647769710, "name": "Pieve di Ledro", "coords": [10.732011795043945, 45.89015751201728]}, {
		"id": 4984276740,
		"name": "Lenzumo",
		"coords": [10.726947784423828, 45.9129142689919]
	}, {"id": 3012447390, "name": "Mezzolago", "coords": [10.750808715820312, 45.88122547528343]}, {
		"id": 647769300,
		"name": "Molina di Ledro",
		"coords": [10.7755708694458, 45.871305945783064]
	}, {"id": 2690070270, "name": "Prè di Ledro", "coords": [10.789432525634766, 45.866225983151224]}, {
		"id": 2690052170,
		"name": "Biacesa di Ledro",
		"coords": [10.805482864379883, 45.86550877455744]
	}, {"id": 2690043500, "name": "Pregasina", "coords": [10.824751853942871, 45.851640922947894]}, {
		"id": 647768440,
		"name": "Riva del Garda",
		"coords": [10.839686393737793, 45.884780538207394]
	}, {"id": 2991269930, "name": "Foci", "coords": [10.831961631774902, 45.89663906070274]}, {
		"id": 6001477870,
		"name": "Dom",
		"coords": [10.832648277282715, 45.89917770999398]
	}, {"id": 4953160450, "name": "Parisi", "coords": [10.803422927856445, 45.91294412737392]}, {
		"id": 3014538700,
		"name": "Campi",
		"coords": [10.811319351196289, 45.911391470208]
	}, {"id": 2978798050, "name": "Pranzo", "coords": [10.819387435913086, 45.922438278080705]}, {
		"id": 2999423360,
		"name": "Canale",
		"coords": [10.826597213745117, 45.929602599209744]
	}, {"id": 3006759380, "name": "Calvola", "coords": [10.831961631774902, 45.92643847146212]}, {
		"id": 647766960,
		"name": "Tenno",
		"coords": [10.83277702331543, 45.91879606012046]
	}, {"id": 3004158520, "name": "Ceole", "coords": [10.852217674255371, 45.911182455349234]}, {
		"id": 2997327360,
		"name": "Fangolino",
		"coords": [10.856766700744629, 45.90407548180471]
	}, {"id": 3318127440, "name": "San Giorgio", "coords": [10.86646556854248, 45.89869985546542]}, {
		"id": 3171734460,
		"name": "La Grotta",
		"coords": [10.866937637329102, 45.89335357756639]
	}, {"id": 5101908030, "name": "Sant'Alessandro", "coords": [10.86221694946289, 45.88982893410517]}, {
		"id": 1074523460,
		"name": "Nago",
		"coords": [10.889983177185059, 45.87758055276021]
	}, {"id": 51848260840, "name": "Prato Saiano", "coords": [10.888824462890625, 45.90401575548262]}, {
		"id": 647767550,
		"name": "Arco",
		"coords": [10.886077880859375, 45.918019818828725]
	}, {"id": 2996510130, "name": "Bolognano", "coords": [10.9039306640625, 45.91321285208926]}, {
		"id": 22698918070,
		"name": "Chiarano",
		"coords": [10.870499610900879, 45.92088588654684]
	}, {"id": 23725519720, "name": "Padaro", "coords": [10.869126319885254, 45.93252776429105]}, {
		"id": 3018504170,
		"name": "San Martino",
		"coords": [10.896849632263184, 45.92628921569559]
	}, {"id": 8639350150, "name": "Moletta", "coords": [10.895605087280273, 45.93178156336711]}, {
		"id": 3229002910,
		"name": "Maso Giare",
		"coords": [10.906977653503418, 45.94377925697847]
	}, {"id": 3228976530, "name": "Ceniga", "coords": [10.901355743408203, 45.95135855098599]}, {
		"id": 3030660970,
		"name": "Braila",
		"coords": [10.92839241027832, 45.95425273233113]
	}, {"id": 647768180, "name": "Dro", "coords": [10.911097526550293, 45.96147260833885]}, {
		"id": 3015583960,
		"name": "San Giovanni al Monte",
		"coords": [10.888009071350098, 45.9725690956744]
	}, {"id": 3000270470, "name": "Ballino", "coords": [10.811104774475098, 45.96588760611891]}, {
		"id": 647768160,
		"name": "Drena",
		"coords": [10.944485664367676, 45.96928810776777]
	}, {"id": 22426841310, "name": "Coste", "coords": [10.964698791503906, 45.97024259702346]}, {
		"id": 6768685420,
		"name": "Luch",
		"coords": [10.96409797668457, 45.96490321141775]
	}, {"id": 6768976730, "name": "Masi di Vigo", "coords": [10.97383975982666, 45.96564896658552]}, {
		"id": 7961563790,
		"name": "S. Barbara",
		"coords": [10.939807891845703, 45.89666892783967]
	}, {"id": 8717829420, "name": "Prà del Lac", "coords": [10.964140892028809, 45.894398979648656]}, {
		"id": 18415467450,
		"name": "Pannone",
		"coords": [10.934915542602539, 45.877162267669036]
	}, {
		"id": 18415467520,
		"name": "Valle San Felice",
		"coords": [10.937533378601074, 45.86966247926563]
	}, {"id": 17783530560, "name": "Manzano", "coords": [10.953497886657715, 45.86565819377756]}, {
		"id": 8717829300,
		"name": "Sant'Antonio",
		"coords": [10.937662124633789, 45.85510821010422]
	}, {"id": 3033842110, "name": "Ravazzone", "coords": [10.99255084991455, 45.862012250152475]}, {
		"id": 8236418170,
		"name": "Seghe II°e",
		"coords": [10.991392135620117, 45.85247787412251]
	}, {"id": 647769920, "name": "Mori", "coords": [10.978260040283203, 45.85244798322603]}, {
		"id": 3028283440,
		"name": "Tierno",
		"coords": [10.977702140808105, 45.84634990442018]
	}, {"id": 1074329380, "name": "Sano", "coords": [10.952038764953613, 45.84617053904023]}, {
		"id": 26213549840,
		"name": "Castione",
		"coords": [10.953025817871094, 45.837291229872534]
	}, {"id": 26213549750, "name": "Besagno", "coords": [10.969891548156738, 45.83815829555388]}, {
		"id": 26213549780,
		"name": "Brentonico",
		"coords": [10.956029891967773, 45.81878064039978]
	}, {"id": 26213550900, "name": "Sorne", "coords": [10.952725410461426, 45.80765338301481]}, {
		"id": 26213550080,
		"name": "Cornè",
		"coords": [10.971221923828125, 45.80825167921023]
	}, {"id": 3052683560, "name": "Zembrae", "coords": [10.980491638183594, 45.81127297682781]}, {
		"id": 26213550470,
		"name": "Molini",
		"coords": [10.999588966369629, 45.81402490727433]
	}, {"id": 26213549920, "name": "Chizzola", "coords": [11.008729934692383, 45.811123411494606]}, {
		"id": 3035466200,
		"name": "Villetta",
		"coords": [11.011862754821777, 45.805559295723754]
	}, {"id": 26213550550, "name": "Prada", "coords": [10.958304405212402, 45.8011913746204]}, {
		"id": 3052637240,
		"name": "San Valentino",
		"coords": [10.905861854553223, 45.782788495366475]
	}, {"id": 20946923360, "name": "Saccone", "coords": [10.972380638122559, 45.79598532274545]}, {
		"id": 17546967110,
		"name": "Sabbionara",
		"coords": [10.95362663269043, 45.74024413006694]
	}, {"id": 647767520, "name": "Avio", "coords": [10.937190055847168, 45.73380426113462]}, {
		"id": 26472474730,
		"name": "Vò Destro",
		"coords": [10.958776473999023, 45.73617062486704]
	}, {"id": 26472474720, "name": "Sdruzzinà", "coords": [10.978474617004395, 45.74072334643563]}, {
		"id": 44640798060,
		"name": "Ala",
		"coords": [11.00182056427002, 45.757912513097864]
	}, {"id": 23324453060, "name": "Pilcante", "coords": [11.000018119812012, 45.77078594683931]}, {
		"id": 26472474690,
		"name": "Cumerlotti",
		"coords": [11.018514633178711, 45.78174099428409]
	}, {"id": 3220796730, "name": "Sgardaiolo", "coords": [11.03177547454834, 45.79281358146139]}, {
		"id": 26213550780,
		"name": "Santa Margherita",
		"coords": [11.025638580322266, 45.79787032873651]
	}, {"id": 3221034350, "name": "Còleri", "coords": [11.024351119995117, 45.803554881297146]}, {
		"id": 26472474680,
		"name": "Brustolotti",
		"coords": [11.017484664916992, 45.755547070852145]
	}, {"id": 3174011110, "name": "Muravalle", "coords": [11.037869453430176, 45.74779131011476]}, {
		"id": 26472474710,
		"name": "Ronchi",
		"coords": [11.06546401977539, 45.739195829920845]
	}, {"id": 3036265540, "name": "Borghetto", "coords": [10.926933288574219, 45.698596507915]}, {
		"id": 50621316330,
		"name": "Villaggio San Michele",
		"coords": [10.969033241271973, 45.69961559710953]
	}, {"id": 3174011120, "name": "Ometto", "coords": [11.118464469909668, 45.75949939916342]}, {
		"id": 3174011230,
		"name": "Zendri",
		"coords": [11.11640453338623, 45.76542736673969]
	}, {"id": 18063199270, "name": "Bastianello", "coords": [11.12790584564209, 45.762643096776884]}, {
		"id": 3015821260,
		"name": "Speccheri",
		"coords": [11.129751205444336, 45.76584649211975]
	}, {"id": 18063199280, "name": "Camposilvano", "coords": [11.144213676452637, 45.759379635751856]}, {
		"id": 9762164070,
		"name": "Streva",
		"coords": [11.16112232208252, 45.76225388668007]
	}, {"id": 3174011080, "name": "Martini", "coords": [11.134300231933594, 45.77258200374433]}, {
		"id": 3174011030,
		"name": "Bruni",
		"coords": [11.107864379882812, 45.776443330234656]
	}, {"id": 3174011160, "name": "Riva", "coords": [11.104817390441895, 45.779765652980416]}, {
		"id": 3174011140,
		"name": "Raossi",
		"coords": [11.118850708007812, 45.78263885355969]
	}, {"id": 3174011070, "name": "Foxi", "coords": [11.111469268798828, 45.79015037625405]}, {
		"id": 18308510160,
		"name": "Aste",
		"coords": [11.096105575561523, 45.79062916410081]
	}, {"id": 18063199260, "name": "Anghebeni", "coords": [11.106491088867188, 45.79532705166346]}, {
		"id": 49792499380,
		"name": "Nave",
		"coords": [11.094002723693848, 45.80101186368742]
	}, {"id": 18311202570, "name": "Zocchio", "coords": [11.096148490905762, 45.809807219243396]}, {
		"id": 18308510670,
		"name": "Tezze",
		"coords": [11.096234321594238, 45.81725526081166]
	}, {"id": 18308510290, "name": "Dosso", "coords": [11.092414855957031, 45.82000689565143]}, {
		"id": 18308510390,
		"name": "Matassone",
		"coords": [11.07846736907959, 45.81561019677767]
	}, {"id": 3174011240, "name": "Zugna", "coords": [11.057438850402832, 45.808580739378556]}, {
		"id": 18308510760,
		"name": "Zanolli",
		"coords": [11.071000099182129, 45.822758394512675]
	}, {"id": 51429359890, "name": "Ganne", "coords": [11.081600189208984, 45.82709471993397]}, {
		"id": 3174011130,
		"name": "Pozzacchio",
		"coords": [11.08245849609375, 45.83585605698724]
	}, {"id": 18308510350, "name": "Foppiano", "coords": [11.063446998596191, 45.84066971990268]}, {
		"id": 18308510070,
		"name": "Albaredo",
		"coords": [11.061086654663086, 45.856154331880816]
	}, {"id": 18059012000, "name": "Vanza", "coords": [11.078424453735352, 45.85415173868691]}, {
		"id": 3063966670,
		"name": "Boccaldo",
		"coords": [11.086664199829102, 45.857529204858196]
	}, {"id": 3232336590, "name": "Giazzera", "coords": [11.0982084274292, 45.85436096821513]}, {
		"id": 3063966680,
		"name": "Pozza",
		"coords": [11.084260940551758, 45.86398467557183]
	}, {"id": 18311407250, "name": "Moscheri", "coords": [11.073660850524902, 45.86975212414691]}, {
		"id": 6528606390,
		"name": "San Colombano",
		"coords": [11.06447696685791, 45.87784944865615]
	}, {"id": 44640798320, "name": "Porte", "coords": [11.05323314666748, 45.88295822344591]}, {
		"id": 2706641550,
		"name": "Rovereto",
		"coords": [11.04525089263916, 45.886543048075794]
	}, {"id": 2983665160, "name": "Borgo Sacco", "coords": [11.025938987731934, 45.88582610165605]}, {
		"id": 17783530550,
		"name": "Isera",
		"coords": [11.009330749511719, 45.88525851251143]
	}, {"id": 13748177910, "name": "Zaffoni", "coords": [11.066880226135254, 45.89174063289411]}, {
		"id": 27424291990,
		"name": "Noriglio",
		"coords": [11.068682670593262, 45.88388432530283]
	}, {"id": 26274895370, "name": "Cisterna", "coords": [11.086535453796387, 45.88283872530761]}, {
		"id": 4102845410,
		"name": "Senter",
		"coords": [11.089925765991211, 45.87677385726505]
	}, {"id": 3647639870, "name": "Fontanelle", "coords": [11.108765602111816, 45.87474212783303]}, {
		"id": 8943883890,
		"name": "Perini",
		"coords": [11.106576919555664, 45.87841711349665]
	}, {"id": 26274895380, "name": "Moietto", "coords": [11.086277961730957, 45.89502621145223]}, {
		"id": 27433353510,
		"name": "Potrich",
		"coords": [11.119494438171387, 45.882032106151]
	}, {"id": 8943883870, "name": "Valduga", "coords": [11.13039493560791, 45.87758055276021]}, {
		"id": 19934728370,
		"name": "Pornal",
		"coords": [11.13288402557373, 45.88484028522029]
	}, {"id": 26597858030, "name": "Piazza", "coords": [11.152067184448242, 45.8790744023826]}, {
		"id": 27433353470,
		"name": "Camperi",
		"coords": [11.162924766540527, 45.87474212783303]
	}, {"id": 8943884140, "name": "Stedileri", "coords": [11.166014671325684, 45.87226212212212]}, {
		"id": 41880300100,
		"name": "Geroli",
		"coords": [11.169576644897461, 45.8652697029699]
	}, {"id": 8943884010, "name": "Serrada", "coords": [11.155457496643066, 45.89410029534747]}, {
		"id": 8944018020,
		"name": "Fondo Grande",
		"coords": [11.189618110656738, 45.89239776415599]
	}, {"id": 4276372810, "name": "Mezzaselva", "coords": [11.17434024810791, 45.9061957246104]}, {
		"id": 3709101190,
		"name": "Marangoni",
		"coords": [11.14863395690918, 45.910107509356294]
	}, {"id": 8943884080, "name": "Guardia", "coords": [11.132497787475586, 45.90712143902965]}, {
		"id": 2893044990,
		"name": "Ondertol",
		"coords": [11.128592491149902, 45.91366072372324]
	}, {"id": 2893044870, "name": "Mezzomonte", "coords": [11.139321327209473, 45.91652701659251]}, {
		"id": 4459900260,
		"name": "Peneri",
		"coords": [11.145071983337402, 45.92037836450862]
	}, {"id": 4459898540, "name": "Fontani", "coords": [11.142969131469727, 45.922766663031354]}, {
		"id": 7066649780,
		"name": "Dietrobeseno",
		"coords": [11.117992401123047, 45.92727429633322]
	}, {"id": 13902050980, "name": "Calliano", "coords": [11.093745231628418, 45.93384105353263]}, {
		"id": 19201475780,
		"name": "Besenello",
		"coords": [11.109538078308105, 45.941929035730425]
	}, {"id": 47652123220, "name": "Scanucieri", "coords": [11.131339073181152, 45.95255204614142]}, {
		"id": 10278173540,
		"name": "Nocchi",
		"coords": [11.195883750915527, 45.92076647001434]
	}, {"id": 18093570120, "name": "Cueli", "coords": [11.218070983886719, 45.920467927558605]}, {
		"id": 19929605420,
		"name": "San Sebastiano",
		"coords": [11.21330738067627, 45.92539367266306]
	}, {
		"id": 10278173570,
		"name": "Girardi di Sopra",
		"coords": [11.221504211425781, 45.92912500658724]
	}, {"id": 7665690260, "name": "Buse", "coords": [11.23626708984375, 45.93282624184948]}, {
		"id": 2878418530,
		"name": "Nosellari",
		"coords": [11.24751091003418, 45.92978169538296]
	}, {"id": 7665022520, "name": "Dazio", "coords": [11.257295608520508, 45.92781160567142]}, {
		"id": 3678916340,
		"name": "Piccoli",
		"coords": [11.264891624450684, 45.92431900194029]
	}, {"id": 7665690110, "name": "Busatti", "coords": [11.259055137634277, 45.91978127381415]}, {
		"id": 15371914420,
		"name": "Oseli",
		"coords": [11.282143592834473, 45.926319066881064]
	}, {"id": 9171096430, "name": "Gionghi", "coords": [11.273045539855957, 45.94004890895448]}, {
		"id": 2878418010,
		"name": "Chiesa",
		"coords": [11.251158714294434, 45.94112327491899]
	}, {"id": 22908742660, "name": "Virti", "coords": [11.239485740661621, 45.94616654799415]}, {
		"id": 36094353720,
		"name": "Maso Menegoi",
		"coords": [11.213479042053223, 45.951716602231244]
	}, {"id": 19184192130, "name": "Maso Maccani", "coords": [11.223177909851074, 45.9568782506322]}, {
		"id": 2878854000,
		"name": "Slaghenaufi",
		"coords": [11.268110275268555, 45.95455109294818]
	}, {"id": 9171096070, "name": "Malga", "coords": [11.298151016235352, 45.95717659711363]}, {
		"id": 21340396290,
		"name": "Masetti",
		"coords": [11.308794021606445, 45.93446783970728]
	}, {"id": 2878968140, "name": "Lusern – Luserna", "coords": [11.324286460876465, 45.92166208774333]}, {
		"id": 3174011000,
		"name": "Arlanch",
		"coords": [11.104216575622559, 45.79009052748404]
	}, {"id": 3647640020, "name": "San Nicolò", "coords": [11.096835136413574, 45.871007137303536]}, {
		"id": 11218029820,
		"name": "Fondo Piccolo",
		"coords": [11.19807243347168, 45.88206198114344]
	}, {"id": 2878848730, "name": "Bertoldi", "coords": [11.262016296386719, 45.951119848870775]}, {
		"id": 19185906760,
		"name": "Valle",
		"coords": [11.232962608337402, 45.964336430775916]
	}, {"id": 647768300, "name": "Centa San Nicolò", "coords": [11.231932640075684, 45.96803531565797]}, {
		"id": 42904879330,
		"name": "Frisanchi",
		"coords": [11.219701766967773, 45.968393259152094]
	}, {"id": 19186575370, "name": "Wolfi", "coords": [11.241331100463867, 45.9712268968409]}, {
		"id": 19186323320,
		"name": "Strada",
		"coords": [11.243734359741211, 45.97561129260731]
	}, {"id": 2933510350, "name": "Pian dei Pradi", "coords": [11.237854957580566, 45.97993569668159]}, {
		"id": 2933513780,
		"name": "Campregheri",
		"coords": [11.238713264465332, 45.983842281705165]
	}, {"id": 19186576560, "name": "Aonè", "coords": [11.250085830688477, 45.98154607810085]}, {
		"id": 19197667760,
		"name": "Dossi",
		"coords": [11.272659301757812, 45.98396156240716]
	}, {
		"id": 647767700,
		"name": "Calceranica al Lago",
		"coords": [11.242876052856445, 46.00423554625024]
	}, {"id": 647764940, "name": "Bosentino", "coords": [11.223177909851074, 46.00179113613345]}, {
		"id": 19202826170,
		"name": "Maso Tòfoi",
		"coords": [11.225323677062988, 46.005159624380894]
	}, {"id": 8637386500, "name": "Vigolo Vattaro", "coords": [11.198372840881348, 46.00483172745621]}, {
		"id": 12107831500,
		"name": "Santa Caterina",
		"coords": [11.226224899291992, 46.017886484803284]
	}, {"id": 39425181550, "name": "Maso Begher", "coords": [11.222319602966309, 46.021164592721846]}, {
		"id": 13483951340,
		"name": "Valcanover",
		"coords": [11.228241920471191, 46.02995492264492]
	}, {"id": 53944096030, "name": "Maso Toldi", "coords": [11.215581893920898, 46.031236108251875]}, {
		"id": 13483923900,
		"name": "San Vito",
		"coords": [11.220388412475586, 46.0344836317355]
	}, {"id": 5242027040, "name": "Maso Ungherle", "coords": [11.216440200805664, 46.03692659701798]}, {
		"id": 2709128910,
		"name": "San Cristoforo al Lago",
		"coords": [11.235108375549316, 46.0390119697384]
	}, {"id": 2938846410, "name": "Susà", "coords": [11.22326374053955, 46.05104601158624]}, {
		"id": 4169339630,
		"name": "Pozza",
		"coords": [11.246480941772461, 46.043450572477354]
	}, {"id": 4169174510, "name": "Masetti", "coords": [11.248798370361328, 46.05104601158624]}, {
		"id": 4828919940,
		"name": "Valàr",
		"coords": [11.255021095275879, 46.05414345946869]
	}, {"id": 647766590, "name": "Pergine Valsugana", "coords": [11.24051570892334, 46.060337834079775]}, {
		"id": 3665781020,
		"name": "Fornaci",
		"coords": [11.223306655883789, 46.06411963461028]
	}, {"id": 3666477510, "name": "Roncogno", "coords": [11.207385063171387, 46.069211256027586]}, {
		"id": 3179455980,
		"name": "Vigalzano",
		"coords": [11.22875690460205, 46.076773382357516]
	}, {"id": 3179447580, "name": "Pissol", "coords": [11.23403549194336, 46.08097080838283]}, {
		"id": 4850042360,
		"name": "Brazzaniga",
		"coords": [11.23952865600586, 46.07352834482947]
	}, {"id": 4170659690, "name": "Serso", "coords": [11.252274513244629, 46.07465966398232]}, {
		"id": 2773904180,
		"name": "Madrano",
		"coords": [11.213994026184082, 46.08537627378732]
	}, {"id": 9228643970, "name": "Slacche", "coords": [11.194725036621094, 46.08025637543838]}, {
		"id": 9268548670,
		"name": "Celva",
		"coords": [11.175456047058105, 46.0716526856628]
	}, {"id": 2742328260, "name": "Oltrecastello", "coords": [11.162452697753906, 46.0727542709939]}, {
		"id": 4749868740,
		"name": "Viarago",
		"coords": [11.26399040222168, 46.080375448238215]
	}, {"id": 4172842870, "name": "Canezza", "coords": [11.278023719787598, 46.07879771278556]}, {
		"id": 4828729190,
		"name": "Falesina",
		"coords": [11.277551651000977, 46.07072971886271]
	}, {"id": 36750715490, "name": "Bebber", "coords": [11.273002624511719, 46.05256497408152]}, {
		"id": 3169196230,
		"name": "Vetriolo Terme",
		"coords": [11.307892799377441, 46.04178241461432]
	}, {"id": 647765510, "name": "Vignola", "coords": [11.277680397033691, 46.04446335803448]}, {
		"id": 4711157670,
		"name": "Tingherla",
		"coords": [11.315488815307617, 46.06784162626599]
	}, {"id": 42433557630, "name": "Kamaovrunt", "coords": [11.302571296691895, 46.074391722068384]}, {
		"id": 17483952360,
		"name": "Maso Molinari",
		"coords": [11.291499137878418, 46.084870258482425]
	}, {"id": 16580869180, "name": "Gabani", "coords": [11.282744407653809, 46.088144393011305]}, {
		"id": 4755333140,
		"name": "Mala",
		"coords": [11.28046989440918, 46.09135880875721]
	}, {"id": 16145238320, "name": "Groven", "coords": [11.293859481811523, 46.08927541250455]}, {
		"id": 4702853730,
		"name": "Drazeri",
		"coords": [11.294159889221191, 46.09344212631373]
	}, {"id": 16145423840, "name": "Ecker", "coords": [11.301755905151367, 46.08796580886607]}, {
		"id": 16738099140,
		"name": "Corni – Kourn",
		"coords": [11.312742233276367, 46.097459730794384]
	}, {"id": 5008400920, "name": "Costarefolo", "coords": [11.273946762084961, 46.10222095708167]}, {
		"id": 647765410,
		"name": "Sant'Orsola Terme",
		"coords": [11.30270004272461, 46.10814215859827]
	}, {"id": 16629682810, "name": "Broli", "coords": [11.29321575164795, 46.111504165799374]}, {
		"id": 11831702420,
		"name": "Pochi",
		"coords": [11.308150291442871, 46.112128941001345]
	}, {
		"id": 34204130610,
		"name": "San Felice – Innervlarötz",
		"coords": [11.31892204284668, 46.110611617510614]
	}, {"id": 16821533810, "name": "Mutznasl", "coords": [11.332955360412598, 46.11665091225933]}, {
		"id": 16821535020,
		"name": "Kerschpam",
		"coords": [11.332354545593262, 46.12135098907609]
	}, {"id": 4842795750, "name": "Fontanari", "coords": [11.317291259765625, 46.12129149693817]}, {
		"id": 9569265090,
		"name": "Borti",
		"coords": [11.308450698852539, 46.12075606480613]
	}, {
		"id": 16837800860,
		"name": "Hacleri – Hacler",
		"coords": [11.340594291687012, 46.12521784024801]
	}, {
		"id": 34203276700,
		"name": "Palù del Fersina – Palai en Bersntol",
		"coords": [11.352224349975586, 46.12979822044284]
	}, {"id": 4726563720, "name": "Tasaineri – Tasainer", "coords": [11.365184783935547, 46.132296449108054]},
		{"id": 12934931630, "name": "Strombiano", "coords": [10.7216477394104, 46.32418643543724]}, {
		"id": 55801249210,
		"name": "Ognano",
		"coords": [10.758941173553467, 46.30822492956571]
	}, {"id": 13936759560, "name": "Menas", "coords": [10.78956127166748, 46.327609327536294]}, {
		"id": 11005984440,
		"name": "Roncio",
		"coords": [10.808830261230469, 46.32132645227145]
	}, {
		"id": 13042048890,
		"name": "Mastellina",
		"coords": [10.847046375274658, 46.323356611158914]
	}, {"id": 14316339280, "name": "Palù", "coords": [10.827584266662598, 46.22422074175884]}, {
		"id": 4429802580,
		"name": "Zambana Vecchia",
		"coords": [11.072523593902588, 46.158030527871176]
	}, {"id": 11226934420, "name": "Maso Daldoss", "coords": [11.0202956199646, 46.18259378335577]}, {
		"id": 647767830,
		"name": "Cavedago",
		"coords": [11.031475067138672, 46.185208462638684]
	}, {"id": 13626470260, "name": "Maset-Sass", "coords": [11.021883487701416, 46.1896947248255]}, {
		"id": 11264182030,
		"name": "Belfort",
		"coords": [11.041667461395264, 46.20414631779431]
	}, {"id": 13818934380, "name": "Fabbrica", "coords": [11.04299783706665, 46.21277387396975]}, {
		"id": 647769030,
		"name": "Spormaggiore",
		"coords": [11.048276424407959, 46.21774783861858]
	}, {"id": 8702737850, "name": "Maurina", "coords": [11.036818027496338, 46.22074684733454]}, {
		"id": 11264182280,
		"name": "Pesort",
		"coords": [11.053318977355957, 46.2278725476076]
	}, {"id": 8544660950, "name": "Maso Milano", "coords": [11.052117347717285, 46.23662995373627]}, {
		"id": 8491353500,
		"name": "Castelletto",
		"coords": [11.062395572662354, 46.239568566697756]
	}, {"id": 8539833250, "name": "Crescino", "coords": [11.058168411254883, 46.24320451135662]}, {
		"id": 8488130940,
		"name": "Masi di Vigo",
		"coords": [11.067953109741211, 46.24658794952197]
	}, {"id": 647766630, "name": "Sporminore", "coords": [11.0302734375, 46.23680805597144]}, {
		"id": 8695580240,
		"name": "Dercolo",
		"coords": [11.047375202178955, 46.24857636406517]
	}, {"id": 8699846090, "name": "Segonzone", "coords": [11.032848358154297, 46.24870991171255]}, {
		"id": 8695580530,
		"name": "Lover",
		"coords": [11.024200916290283, 46.24802733143213]
	}, {"id": 8488130900, "name": "Raut", "coords": [11.069090366363525, 46.25452633818702]}, {
		"id": 8702735220,
		"name": "Sant'Angelo",
		"coords": [11.057932376861572, 46.256974394460855]
	}, {"id": 8695577150, "name": "Quetta", "coords": [11.048619747161865, 46.25998609557615]}, {
		"id": 8491358260,
		"name": "Moncovo",
		"coords": [11.06396198272705, 46.26172182846338]
	}, {"id": 8488130870, "name": "Bastianelli", "coords": [11.083531379699707, 46.25851735559854]}, {
		"id": 647765440,
		"name": "Vigo di Ton",
		"coords": [11.08797311782837, 46.26531180245891]
	}, {"id": 8521197070, "name": "Sabino", "coords": [11.065120697021484, 46.269672859546034]}, {
		"id": 647768120,
		"name": "Denno",
		"coords": [11.049070358276367, 46.275012456911526]
	}, {"id": 8695579190, "name": "Termon", "coords": [11.035423278808594, 46.26991018603124]}, {
		"id": 8488130910,
		"name": "Toss",
		"coords": [11.079046726226807, 46.276525248252625]
	}, {"id": 8398347730, "name": "Nosino", "coords": [11.091363430023193, 46.2755760507236]}, {
		"id": 20000253830,
		"name": "Dardine",
		"coords": [11.08245849609375, 46.28646117287781]
	}, {"id": 20000254010, "name": "Priò", "coords": [11.092586517333984, 46.29000504429732]}, {
		"id": 20000254070,
		"name": "Tuenetto",
		"coords": [11.08020544052124, 46.296795588180856]
	}, {"id": 20000253970, "name": "Mollaro", "coords": [11.071386337280273, 46.29469031310313]}, {
		"id": 647767310,
		"name": "Cunevo",
		"coords": [11.034564971923828, 46.28640185927145]
	}, {"id": 647768580, "name": "Flavon", "coords": [11.030488014221191, 46.29944930553168]}, {
		"id": 11134929430,
		"name": "Portolo",
		"coords": [11.059949398040771, 46.30296268873644]
	}, {"id": 647768750, "name": "Terres", "coords": [11.022377014160156, 46.30930696145447]}, {
		"id": 647766410,
		"name": "Nanno",
		"coords": [11.04801893234253, 46.31447966765043]
	}, {"id": 647767070, "name": "Vervò", "coords": [11.119601726531982, 46.30838797683546]}, {
		"id": 647768880,
		"name": "Tres",
		"coords": [11.096878051757812, 46.323104697726734]
	}, {"id": 647766920, "name": "Taio", "coords": [11.066815853118896, 46.320585499606665]}, {
		"id": 12950511230,
		"name": "Campo",
		"coords": [11.052160263061523, 46.33153575815467]
	}, {"id": 12950513140, "name": "Pavillo", "coords": [11.041066646575928, 46.331135719629174]}, {
		"id": 647766850,
		"name": "Tuenno",
		"coords": [11.022977828979492, 46.328913230094685]
	}, {"id": 647769170, "name": "Tassullo", "coords": [11.050851345062256, 46.33593598882106]}, {
		"id": 12950513820,
		"name": "Rallo",
		"coords": [11.045401096343994, 46.34192091943339]
	}, {"id": 12931358750, "name": "Dermulo", "coords": [11.062524318695068, 46.33817300737485]}, {
		"id": 647766720,
		"name": "Sfruz",
		"coords": [11.115760803222656, 46.33715080495324]
	}, {"id": 647766790, "name": "Smarano", "coords": [11.109774112701416, 46.34310597650429]}, {
		"id": 647766000,
		"name": "Capriana",
		"coords": [11.338577270507812, 46.26191468428115]
	}, {"id": 2500587230, "name": "Carbonare", "coords": [11.347761154174805, 46.26876804289421]}, {
		"id": 9376491580,
		"name": "Pradel",
		"coords": [11.355485916137695, 46.2611135864407]
	}, {"id": 2600071680, "name": "Palù", "coords": [11.362996101379395, 46.256558974428714]}, {
		"id": 11877571060,
		"name": "Casatta",
		"coords": [11.343169212341309, 46.2498524727379]
	}, {
		"id": 25999458850,
		"name": "Simoni-Fraine",
		"coords": [11.331818103790283, 46.24887313617333]
	}, {"id": 647765500, "name": "Valfloriana", "coords": [11.348683834075928, 46.246884732388224]}, {
		"id": 2600028590,
		"name": "Casanova",
		"coords": [11.348791122436523, 46.241067495524305]
	}, {"id": 9569813260, "name": "Valle", "coords": [11.350507736206055, 46.238960078953966]}, {
		"id": 2600028610,
		"name": "Montalbiano",
		"coords": [11.35563611984253, 46.234403627023056]
	}, {"id": 2600028640, "name": "Sicina", "coords": [11.3588547706604, 46.230678038301335]}, {
		"id": 8245554550,
		"name": "Monte Alto",
		"coords": [11.332054138183594, 46.2343442570743]
	}, {"id": 2693587680, "name": "Piscine", "coords": [11.325101852416992, 46.2393459500133]}, {
		"id": 2693587670,
		"name": "Mezauno",
		"coords": [11.319544315338135, 46.231049124215644]
	}, {"id": 647767500, "name": "Grauno", "coords": [11.298086643218994, 46.23051475970422]}, {
		"id": 647767490,
		"name": "Grumes",
		"coords": [11.294074058532715, 46.22083592429638]
	}, {"id": 9116335500, "name": "Greggion", "coords": [11.28044843673706, 46.21739150986431]}, {
		"id": 5088209430,
		"name": "Noldi",
		"coords": [11.270556449890137, 46.22227598179228]
	}, {"id": 647769570, "name": "Valda", "coords": [11.266565322875977, 46.20716091915523]}, {
		"id": 9569560820,
		"name": "Ponciach",
		"coords": [11.230688095092773, 46.2080222034443]
	}, {"id": 9116297330, "name": "Gaggio", "coords": [11.289010047912598, 46.2062550711083]}, {
		"id": 4772361700,
		"name": "Valcava",
		"coords": [11.31141185760498, 46.206923321112725]
	}, {"id": 9569560760, "name": "Sveseri", "coords": [11.323707103729248, 46.2073688165994]}, {
		"id": 9569561120,
		"name": "Slosseri",
		"coords": [11.324758529663086, 46.20549771127591]
	}, {"id": 25923742260, "name": "Casal", "coords": [11.260385513305664, 46.19544314147379]}, {
		"id": 9820650920,
		"name": "Sabion",
		"coords": [11.261372566223145, 46.192249650898106]
	}, {"id": 5529285010, "name": "Pra", "coords": [11.25124454498291, 46.19201198836663]}, {
		"id": 647766740,
		"name": "Scancio",
		"coords": [11.259698867797852, 46.190155214461214]
	}, {"id": 9814129040, "name": "Luch", "coords": [11.26399040222168, 46.186070091068444]}, {
		"id": 5529278820,
		"name": "Piazzo",
		"coords": [11.251523494720459, 46.18351487774527]
	}, {"id": 647768630, "name": "Faver", "coords": [11.23727560043335, 46.18130125391755]}, {
		"id": 14268669000,
		"name": "Fadana",
		"coords": [11.225581169128418, 46.17844866763005]
	}, {"id": 647768280, "name": "Cembra", "coords": [11.220581531524658, 46.174407250383666]}, {
		"id": 5529135120,
		"name": "Sevignano",
		"coords": [11.24800443649292, 46.17170290092392]
	}, {"id": 5529139890, "name": "Piazzole", "coords": [11.242403984069824, 46.166709906394885]}, {
		"id": 13766685660,
		"name": "Casara",
		"coords": [11.24201774597168, 46.163425576585695]
	}, {"id": 5529146060, "name": "Lona", "coords": [11.234164237976074, 46.16024508845777]}, {
		"id": 647766150,
		"name": "Lisignago",
		"coords": [11.187043190002441, 46.16057206300607]
	}, {"id": 3274934880, "name": "Ceola", "coords": [11.171786785125732, 46.160051875311325]}, {
		"id": 9569265420,
		"name": "Barco di Sotto",
		"coords": [11.173739433288574, 46.15193631048214]
	}, {"id": 647765550, "name": "Albiano", "coords": [11.193480491638184, 46.1449048261633]}, {
		"id": 647765180,
		"name": "Lases",
		"coords": [11.219573020935059, 46.144206088385715]
	}, {
		"id": 9569265520,
		"name": "Villaggio Pian del Gac'",
		"coords": [11.20532512664795, 46.133768566508735]
	}, {
		"id": 9569265410,
		"name": "Santo Stefano",
		"coords": [11.214487552642822, 46.129872574122516]
	}, {
		"id": 2688023530,
		"name": "Baselga di Piné",
		"coords": [11.245923042297363, 46.133114296966454]
	}, {"id": 2691272860, "name": "Ricaldo", "coords": [11.248540878295898, 46.13672755213776]}, {
		"id": 2691272870,
		"name": "Sternigo",
		"coords": [11.2534761428833, 46.14040004180109]
	}, {"id": 2691396630, "name": "Rizzolaga", "coords": [11.263582706451416, 46.14755102926475]}, {
		"id": 2873346360,
		"name": "Valeti",
		"coords": [11.27570629119873, 46.154909183762356]
	}, {"id": 2873346370, "name": "Cialini", "coords": [11.282272338867188, 46.15863991249168]}, {
		"id": 2873393520,
		"name": "Piazze",
		"coords": [11.289567947387695, 46.157807580592134]
	}, {"id": 50934808590, "name": "Fabbrica", "coords": [11.288344860076904, 46.15541455624927]}, {
		"id": 33405824850,
		"name": "Casei",
		"coords": [11.295018196105957, 46.16015591324373]
	}, {"id": 12704938490, "name": "Marteri", "coords": [11.296992301940918, 46.16268248834325]}, {
		"id": 647765630,
		"name": "Bedollo",
		"coords": [11.299889087677002, 46.16550617026891]
	}, {
		"id": 50934962330,
		"name": "Svaldi-Pec'",
		"coords": [11.301906108856201, 46.16929562085883]
	}, {"id": 39645432080, "name": "Quaras", "coords": [11.287293434143066, 46.17422894572303]}, {
		"id": 9569265190,
		"name": "Martinei",
		"coords": [11.31115436553955, 46.15866963839812]
	}, {"id": 4773217770, "name": "Regnana", "coords": [11.310381889343262, 46.15308088570697]}, {
		"id": 9569265510,
		"name": "Pitoi",
		"coords": [11.31242036819458, 46.14845784488193]
	}, {"id": 9378020750, "name": "Brusago", "coords": [11.325831413269043, 46.18262349631888]}, {
		"id": 5339037770,
		"name": "Montepeloso",
		"coords": [11.330122947692871, 46.185906679817805]
	}, {"id": 4772361690, "name": "Caloneghi", "coords": [11.31890058517456, 46.19724032665553]}, {
		"id": 9569560970,
		"name": "Settefontane",
		"coords": [11.326539516448975, 46.19998797549269]
	}, {"id": 9569561100, "name": "Facendi", "coords": [11.320445537567139, 46.21623342544362]}, {
		"id": 647766660,
		"name": "Sover",
		"coords": [11.31568193435669, 46.22171184005691]
	}, {
		"id": 5461530670,
		"name": "Valternigo di Giovo",
		"coords": [11.160199642181396, 46.160259950979395]
	}, {"id": 647765120, "name": "Verla", "coords": [11.152560710906982, 46.15633610589512]}, {
		"id": 5461574590,
		"name": "Palù",
		"coords": [11.141295433044434, 46.15814943271732]
	}, {"id": 9901651900, "name": "Pomaroli", "coords": [11.13213300704956, 46.15841696768169]}, {
		"id": 3274959560,
		"name": "Mosana",
		"coords": [11.13715410232544, 46.1543740784235]
	}, {"id": 2693885940, "name": "San Mauro", "coords": [11.220989227294922, 46.12401319648916]}, {
		"id": 2691974490,
		"name": "Ferrari",
		"coords": [11.234872341156006, 46.122927507323794]
	}, {"id": 2695553370, "name": "Vigo", "coords": [11.240944862365723, 46.12596143435766]}, {
		"id": 2691272440,
		"name": "Tressilla",
		"coords": [11.23427152633667, 46.13011050522297]
	}, {"id": 2691919490, "name": "Fovi", "coords": [11.25748872756958, 46.126779376285185]}, {
		"id": 2696086000,
		"name": "Fioré",
		"coords": [11.265277862548828, 46.12007189395416]
	}, {"id": 2696085990, "name": "Cané", "coords": [11.26171588897705, 46.118004456496436]}, {
		"id": 2769291820,
		"name": "Prada",
		"coords": [11.248819828033447, 46.11406272411986]
	}, {"id": 2691958940, "name": "Faida", "coords": [11.252295970916748, 46.10971905476791]}, {
		"id": 2900227000,
		"name": "Grill",
		"coords": [11.239721775054932, 46.10835043048937]
	}, {"id": 4931512650, "name": "Valt", "coords": [11.236417293548584, 46.10616353638409]}, {
		"id": 4789068830,
		"name": "Erla",
		"coords": [11.23753309249878, 46.1018787576557]
	}, {"id": 2691974440, "name": "Montagnaga", "coords": [11.235795021057129, 46.09728117681058]}, {
		"id": 2693499720,
		"name": "Torchio",
		"coords": [11.197750568389893, 46.09150761976392]
	}, {"id": 4203194970, "name": "Seregnano", "coords": [11.200346946716309, 46.09497480255476]}, {
		"id": 2785363070,
		"name": "Nogaré",
		"coords": [11.211998462677002, 46.10327729843317]
	}, {
		"id": 47916382580,
		"name": "Molino Roccabruna",
		"coords": [11.208608150482178, 46.10885623037717]
	}, {"id": 24446648490, "name": "Valle", "coords": [11.210453510284424, 46.111667798084454]}, {
		"id": 647768690,
		"name": "Fornace",
		"coords": [11.206505298614502, 46.11819781724478]
	}, {"id": 11413787580, "name": "Mazzanigo", "coords": [11.188931465148926, 46.10458653849784]}, {
		"id": 11413787550,
		"name": "Barbaniga",
		"coords": [11.185734272003174, 46.1018043661951]
	}, {"id": 6631859550, "name": "Garzano", "coords": [11.183245182037354, 46.09696870594772]}, {
		"id": 10265380080,
		"name": "Orzano",
		"coords": [11.17884635925293, 46.094796240524744]
	}, {"id": 647768360, "name": "Civezzano", "coords": [11.180026531219482, 46.08936470252948]}, {
		"id": 2920331590,
		"name": "Villamontagna",
		"coords": [11.158955097198486, 46.0857781061641]
	}, {"id": 3015650070, "name": "Magnago", "coords": [11.168568134307861, 46.09717702005304]}, {
		"id": 3051054170,
		"name": "Campagnaga",
		"coords": [11.168053150177002, 46.10311364123885]
	}, {
		"id": 3013837490,
		"name": "Bosco di Civezzano",
		"coords": [11.171228885650635, 46.111400035910464]
	}, {"id": 2599735630, "name": "Montevaccino", "coords": [11.145586967468262, 46.10838018355241]}, {
		"id": 3047300790,
		"name": "Valcalda",
		"coords": [11.144514083862305, 46.1163088024083]
	}, {"id": 3047300820, "name": "Cirocol", "coords": [11.138184070587158, 46.119149737189815]}, {
		"id": 11768010000,
		"name": "Gardolo di Mezzo",
		"coords": [11.117112636566162, 46.114851093151316]
	}, {
		"id": 3047300840,
		"name": "Masi Saracini",
		"coords": [11.128077507019043, 46.11532708408731]
	}, {
		"id": 2600219830,
		"name": "Spini di Gardolo",
		"coords": [11.0996675491333, 46.1175284886867]
	}, {
		"id": 13885634490,
		"name": "Ghiaie di Gardolo",
		"coords": [11.103379726409912, 46.1236860047465]
	}, {"id": 2785465420, "name": "Meano", "coords": [11.116511821746826, 46.12451885263292]}, {
		"id": 647769410,
		"name": "Lavis",
		"coords": [11.110224723815918, 46.139745851046456]
	}, {"id": 4419295120, "name": "Pressano", "coords": [11.106748580932617, 46.152857918374195]},
		{"id": 5550139280, "name": "Isoletta", "coords": [10.935688018798828, 46.009437010752094]}, {
		"id": 37825293150,
		"name": "Gaggiolo",
		"coords": [10.926246643066406, 45.993190127461304]
	}, {"id": 3043509170, "name": "Trebi", "coords": [10.948669910430908, 45.99137137208419]}, {
		"id": 647768240,
		"name": "Cavedine",
		"coords": [10.97358226776123, 45.99520261498009]
	}, {"id": 6768687070, "name": "Stravino", "coords": [10.984482765197754, 46.00356483471376]}, {
		"id": 647767420,
		"name": "Lasino",
		"coords": [10.98306655883789, 46.02509806568369]
	}, {"id": 21945232930, "name": "Pergolese", "coords": [10.959227085113525, 46.02645385703437]}, {
		"id": 3235864760,
		"name": "Maso del Gobbo",
		"coords": [10.947575569152832, 46.033336592486194]
	}, {
		"id": 13578925120,
		"name": "Castel Madruzzo",
		"coords": [10.986993312835693, 46.035958361502395]
	}, {"id": 13579029110, "name": "Lagolo", "coords": [11.004459857940674, 46.03801397975613]}, {
		"id": 647765810,
		"name": "Calavino",
		"coords": [10.98508358001709, 46.046697378171245]
	}, {"id": 17614783830, "name": "Ranzo", "coords": [10.942275524139404, 46.06341987215458]}, {
		"id": 3059322240,
		"name": "Margone",
		"coords": [10.962274074554443, 46.06807982518754]
	}, {"id": 2931210970, "name": "Santa Massenza", "coords": [10.982422828674316, 46.0693899008823]}, {
		"id": 647766300,
		"name": "Padergnone",
		"coords": [10.988881587982178, 46.06209476575728]
	}, {"id": 2777655360, "name": "Vason", "coords": [11.05748176574707, 46.03945882508211]}, {
		"id": 2777654680,
		"name": "Norge",
		"coords": [11.067073345184326, 46.04949722250055]
	}, {"id": 2777653290, "name": "Vaneze", "coords": [11.071794033050537, 46.05333933104103]}, {
		"id": 2777652010,
		"name": "Candriai",
		"coords": [11.082565784454346, 46.06358364714137]
	}, {"id": 2777650280, "name": "Sopramonte", "coords": [11.060013771057129, 46.07278404326527]}, {
		"id": 4324590540,
		"name": "Baselga del Bondone",
		"coords": [11.046066284179688, 46.077651593648994]
	}, {
		"id": 3058806480,
		"name": "Vigolo Baselga",
		"coords": [11.045079231262207, 46.08183406918144]
	}, {
		"id": 23628508800,
		"name": "Garniga Vecchia Superiore",
		"coords": [11.083981990814209, 46.01390779276434]
	}, {
		"id": 11811566550,
		"name": "Garniga Terme",
		"coords": [11.087050437927246, 46.002655634982034]
	}, {"id": 19192101340, "name": "Valle", "coords": [11.106984615325928, 46.01204501084564]}, {
		"id": 19192101280,
		"name": "Romagnano",
		"coords": [11.106126308441162, 46.01831861014978]
	}, {"id": 12047858980, "name": "Mattarello", "coords": [11.128485202789307, 46.00793176615824]}, {
		"id": 38303444790,
		"name": "Il Castellér",
		"coords": [11.136295795440674, 46.02746695413836]
	}, {"id": 4435244980, "name": "Valsorda", "coords": [11.154835224151611, 46.008065898527065]}, {
		"id": 15338841300,
		"name": "Stella",
		"coords": [11.131339073181152, 46.03668826369588]
	}, {"id": 15338841280, "name": "San Rocco", "coords": [11.146767139434814, 46.03637544965062]}, {
		"id": 15338841220,
		"name": "Madonna Bianca",
		"coords": [11.133871078491211, 46.04085894842413]
	}, {
		"id": 15338841230,
		"name": "Man Malpensada",
		"coords": [11.1326265335083, 46.043584618690716]
	}, {"id": 13652242940, "name": "Belvedere", "coords": [11.105375289916992, 46.04586335455525]}, {
		"id": 17845284000,
		"name": "San Pio X",
		"coords": [11.120052337646484, 46.05588569756148]
	}, {
		"id": 38197951400,
		"name": "San Giuseppe",
		"coords": [11.123056411743164, 46.05847661665976]
	}, {"id": 23783371210, "name": "Le Albere", "coords": [11.11466646194458, 46.0605760653803]}, {
		"id": 15338841270,
		"name": "San Bartolameo",
		"coords": [11.132283210754395, 46.05070349466334]
	}, {"id": 2892417920, "name": "Villazzano", "coords": [11.146101951599121, 46.04891641539666]}, {
		"id": 38198152080,
		"name": "Negrano",
		"coords": [11.147346496582031, 46.050688602575036]
	}, {"id": 4307656310, "name": "Gabbiolo", "coords": [11.153526306152344, 46.05640686921629]}, {
		"id": 38204410460,
		"name": "Graffiano",
		"coords": [11.149513721466064, 46.061186528396064]
	}, {"id": 41880300110, "name": "Mesiano", "coords": [11.140737533569336, 46.06620398085485]}, {
		"id": 2488887560,
		"name": "Povo",
		"coords": [11.154963970184326, 46.06550424481949]
	}, {"id": 38204410520, "name": "Spré", "coords": [11.160950660705566, 46.06830313574292]}, {
		"id": 9268550540,
		"name": "Borino",
		"coords": [11.166143417358398, 46.06727589970771]
	}, {"id": 2488886060, "name": "San Donà", "coords": [11.139857769012451, 46.0719504135962]}, {
		"id": 38198203790,
		"name": "San Vito",
		"coords": [11.139106750488281, 46.07468943522579]
	}, {"id": 2488886610, "name": "Cognola", "coords": [11.141724586486816, 46.07662453160083]}, {
		"id": 11073708980,
		"name": "San Martino",
		"coords": [11.125438213348389, 46.07357300260412]
	}, {"id": 8927427800, "name": "Cristo Re", "coords": [11.11764907836914, 46.07790463498665]}, {
		"id": 15338841260,
		"name": "Piedicastello",
		"coords": [11.113014221191406, 46.07101256516748]
	}, {"id": 3599209850, "name": "Vela", "coords": [11.102113723754883, 46.08214662579519]}, {
		"id": 15338841180,
		"name": "Campotrentino",
		"coords": [11.111490726470947, 46.08537627378732]
	}, {"id": 11797794380, "name": "Zell", "coords": [11.140780448913574, 46.080226607198284]}, {
		"id": 38204410480,
		"name": "Moià",
		"coords": [11.149084568023682, 46.082295461655605]
	}, {"id": 2920329680, "name": "Tavernaro", "coords": [11.156830787658691, 46.08193825491611]}, {
		"id": 45060349760,
		"name": "Eremo",
		"coords": [11.17785930633545, 46.06867531799432]
	}, {
		"id": 19206396610,
		"name": "Maso Pegoretti",
		"coords": [11.18131399154663, 46.008781265667494]
	}, {
		"id": 19235572900,
		"name": "Maso Zugolini",
		"coords": [11.168224811553955, 46.00219357693314]
	}, {"id": 647767150, "name": "Vattaro", "coords": [11.219315528869629, 45.99336901820425]}, {
		"id": 647765760,
		"name": "Caldonazzo",
		"coords": [11.262123584747314, 45.991520452642504]
	}, {"id": 42904879370, "name": "Maso Uezi", "coords": [11.23403549194336, 45.97343405102055]}, {
		"id": 42904879340,
		"name": "Maso Martinelli",
		"coords": [11.233241558074951, 45.970988280309655]
	}, {"id": 19185907330, "name": "Maso Doss", "coords": [11.2315034866333, 45.96192009035295]}, {
		"id": 42904879360,
		"name": "Maso Sadleri",
		"coords": [11.218435764312744, 45.95298468178825]
	}, {"id": 2878848740, "name": "Stengheli", "coords": [11.25922679901123, 45.949777130317216]}, {
		"id": 2878848750,
		"name": "Lanzino",
		"coords": [11.252875328063965, 45.9474049147108]
	}, {
		"id": 15371915870,
		"name": "Località Tobia",
		"coords": [11.263625621795654, 45.94034734603284]
	}, {"id": 3678915450, "name": "Rocchetti", "coords": [11.266865730285645, 45.93672122672248]}, {
		"id": 3678915520,
		"name": "Cappella",
		"coords": [11.275427341461182, 45.93736290296334]
	}, {"id": 26095880700, "name": "Magrè", "coords": [11.281177997589111, 45.94231699046313]}, {
		"id": 3678916510,
		"name": "Masi di Sotto",
		"coords": [11.274504661560059, 45.92837875988198]
	}, {"id": 2878715330, "name": "Prà di Sopra", "coords": [11.248412132263184, 45.93372166488666]}, {
		"id": 4423979820,
		"name": "Carbonare",
		"coords": [11.226589679718018, 45.93466184351615]
	}, {"id": 5955901060, "name": "Perpruneri", "coords": [11.210968494415283, 45.91763169411064]}, {
		"id": 19929605440,
		"name": "Tezzeli",
		"coords": [11.214337348937988, 45.914168307205756]
	}, {"id": 10278173580, "name": "Colpi", "coords": [11.187987327575684, 45.91292919818491]}, {
		"id": 8944017990,
		"name": "Francolini",
		"coords": [11.186120510101318, 45.906464481999876]
	}, {
		"id": 647768730,
		"name": "Folgaria",
		"coords": [11.173009872436523, 45.914974459665444]
	}, {
		"id": 26597857840,
		"name": "Costa",
		"coords": [11.149899959564209, 45.88112091116534]
	}, {"id": 11601485010, "name": "Rovri", "coords": [11.139707565307617, 45.88034414298477]}, {
		"id": 8943883780,
		"name": "Pedrazzi",
		"coords": [11.14288330078125, 45.87801377328532]
	}, {"id": 3175268250, "name": "Quaere", "coords": [11.302120685577393, 45.990775045835164]}, {
		"id": 3398093950,
		"name": "Santa Giuliana",
		"coords": [11.315209865570068, 45.99172916474956]
	}, {"id": 3176424940, "name": "Barco", "coords": [11.335594654083252, 46.00441440128665]}, {
		"id": 647769390,
		"name": "Levico Terme",
		"coords": [11.298837661743164, 46.00997352367534]
	}, {"id": 45836849510, "name": "Brenta", "coords": [11.272530555725098, 46.00566637035078]}, {
		"id": 647766900,
		"name": "Tenna",
		"coords": [11.264290809631348, 46.015696004378356]
	}, {
		"id": 3175233350,
		"name": "Selva di Levico",
		"coords": [11.319007873535156, 46.01480190579923]
	}, {"id": 13590001790, "name": "Campiello", "coords": [11.352589130401611, 46.02204368861425]}, {
		"id": 647769840,
		"name": "Novaledo",
		"coords": [11.366429328918457, 46.02281847340538]
	}, {"id": 13590003070, "name": "Margoni", "coords": [11.381707191467285, 46.030431646340105]}, {
		"id": 13590002780,
		"name": "Marter",
		"coords": [11.391212940216064, 46.033902666779454]
	}, {"id": 2647992760, "name": "Ischia", "coords": [11.247210502624512, 46.032785409313504]}, {
		"id": 39779145910,
		"name": "Maso Posser",
		"coords": [11.21927261352539, 46.02634956657255]
	}, {"id": 31321608430, "name": "Canale", "coords": [11.229722499847412, 46.041707942106655]}, {
		"id": 5242436210,
		"name": "Sercèr",
		"coords": [11.221354007720947, 46.0390119697384]
	}, {"id": 4178876980, "name": "Zava", "coords": [11.251137256622314, 46.04243776835321]}, {
		"id": 7372069030,
		"name": "Ochner",
		"coords": [11.27246618270874, 46.045491018518305]
	}, {"id": 7372109720, "name": "Fruet", "coords": [11.274054050445557, 46.046786737097534]}, {
		"id": 45943677540,
		"name": "Stal",
		"coords": [11.281778812408447, 46.05335422241461]
	}, {"id": 4177091180, "name": "Costasavina", "coords": [11.214616298675537, 46.05875952575096]}, {
		"id": 39271594590,
		"name": "Spiez",
		"coords": [11.294031143188477, 46.05802991514338]
	}, {
		"id": 39271594570,
		"name": "La Gadainera",
		"coords": [11.28695011138916, 46.05798524479303]
	}, {"id": 39271594580, "name": "La Volpis", "coords": [11.29370927810669, 46.055602773746216]}, {
		"id": 3569136860,
		"name": "Compet",
		"coords": [11.300017833709717, 46.04029294538799]
	}, {
		"id": 11532362520,
		"name": "Roncegno Terme",
		"coords": [11.4103102684021, 46.05162679629669]
	}, {"id": 13590002950, "name": "Cofleri", "coords": [11.400611400604248, 46.053905200414476]}, {
		"id": 13590001890,
		"name": "Molini",
		"coords": [11.395890712738037, 46.05482844852369]
	}, {"id": 13590002930, "name": "Zonti", "coords": [11.40230655670166, 46.05539430263897]}, {
		"id": 13590003120,
		"name": "Postai",
		"coords": [11.405482292175293, 46.05707693983058]
	}, {"id": 48258373950, "name": "Pìnzeri", "coords": [11.415503025054932, 46.057970354668186]}, {
		"id": 13590002260,
		"name": "Scali",
		"coords": [11.415653228759766, 46.059310449821425]
	}, {"id": 13590001730, "name": "Roveri", "coords": [11.41803503036499, 46.062481878779835]}, {
		"id": 48258373880,
		"name": "Rozza",
		"coords": [11.402370929718018, 46.062347878425356]
	}, {"id": 13590002450, "name": "Fraineri", "coords": [11.396448612213135, 46.06264565654919]}, {
		"id": 13590002890,
		"name": "Montibelleri",
		"coords": [11.408529281616211, 46.064000526733565]
	}, {"id": 13590002620, "name": "Sasso", "coords": [11.426467895507812, 46.064045192217435]}, {
		"id": 49291026390,
		"name": "Grilli",
		"coords": [11.421060562133789, 46.06705258499656]
	}, {"id": 13590003160, "name": "Bosco", "coords": [11.430802345275879, 46.06595088586596]}, {
		"id": 647767390,
		"name": "Ronchi Valsugana",
		"coords": [11.435179710388184, 46.068407347026266]
	}, {"id": 13590001580, "name": "Facchini", "coords": [11.441917419433594, 46.06802027555352]}, {
		"id": 49266475030,
		"name": "Maso dell'Aria",
		"coords": [11.44775390625, 46.06091852107397]
	}, {
		"id": 49266475040,
		"name": "Località Onea",
		"coords": [11.447925567626953, 46.056957816760104]
	}, {
		"id": 647764920,
		"name": "Borgo Valsugana",
		"coords": [11.456122398376465, 46.05319041708432]
	}, {"id": 46806063220, "name": "Crosetta", "coords": [11.459577083587646, 46.04157389133988]}, {
		"id": 4579433200,
		"name": "Olle",
		"coords": [11.465263366699219, 46.040575947630856]
	}, {"id": 46491419090, "name": "Pràe", "coords": [11.458654403686523, 46.0308487762004]}, {
		"id": 54576170930,
		"name": "Perozzi",
		"coords": [11.481614112854004, 46.04592292808829]
	}, {"id": 49385561200, "name": "Ceggio", "coords": [11.47895336151123, 46.055483647495606]}, {
		"id": 49385583210,
		"name": "Broletti",
		"coords": [11.484017372131348, 46.05600482294483]
	}, {"id": 647767870, "name": "Castelnuovo", "coords": [11.493287086486816, 46.052862804966225]}, {
		"id": 57227752310,
		"name": "Rore",
		"coords": [11.486656665802002, 46.06431318436185]
	}, {"id": 39091655790, "name": "Viadaman", "coords": [11.4872145652771, 46.06700792194593]}, {
		"id": 57227752320,
		"name": "Longhini",
		"coords": [11.485111713409424, 46.06970252798675]
	}, {"id": 647769180, "name": "Telve", "coords": [11.479597091674805, 46.071325183081086]}, {
		"id": 647768790,
		"name": "Telve di Sopra",
		"coords": [11.471679210662842, 46.07135495612337]
	}, {"id": 13590002570, "name": "Parise", "coords": [11.466765403747559, 46.07619286213588]}, {
		"id": 13590002030,
		"name": "Campestrini",
		"coords": [11.456208229064941, 46.078023712861636]
	}, {"id": 647766870, "name": "Torcegno", "coords": [11.449792385101318, 46.07430240780798]}, {
		"id": 49372108080,
		"name": "Visentini",
		"coords": [11.443140506744385, 46.06641241115088]
	}, {"id": 39918851610, "name": "Belvedere", "coords": [11.475756168365479, 46.07631194370484]}, {
		"id": 13590001640,
		"name": "Martinelli",
		"coords": [11.483759880065918, 46.07783021118425]
	}, {"id": 13590001840, "name": "Dami", "coords": [11.489102840423584, 46.079095402173095]}, {
		"id": 13590003260,
		"name": "Masi di Carzano",
		"coords": [11.49245023727417, 46.08123871835201]
	}, {
		"id": 14355650960,
		"name": "Casolera-Porchera",
		"coords": [11.454405784606934, 46.096819909676384]
	}, {"id": 14187553610, "name": "Musiera", "coords": [11.487407684326172, 46.099989183451555]}, {
		"id": 13590003320,
		"name": "Pianezze",
		"coords": [11.504294872283936, 46.078023712861636]
	}, {"id": 12036330420, "name": "Torgheli", "coords": [11.50993824005127, 46.07553790891305]}, {
		"id": 52549042620,
		"name": "Paluati",
		"coords": [11.514487266540527, 46.07922936187347]
	}, {"id": 647768560, "name": "Samone", "coords": [11.522598266601562, 46.08161081337326]}, {
		"id": 647769130,
		"name": "Spera",
		"coords": [11.509315967559814, 46.070164021904674]
	}, {"id": 26402646020, "name": "Palua", "coords": [11.501805782318115, 46.07010447451921]}, {
		"id": 647767810,
		"name": "Carzano",
		"coords": [11.493351459503174, 46.070327776883545]
	}, {"id": 647768500, "name": "Scurelle", "coords": [11.50592565536499, 46.06446206832439]}, {
		"id": 13590002410,
		"name": "Castellare",
		"coords": [11.505625247955322, 46.0675736512506]
	}, {"id": 647766670, "name": "Strigno", "coords": [11.523070335388184, 46.06630819610123]}, {
		"id": 12036318790,
		"name": "Tomaselli",
		"coords": [11.527469158172607, 46.06886885177329]
	}, {"id": 55648859790, "name": "Sièga", "coords": [11.525344848632812, 46.06176720646434]}, {
		"id": 44640798230,
		"name": "Ivano-Fracena",
		"coords": [11.53188943862915, 46.057806563030084]
	}, {"id": 9595152200, "name": "Villa", "coords": [11.521804332733154, 46.057642770906114]}, {
		"id": 647765610,
		"name": "Agnedo",
		"coords": [11.528799533843994, 46.052981936870395]
	}, {"id": 54575741130, "name": "Mesole", "coords": [11.521568298339844, 46.042646288367166]}, {
		"id": 12167900210,
		"name": "Oltrebrenta",
		"coords": [11.529936790466309, 46.04060573725627]
	}, {"id": 54407475940, "name": "Bislonghi", "coords": [11.537468433380127, 46.03826720280142]}, {
		"id": 647766270,
		"name": "Ospedaletto",
		"coords": [11.552510261535645, 46.04359951269433]
	}, {"id": 55169504970, "name": "Lusumina", "coords": [11.550149917602539, 46.083337301435876]}, {
		"id": 30864407960,
		"name": "Casetta",
		"coords": [11.54390573501587, 46.082801500577574]
	}, {"id": 48237512050, "name": "Zunaghe", "coords": [11.542704105377197, 46.07934843688969]}, {
		"id": 647767930,
		"name": "Bieno",
		"coords": [11.554033756256104, 46.08067312911356]
	}, {"id": 27653816030, "name": "Pradellano", "coords": [11.574697494506836, 46.07847025260432]},
		{"id": 12838358040, "name": "Fiavè", "coords": [10.842046737670898, 46.00459325574482]}, {
		"id": 19220840040,
		"name": "Vigo Lomaso",
		"coords": [10.869340896606445, 46.01329680720971]
	}, {"id": 647764910, "name": "Santa Croce", "coords": [10.838871002197266, 46.02527685325964]}, {
		"id": 647764880,
		"name": "Ponte Arche",
		"coords": [10.871315002441406, 46.03671805541737]
	}, {"id": 647769210, "name": "Stenico", "coords": [10.854063034057617, 46.052148008144655]}, {
		"id": 13571859500,
		"name": "Sclemo",
		"coords": [10.883760452270508, 46.05590058824848]
	}, {"id": 1073738390, "name": "Sarche", "coords": [10.951480865478516, 46.04589314132977]}, {
		"id": 647765020,
		"name": "Cimone",
		"coords": [11.069755554199219, 45.97877261430466]
	}, {"id": 647767620, "name": "Aldeno", "coords": [11.090869903564453, 45.976804265394065]}, {
		"id": 12457977380,
		"name": "Pianezze",
		"coords": [11.087179183959961, 45.964813720122976]
	}, {"id": 647767100, "name": "Vezzano", "coords": [10.999374389648438, 46.07876794375849]}, {
		"id": 4427569450,
		"name": "Ciago",
		"coords": [11.003665924072266, 46.08972186118365]
	}, {
		"id": 3668693230,
		"name": "Monte Terlago",
		"coords": [11.038341522216797, 46.110611617510614]
	}, {"id": 647768830, "name": "Terlago", "coords": [11.046152114868164, 46.09805490656467]}, {
		"id": 3303083430,
		"name": "Cadine",
		"coords": [11.063575744628906, 46.086685938905475]
	}, {"id": 3199131530, "name": "Covelo", "coords": [11.018643379211426, 46.09853104255552]}, {
		"id": 23437708360,
		"name": "Maso Ariol",
		"coords": [11.026668548583984, 46.10638669281869]
	}, {"id": 3199169930, "name": "Vallene", "coords": [11.044542789459229, 46.10852894862683]}, {
		"id": 38198221090,
		"name": "Nord Center",
		"coords": [11.114065647125244, 46.09109094793334]
	}, {"id": 15338841290, "name": "Solteri", "coords": [11.121039390563965, 46.08845691387424]}, {
		"id": 2920289960,
		"name": "Martignano",
		"coords": [11.130845546722412, 46.08809974702922]
	}, {"id": 38204410470, "name": "Maderno", "coords": [11.138956546783447, 46.090064136057066]}, {
		"id": 3051054180,
		"name": "Pinara",
		"coords": [11.12842082977295, 46.09604616259327]
	}, {
		"id": 8894646510,
		"name": "Melta di Gardolo",
		"coords": [11.118206977844238, 46.10125386626689]
	}, {"id": 4104220240, "name": "Roncafort", "coords": [11.10170602798462, 46.10137289374413]}, {
		"id": 2785465370,
		"name": "Gazzadina",
		"coords": [11.127927303314209, 46.13057149380526]
	}, {
		"id": 13326405030,
		"name": "Nuova Gazzadina",
		"coords": [11.127927303314209, 46.13565698272848]
	}, {
		"id": 2690145320,
		"name": "San Lazzaro di Meano",
		"coords": [11.117005348205566, 46.136682945494584]
	}, {"id": 4853604900, "name": "Ravina", "coords": [11.110804080963135, 46.03860979683924]}, {
		"id": 15338841160,
		"name": "Bolghera",
		"coords": [11.132326126098633, 46.058610626409944]
	}, {
		"id": 4827259960,
		"name": "Fontanabotte",
		"coords": [11.248283386230469, 46.056823802998565]
	}, {"id": 4747821060, "name": "Zivignago", "coords": [11.255407333374023, 46.06685904484945]}, {
		"id": 2741105590,
		"name": "Canzolino",
		"coords": [11.22178316116333, 46.08341171781018]
	}, {"id": 2749025270, "name": "Buss", "coords": [11.225752830505371, 46.08839738622734]}, {
		"id": 4785282150,
		"name": "Guarda",
		"coords": [11.230237483978271, 46.090525459699705]
	}, {"id": 2740854410, "name": "Puel", "coords": [11.22976541519165, 46.09402246504689]}, {
		"id": 5020940270,
		"name": "Mezadòi",
		"coords": [11.271543502807617, 46.08778722414266]
	}, {
		"id": 4711197660,
		"name": "Maso Slaifer",
		"coords": [11.268432140350342, 46.07559745043636]
	}, {
		"id": 39271562560,
		"name": "Dos del Derdel",
		"coords": [11.286242008209229, 46.066055101590166]
	}, {"id": 4711138270, "name": "Roveda", "coords": [11.298751831054688, 46.06821381162919]}, {
		"id": 5406877290,
		"name": "Maso Oberstol",
		"coords": [11.291499137878418, 46.08193825491611]
	}, {
		"id": 5307082160,
		"name": "Omeri – Lommerer",
		"coords": [11.303107738494873, 46.0930849917502]
	}, {"id": 4842718450, "name": "Rossatti", "coords": [11.29694938659668, 46.096745511390196]}, {
		"id": 43555166840,
		"name": "Donadini",
		"coords": [11.298859119415283, 46.102578032479045]
	}, {
		"id": 5541637080,
		"name": "Plozzeri – Plotzer",
		"coords": [11.31068229675293, 46.10042066673114]
	}, {"id": 16738099160, "name": "Joppern", "coords": [11.314716339111328, 46.09557000514582]}, {
		"id": 6236065080,
		"name": "Eckern – Eccher",
		"coords": [11.32467269897461, 46.10638669281869]
	}, {"id": 2691254610, "name": "Miola", "coords": [11.247596740722656, 46.12694296321328]}, {
		"id": 34204531170,
		"name": "Bugno",
		"coords": [11.249656677246094, 46.12425115289966]
	}, {
		"id": 16021862710,
		"name": "Sigismondi – Simater",
		"coords": [11.34361982345581, 46.12967925434657]
	}, {"id": 5321719550, "name": "Marcheli", "coords": [11.31265640258789, 46.119692621669515]}, {
		"id": 9569265570,
		"name": "Conti",
		"coords": [11.306004524230957, 46.119833919492464]
	}, {"id": 11831741590, "name": "Pizoi", "coords": [11.306508779525757, 46.1160410627825]}, {
		"id": 11831720580,
		"name": "Doss Martin",
		"coords": [11.308236122131348, 46.11500727813038]
	}, {"id": 16821534840, "name": "Soa", "coords": [11.328223943710327, 46.117461555383755]}, {
		"id": 7397264960,
		"name": "Zern",
		"coords": [11.327944993972778, 46.11609312336719]
	}, {"id": 16655931260, "name": "Zeabl", "coords": [11.330605745315552, 46.10883391577414]}, {
		"id": 6236091600,
		"name": "Tuneger",
		"coords": [11.326678991317749, 46.108759533698674]
	}, {
		"id": 6235998140,
		"name": "Bolleri – Boler",
		"coords": [11.329586505889893, 46.10759172195509]
	}, {
		"id": 4700009850,
		"name": "San Francesco",
		"coords": [11.31141185760498, 46.10617841350785]
	}, {"id": 39640090770, "name": "Fondi", "coords": [11.303268671035767, 46.105241146870526]}, {
		"id": 37542549240,
		"name": "Pallaori",
		"coords": [11.30043625831604, 46.103864972535376]
	}, {
		"id": 5307165850,
		"name": "Rodler – Roudler",
		"coords": [11.308761835098267, 46.10601476492582]
	}, {"id": 5877544570, "name": "Davìdi", "coords": [11.3094162940979, 46.11391025522266]}, {
		"id": 16649857130,
		"name": "Roro",
		"coords": [11.30642294883728, 46.1127090830615]
	}, {"id": 11831755370, "name": "Donadi", "coords": [11.305328607559204, 46.110157899922655]}, {
		"id": 36753288540,
		"name": "Tadesia",
		"coords": [11.300624012947083, 46.10993475875517]
	}, {"id": 11831765910, "name": "Predaroneri", "coords": [11.299309730529785, 46.10782974928614]}, {
		"id": 7397309640,
		"name": "Houver",
		"coords": [11.326480507850647, 46.116472420428124]
	}, {"id": 16822042650, "name": "Vilzer", "coords": [11.336549520492554, 46.11999380869719]}, {
		"id": 5671314320,
		"name": "Moser – Mouser",
		"coords": [11.3046795129776, 46.09902205348993]
	}, {"id": 5020859720, "name": "Stefani", "coords": [11.294546127319336, 46.097772198874765]}, {
		"id": 16647932890,
		"name": "Castello",
		"coords": [11.299400925636292, 46.11159713874821]
	}, {"id": 5289201010, "name": "Sluffi", "coords": [11.296096444129944, 46.11460194000654]}, {
		"id": 5289195450,
		"name": "Titani",
		"coords": [11.299213171005249, 46.113635066389236]
	}, {"id": 6667362790, "name": "Bus", "coords": [11.278077363967896, 46.09300686825614]}, {
		"id": 4828474880,
		"name": "Taìci",
		"coords": [11.281837821006775, 46.08932749836998]
	}, {"id": 16580674010, "name": "Pian", "coords": [11.289744973182678, 46.09337144361493]}, {
		"id": 16580673720,
		"name": "Marcotti",
		"coords": [11.289482116699219, 46.0919205682045]
	}, {
		"id": 5885832040,
		"name": "Villata – Plankerhof",
		"coords": [11.297239065170288, 46.09003437310889]
	}, {"id": 4828570410, "name": "Agnoletti", "coords": [11.279236078262329, 46.0887694329665]}, {
		"id": 5267487260,
		"name": "Pintarèi",
		"coords": [11.273104548454285, 46.08869874427916]
	}, {"id": 4786617370, "name": "Portolo", "coords": [11.276328563690186, 46.08043498454177]}, {
		"id": 7667057220,
		"name": "Pizze",
		"coords": [11.289825439453125, 46.08370194071091]
	}, {"id": 7035827980, "name": "Maso Puamine", "coords": [11.291617155075073, 46.08293545127998]},
		{"id": 2936182410, "name": "Costa", "coords": [11.235242486000061, 46.073680925410315]}, {
		"id": 5060780680,
		"name": "Riposo",
		"coords": [11.23751699924469, 46.084531672120335]
	}, {"id": 4828314810, "name": "Casalino", "coords": [11.228563785552979, 46.07337576382679]}, {
		"id": 38204410500,
		"name": "Pantè",
		"coords": [11.153005957603455, 46.06450673343488]
	}, {"id": 38204410510, "name": "Salè", "coords": [11.152732372283936, 46.061904930494734]}, {
		"id": 38198203780,
		"name": "Marnighe",
		"coords": [11.142073273658752, 46.07774462368744]
	}, {"id": 2676669960, "name": "Sardagna", "coords": [11.096652746200562, 46.063133264758676]}, {
		"id": 2931210960,
		"name": "Fraveggio",
		"coords": [10.990281701087952, 46.07919959307927]
	}, {"id": 6564068640, "name": "Lon", "coords": [10.99155306816101, 46.08523116693186]}, {
		"id": 647767170,
		"name": "Dorsino",
		"coords": [10.897375345230103, 46.07270216948038]
	}, {"id": 13571859510, "name": "Tavodo", "coords": [10.892010927200317, 46.065865279945314]}, {
		"id": 44376803220,
		"name": "Seo",
		"coords": [10.87245225906372, 46.05722212072533]
	}, {"id": 13571859480, "name": "Premione", "coords": [10.874823331832886, 46.048406342582666]}, {
		"id": 13571859520,
		"name": "Villa Banale",
		"coords": [10.88768184185028, 46.04747926387503]
	}, {"id": 54013088970, "name": "Vergonzo", "coords": [10.841295719146729, 46.03957053835336]}, {
		"id": 54067805430,
		"name": "Cillà",
		"coords": [10.845887660980225, 46.04286597824478]
	}, {"id": 54013088950, "name": "Biè", "coords": [10.846118330955505, 46.03634193375501]}, {
		"id": 24013856180,
		"name": "Sesto",
		"coords": [10.845662355422974, 46.032811478911725]
	}, {"id": 24013856210, "name": "Comighello", "coords": [10.850806832313538, 46.0309940260378]}, {
		"id": 24013856220,
		"name": "Cares",
		"coords": [10.86425006389618, 46.03251353991337]
	}, {
		"id": 44640798410,
		"name": "Terme di Comano",
		"coords": [10.881378650665283, 46.04124993397744]
	}, {"id": 13899593600, "name": "Comano", "coords": [10.89218258857727, 46.03519120900975]}, {
		"id": 647765160,
		"name": "Campo Lomaso",
		"coords": [10.866095423698425, 46.0241482469911]
	}, {"id": 19220840000, "name": "Dasindo", "coords": [10.860435962677002, 46.011109870668434]}, {
		"id": 24013856190,
		"name": "Gallio",
		"coords": [10.84099531173706, 46.02085541584401]
	}, {"id": 24013856170, "name": "Madice", "coords": [10.831902623176575, 46.02332505759051]}, {
		"id": 31242094560,
		"name": "Cavrasto",
		"coords": [10.820358395576477, 46.016008935465834]
	}, {"id": 23991870970, "name": "Cavrasto", "coords": [10.820374488830566, 46.01731652120759]}, {
		"id": 23950838280,
		"name": "Cavaione",
		"coords": [10.819730758666992, 46.02268437681468]
	}, {"id": 23991870940, "name": "Marazzone", "coords": [10.820733904838562, 46.02709449412609]}, {
		"id": 23991870950,
		"name": "Larido",
		"coords": [10.823400020599365, 46.0289753915238]
	}, {"id": 23991870960, "name": "Bivedo", "coords": [10.829300880432129, 46.02805171129455]}, {
		"id": 24005072570,
		"name": "Balbido",
		"coords": [10.811501741409302, 46.01454484978228]
	}, {"id": 23991870980, "name": "Rango", "coords": [10.811721682548523, 46.018989142794766]}, {
		"id": 12838283670,
		"name": "Cornelle",
		"coords": [10.822101831436157, 46.00455599444703]
	}, {"id": 3015962590, "name": "Stumiaga", "coords": [10.849884152412415, 46.007659774523006]}, {
		"id": 3015960420,
		"name": "Favrio",
		"coords": [10.857839584350586, 46.00038629669373]
	}, {"id": 11681814030, "name": "Fevri", "coords": [10.776053667068481, 46.051600735575164]}, {
		"id": 11681819630,
		"name": "Baltram",
		"coords": [10.775994658470154, 46.05301916553776]
	}, {"id": 11681814520, "name": "Bolciana", "coords": [10.777555704116821, 46.05469070684185]}, {
		"id": 10024173620,
		"name": "Pez",
		"coords": [10.796771049499512, 46.05648504454024]
	}, {"id": 647768100, "name": "Darè", "coords": [10.718069672584534, 46.075199265277604]}, {
		"id": 4621283210,
		"name": "Borzago",
		"coords": [10.733857154846191, 46.09933451271712]
	}, {"id": 17963575800, "name": "Fisto", "coords": [10.740723609924316, 46.100446704407204]}, {
		"id": 2826735290,
		"name": "Strembo",
		"coords": [10.751452445983887, 46.119912004976015]
	}, {"id": 647768000, "name": "Bocenago", "coords": [10.75886070728302, 46.119105116309754]},
		{
			"id": 15209871080,
			"name": "Villa Lagarina",
			"coords": [11.03302001953125, 45.91509388866348]
		}, {"id": 647768030, "name": "Bondone", "coords": [10.551681518554688, 45.806307193078965]}, {
		"id": 647769500,
		"name": "Grigno",
		"coords": [11.635894775390625, 46.01699242164091]
	}, {"id": 39216775040, "name": "Primiero", "coords": [11.830902099609375, 46.17578909190212]}, {
		"id": 647766110,
		"name": "Cavalese",
		"coords": [11.460456848144531, 46.291206053301465]
	}, {"id": 43920040050, "name": "Predazzo", "coords": [11.602592468261719, 46.312315787003286]}, {
		"id": 647769620,
		"name": "Pozza di Fassa",
		"coords": [11.68670654296875, 46.430285240839964]
	}, {
		"id": 647765860,
		"name": "Canazei",
		"coords": [11.771163940429688, 46.47688154071966]
	}, {
		"id": 2688023530,
		"name": "Baselga di Piné",
		"coords": [11.245880126953125, 46.13321838582266]
	}, {"id": 5199820730, "name": "Solaiolo", "coords": [11.385698318481445, 46.297106924808475]}, {
		"id": 2534596980,
		"name": "Aguai",
		"coords": [11.404409408569336, 46.30013124564326]
	}, {"id": 2488906500, "name": "Predaia", "coords": [11.414387226104736, 46.277340951743184]}, {
		"id": 2488892270,
		"name": "Molina di Fiemme",
		"coords": [11.416490077972412, 46.2732623128656]
	}, {"id": 2869257710, "name": "Stramentizzo", "coords": [11.403958797454834, 46.26718086940997]}, {
		"id": 2610870550,
		"name": "Castello di Fiemme",
		"coords": [11.435115337371826, 46.28112269109056]
	}, {
		"id": 2534593980,
		"name": "Villaggio Veronza",
		"coords": [11.432089805603027, 46.28990125215941]
	}, {"id": 647767760, "name": "Carano", "coords": [11.439685821533203, 46.291754653590345]}, {
		"id": 647767250,
		"name": "Daiano",
		"coords": [11.447474956512451, 46.300961422141455]
	}, {"id": 647767120, "name": "Varena", "coords": [11.45599365234375, 46.303555642567886]}, {
		"id": 2749033480,
		"name": "Cavazzal",
		"coords": [11.472666263580322, 46.288848492217625]
	}, {
		"id": 2534593650,
		"name": "Piera di Tesero",
		"coords": [11.491656303405762, 46.29049434744104]
	}, {
		"id": 2534590020,
		"name": "Masi di Cavalese",
		"coords": [11.485755443572998, 46.28257599599763]
	}, {"id": 647768770, "name": "Tesero", "coords": [11.511976718902588, 46.29042021088205]}, {
		"id": 2534588450,
		"name": "Lago di Tesero",
		"coords": [11.5211820602417, 46.28254633709915]
	}, {"id": 647766340, "name": "Panchià", "coords": [11.542596817016602, 46.285645605160965]}, {
		"id": 647766980,
		"name": "Ziano di Fiemme",
		"coords": [11.565985679626465, 46.2865204864199]
	}, {"id": 2616645520, "name": "Bosin", "coords": [11.569032669067383, 46.28398477518061]}, {
		"id": 2983922980,
		"name": "Roda",
		"coords": [11.572937965393066, 46.28628323186609]
	}, {"id": 43924219290, "name": "Coste", "coords": [11.5940523147583, 46.31021110868306]}, {
		"id": 2792996910,
		"name": "Al Fol",
		"coords": [11.596198081970215, 46.32212666987883]
	}, {"id": 43921454350, "name": "Stalimen", "coords": [11.601777076721191, 46.32873542703058]}, {
		"id": 2723373530,
		"name": "Mezzavalle",
		"coords": [11.608986854553223, 46.33761005768065]
	}, {"id": 43931673170, "name": "Zaluna", "coords": [11.647589206695557, 46.314390742734304]}, {
		"id": 3274934840,
		"name": "Bellamonte",
		"coords": [11.662373542785645, 46.313575590936495]
	}, {"id": 2534726030, "name": "Forno", "coords": [11.627440452575684, 46.34929748195634]}, {
		"id": 2534748950,
		"name": "Medìl",
		"coords": [11.625874042510986, 46.35430349749842]
	}, {"id": 2534821280, "name": "Peniola", "coords": [11.635315418243408, 46.36503970616846]}, {
		"id": 15343119030,
		"name": "Sorte",
		"coords": [11.648683547973633, 46.3740268818313]
	}, {"id": 647766370, "name": "Moena", "coords": [11.65926218032837, 46.37694332446321]}, {
		"id": 15432650600,
		"name": "Someda",
		"coords": [11.66821002960205, 46.37720979368905]
	}, {
		"id": 647766770,
		"name": "Soraga di Fassa",
		"coords": [11.666171550750732, 46.3946014996954]
	}, {"id": 35475273520, "name": "Tamion", "coords": [11.657438278198242, 46.406661428544226]}, {
		"id": 4532864470,
		"name": "Vallonga",
		"coords": [11.65949821472168, 46.412239168744804]
	}, {
		"id": 647769540,
		"name": "Vigo di Fassa",
		"coords": [11.672608852386475, 46.420405016658776]
	}, {
		"id": 2991330550,
		"name": "San Giovanni",
		"coords": [11.679883003234863, 46.420242303369605]
	}, {
		"id": 2723333320,
		"name": "Pera di Fassa",
		"coords": [11.692907810211182, 46.44085864636952]
	}, {"id": 18037124180, "name": "Monzon", "coords": [11.690118312835693, 46.44671380359338]}, {
		"id": 18037124190,
		"name": "Ronch",
		"coords": [11.692628860473633, 46.449522872802135]
	}, {"id": 647769520, "name": "Mazzin", "coords": [11.70018196105957, 46.45766835429828]}, {
		"id": 2723333220,
		"name": "Campestrin",
		"coords": [11.715567111968994, 46.46387643300852]
	}, {"id": 2723333200, "name": "Fontanazzo", "coords": [11.729686260223389, 46.470113361079825]}, {
		"id": 44640798210,
		"name": "Fontanazzo di Sopra",
		"coords": [11.73464298248291, 46.47225621643477]
	}, {
		"id": 647765880,
		"name": "Campitello di Fassa",
		"coords": [11.741530895233154, 46.476172251334106]
	}, {"id": 3356915770, "name": "Alba", "coords": [11.78058385848999, 46.46766005757547]}, {
		"id": 3356915850,
		"name": "Penia",
		"coords": [11.798222064971924, 46.45857004776343]
	}, {"id": 41920435070, "name": "Paneveggio", "coords": [11.746423244476318, 46.30887711573072]}, {
		"id": 43935556120,
		"name": "Paneveggio",
		"coords": [11.747812628746033, 46.3095552328777]
	}, {"id": 43289949900, "name": "Roncosogno", "coords": [11.525344848632812, 46.28362887649118]}, {
		"id": 14062951470,
		"name": "Suerta",
		"coords": [11.431360244750977, 46.10372363376649]
	}, {"id": 14355600770, "name": "Lissa", "coords": [11.626839637756348, 46.07398980675825]}, {
		"id": 14355600780,
		"name": "Molini",
		"coords": [11.624071598052979, 46.06994071887783]
	}, {"id": 647769730, "name": "Pieve Tesino", "coords": [11.608085632324219, 46.069315465596446]}, {
		"id": 647768320,
		"name": "Cinte Tesino",
		"coords": [11.614758968353271, 46.056823802998565]
	}, {
		"id": 647765930,
		"name": "Castello Tesino",
		"coords": [11.632161140441895, 46.0641791884523]
	}, {"id": 14355600750, "name": "Roa", "coords": [11.692607402801514, 46.059221111156404]}, {
		"id": 4227013120,
		"name": "Selva di Grigno",
		"coords": [11.5950608253479, 46.01666459485938]
	}, {"id": 27375197200, "name": "Palù", "coords": [11.656408309936523, 46.007112061281475]}, {
		"id": 27375197740,
		"name": "Serafini",
		"coords": [11.660549640655518, 46.00466777826506]
	}, {
		"id": 27402381130,
		"name": "Belvedere di Tezze",
		"coords": [11.665699481964111, 46.00059870176264]
	}, {
		"id": 18362921560,
		"name": "Tezze Valsugana",
		"coords": [11.667898893356323, 45.99105830160457]
	}, {
		"id": 27402381150,
		"name": "Martincelli",
		"coords": [11.680516004562378, 45.978019579992804]
	}, {
		"id": 13643534870,
		"name": "Pianello di Sopra",
		"coords": [11.679829359054565, 45.97312087909327]
	}, {
		"id": 13643534910,
		"name": "Pianello Vallon",
		"coords": [11.681395769119263, 45.96862442976274]
	}, {
		"id": 13643533800,
		"name": "Case Celeste",
		"coords": [11.670076847076416, 45.968930170056296]
	}, {"id": 4553473790, "name": "Lochere", "coords": [11.298655271530151, 45.98979109347766]}, {
		"id": 19186575400,
		"name": "Paldaofi",
		"coords": [11.239625215530396, 45.96956401650837]
	}, {"id": 42904879380, "name": "Tiecheri", "coords": [11.222888231277466, 45.96682723928839]}, {
		"id": 12159140910,
		"name": "Migazzone",
		"coords": [11.227587461471558, 46.003207118569435]
	}, {"id": 12457977660, "name": "Gatter", "coords": [11.080816984176636, 45.99132664783838]}, {
		"id": 12457977930,
		"name": "Zobbio",
		"coords": [11.084765195846558, 45.991699348782475]
	}, {
		"id": 8979744830,
		"name": "Buzzi di Cimone",
		"coords": [11.07246994972229, 45.982015763858556]
	}, {"id": 9001706630, "name": "Cimoneri", "coords": [11.07149362564087, 45.984349222913636]}, {
		"id": 23628508810,
		"name": "Pietra",
		"coords": [11.064434051513672, 45.98103911122428]
	}, {"id": 9001374270, "name": "Petrolli", "coords": [11.068317890167236, 45.98001770797836]}, {
		"id": 12457977130,
		"name": "Frizzi",
		"coords": [11.064112186431885, 45.976863913358756]
	}, {
		"id": 12457977620,
		"name": "Case sparse",
		"coords": [11.063114404678345, 45.97856385334322]
	}, {"id": 23628508790, "name": "Costa", "coords": [11.059842109680176, 45.97804194749614]}, {
		"id": 12457977480,
		"name": "Chiausa",
		"coords": [11.084786653518677, 45.97494023412062]
	}, {"id": 647769810, "name": "Nomi", "coords": [11.071879863739014, 45.928602634947765]}, {
		"id": 5442302820,
		"name": "Chiusole",
		"coords": [11.05398416519165, 45.925931000217446]
	}, {"id": 647769770, "name": "Pomarolo", "coords": [11.043083667755127, 45.92810264605649]}, {
		"id": 3701167470,
		"name": "Savignano",
		"coords": [11.055057048797607, 45.937840424649124]
	}, {"id": 17783530820, "name": "Piazzo", "coords": [11.034425497055054, 45.92342342710154]}, {
		"id": 17783530810,
		"name": "Pedersano",
		"coords": [11.020252704620361, 45.91927374167176]
	}, {"id": 647767050, "name": "Volano", "coords": [11.060807704925537, 45.917176390500344]}, {
		"id": 2983889220,
		"name": "Sant'Ilario",
		"coords": [11.039607524871826, 45.90864435496826]
	}, {"id": 2983665200, "name": "Brione", "coords": [11.043287515640259, 45.90212687739634]}, {
		"id": 3077055460,
		"name": "Brancolino",
		"coords": [11.02104663848877, 45.9023209943127]
	}, {"id": 17783530570, "name": "Marano", "coords": [11.015843152999878, 45.89628812064029]}, {
		"id": 2983665180,
		"name": "San Giorgio",
		"coords": [11.030359268188477, 45.895474229831734]
	}, {"id": 3077051050, "name": "Sasso", "coords": [11.01330041885376, 45.903866439396126]}, {
		"id": 3077000890,
		"name": "Patone",
		"coords": [11.000522375106812, 45.90310492110828]
	}, {"id": 6490084810, "name": "Belvedere", "coords": [11.00903034210205, 45.90936100683467]}, {
		"id": 3077050980,
		"name": "Noarna",
		"coords": [11.011615991592407, 45.91051808146369]
	}, {
		"id": 17783530540,
		"name": "Castellano",
		"coords": [11.006927490234375, 45.923789121907134]
	}, {"id": 26281952520, "name": "Reviano", "coords": [11.00655198097229, 45.89034420314684]}, {
		"id": 3066736910,
		"name": "Folaso",
		"coords": [11.001155376434326, 45.88680443248961]
	}, {"id": 2983738840, "name": "Cornalè", "coords": [11.01128339767456, 45.88271922691226]}, {
		"id": 647766540,
		"name": "Ronzo-Chienis",
		"coords": [10.950075387954712, 45.88959743486356]
	}, {"id": 2983665170, "name": "Santa Maria", "coords": [11.0432767868042, 45.88522863923791]}, {
		"id": 2983665140,
		"name": "Lizzana",
		"coords": [11.032204627990723, 45.867428780958136]
	}, {"id": 17783530800, "name": "Nomesino", "coords": [10.962671041488647, 45.86550130358589]}, {
		"id": 8717829270,
		"name": "Varano",
		"coords": [10.937769412994385, 45.881307632666676]
	}, {"id": 11052366440, "name": "Torbole", "coords": [10.876400470733643, 45.8693038982947]}, {
		"id": 3408031390,
		"name": "Alboletta",
		"coords": [10.848129987716675, 45.891636088567395]
	}, {"id": 3133917130, "name": "San Tomaso", "coords": [10.860886573791504, 45.90302279596307]}, {
		"id": 2981164630,
		"name": "Varone",
		"coords": [10.84125280380249, 45.907651478238876]
	}, {"id": 3293651930, "name": "Gavazzo", "coords": [10.840812921524048, 45.91292173358892]}, {
		"id": 3293651850,
		"name": "Cologna",
		"coords": [10.841821432113647, 45.914646028595456]
	}, {"id": 3293594620, "name": "Teggiole", "coords": [10.845876932144165, 45.914974459665444]}, {
		"id": 2997232510,
		"name": "Piazze",
		"coords": [10.851584672927856, 45.91693754121087]
	}, {"id": 2997314440, "name": "Volta di No", "coords": [10.847636461257935, 45.91780336499386]}, {
		"id": 3293981270,
		"name": "Fontanelle",
		"coords": [10.848290920257568, 45.91879606012046]
	}, {"id": 3874873110, "name": "Varignano", "coords": [10.858279466629028, 45.9202365560505]}, {
		"id": 6001477900,
		"name": "Zucchetti",
		"coords": [10.813647508621216, 45.906113604037415]
	}, {"id": 6001477730, "name": "Malacarne", "coords": [10.810128450393677, 45.90774106183059]}, {
		"id": 4953160470,
		"name": "Righi",
		"coords": [10.806856155395508, 45.91036131792657]
	}, {"id": 6001477930, "name": "Bologni", "coords": [10.813990831375122, 45.909935814665346]}, {
		"id": 6001477950,
		"name": "Cazzoli",
		"coords": [10.815267562866211, 45.911712455779934]
	}, {"id": 4953160410, "name": "Zumiani", "coords": [10.816318988800049, 45.91330989124998]}, {
		"id": 2981164350,
		"name": "Deva",
		"coords": [10.83351731300354, 45.908763797588534]
	}, {"id": 3293861780, "name": "Frapporta", "coords": [10.833163261413574, 45.91596720540022]}, {
		"id": 3018504830,
		"name": "Massone",
		"coords": [10.902460813522339, 45.923012950468774]
	}, {"id": 39029766030, "name": "Prabi", "coords": [10.892332792282104, 45.92822204679834]}, {
		"id": 3854935850,
		"name": "Santa Maria di Laghel",
		"coords": [10.884833335876465, 45.92837129736424]
	}, {"id": 22698918080, "name": "Vigne", "coords": [10.865339040756226, 45.92207257437076]}, {
		"id": 4984276720,
		"name": "Enguiso",
		"coords": [10.728696584701538, 45.905538756624935]
	}, {"id": 16011373780, "name": "Barcesino", "coords": [10.78231930732727, 45.871051958677896]}, {
		"id": 3228201040,
		"name": "Legos",
		"coords": [10.776128768920898, 45.86785460996842]
	}, {"id": 4898691420, "name": "Tempesta", "coords": [10.85999608039856, 45.83868151830782]}, {
		"id": 2690053140,
		"name": "Leano",
		"coords": [10.796856880187988, 45.85287392698453]
	}, {
		"id": 23697428710,
		"name": "Passablù di sotto",
		"coords": [10.607771873474121, 45.94639781134924]
	}, {
		"id": 23697428630,
		"name": "Passablù di sopra",
		"coords": [10.610507726669312, 45.944323866792644]
	}, {"id": 14213144200, "name": "Frugone", "coords": [10.648316144943237, 45.95370822006424]}, {
		"id": 26597963280,
		"name": "Sevror",
		"coords": [10.640977621078491, 45.95469281367852]
	}, {"id": 4324404420, "name": "Strada", "coords": [10.643949508666992, 45.94524894517349]}, {
		"id": 23697428780,
		"name": "Ribor",
		"coords": [10.573482513427734, 45.94975475139907]
	}, {"id": 647768710, "name": "Fondo", "coords": [11.136531829833984, 46.439986233222584]}, {
		"id": 13042119090,
		"name": "Somrabbi",
		"coords": [10.80256462097168, 46.40987202771808]
	}, {"id": 12159203540, "name": "Mocenigo", "coords": [11.008987426757812, 46.45069081157109]}, {
		"id": 647767800,
		"name": "Castelfondo",
		"coords": [11.116790771484375, 46.455717097651615]
	}, {"id": 647767740, "name": "Brez", "coords": [11.108207702636719, 46.430995128108464]}, {
		"id": 647766530,
		"name": "Sarnonico",
		"coords": [11.140823364257812, 46.418748276834606]
	}, {"id": 647766080, "name": "Cavareno", "coords": [11.138076782226562, 46.40839251240533]}, {
		"id": 647767610,
		"name": "Amblar",
		"coords": [11.146745681762695, 46.39543024267246]
	}, {"id": 647766470, "name": "Romeno", "coords": [11.120309829711914, 46.394009532720645]}, {
		"id": 647767280,
		"name": "Dambel",
		"coords": [11.093101501464844, 46.404723141253214]
	}, {"id": 647769270, "name": "Livo", "coords": [11.019287109375, 46.404723141253214]}, {
		"id": 647768340,
		"name": "Cis",
		"coords": [11.002979278564453, 46.39915943032287]
	}, {"id": 647765740, "name": "Caldes", "coords": [10.942554473876953, 46.36540988263408]}, {
		"id": 44640798130,
		"name": "Bresimo",
		"coords": [10.970706939697266, 46.411824926481415]
	}, {"id": 647766440, "name": "Revò", "coords": [11.059713363647461, 46.39181919908128]}, {
		"id": 12708231090,
		"name": "Malgolo",
		"coords": [11.091041564941406, 46.37980039894214]
	}, {"id": 12775828310, "name": "Banco", "coords": [11.068639755249023, 46.37429336528865]}, {
		"id": 647768520,
		"name": "Sanzeno",
		"coords": [11.07447624206543, 46.36594293233779]
	}, {"id": 647766030, "name": "Coredo", "coords": [11.088809967041016, 46.350837843267755]}, {
		"id": 647766490,
		"name": "San Michele all'Adige",
		"coords": [11.133613586425781, 46.192903217560485]
	}, {"id": 13042119030, "name": "Serra", "coords": [10.809860229492188, 46.41152903722411]}, {
		"id": 14355632080,
		"name": "Bagni di Rabbi",
		"coords": [10.81127643585205, 46.40793385450962]
	}, {"id": 14355631990, "name": "Nistella", "coords": [10.828442573547363, 46.40888075689509]}, {
		"id": 14355632310,
		"name": "Penasa",
		"coords": [10.835137367248535, 46.40769712634486]
	}, {"id": 14355631860, "name": "Zanon", "coords": [10.839643478393555, 46.40558132274819]}, {
		"id": 3336962400,
		"name": "San Bernardo",
		"coords": [10.849707126617432, 46.39837513821101]
	}, {"id": 14355631750, "name": "Tassè", "coords": [10.858054161071777, 46.392766381172464]}, {
		"id": 3336935680,
		"name": "Pracorno",
		"coords": [10.884618759155273, 46.3697777754935]
	}, {"id": 14811977510, "name": "Presson", "coords": [10.880992412567139, 46.33260251325123]}, {
		"id": 3336908670,
		"name": "Magras",
		"coords": [10.912964344024658, 46.36107125687997]
	}, {"id": 17941917650, "name": "Samoclevo", "coords": [10.941438674926758, 46.369452046383486]}, {
		"id": 14043593840,
		"name": "Le Contre",
		"coords": [10.94214677810669, 46.36176723711412]
	}, {"id": 647766930, "name": "Terzolas", "coords": [10.926096439361572, 46.36160434891784]}, {
		"id": 647768260,
		"name": "Cavizzana",
		"coords": [10.957918167114258, 46.36749763093073]
	}, {"id": 49620536860, "name": "Molini", "coords": [10.91442346572876, 46.34627587790894]}, {
		"id": 18027378790,
		"name": "Cassana",
		"coords": [10.960235595703125, 46.376588030139345]
	}, {"id": 12918951940, "name": "Tozzaga", "coords": [10.970921516418457, 46.38185799210231]}, {
		"id": 18027378780,
		"name": "Bordiana",
		"coords": [10.981950759887695, 46.3871274454292]
	}, {"id": 12158586080, "name": "Baselga", "coords": [10.985791683197021, 46.41561216719862]}, {
		"id": 12158585650,
		"name": "Preghena",
		"coords": [11.01306438446045, 46.40950215265224]
	}, {"id": 12158587680, "name": "Varollo", "coords": [11.020896434783936, 46.39923341956279]}, {
		"id": 12173461180,
		"name": "Scanna",
		"coords": [11.025574207305908, 46.39577061560627]
	}, {"id": 647765790, "name": "Cagnò", "coords": [11.041173934936523, 46.39430551701068]}, {
		"id": 647768430,
		"name": "Romallo",
		"coords": [11.066172122955322, 46.397206078100425]
	}, {"id": 9428736440, "name": "Caltron", "coords": [11.027441024780273, 46.372827690181595]}, {
		"id": 9428736230,
		"name": "Dres",
		"coords": [11.03351354598999, 46.372635224872]
	}, {"id": 50300692190, "name": "Pez", "coords": [11.037354469299316, 46.363159170985085]}, {
		"id": 12173456870,
		"name": "Maiano",
		"coords": [11.04276180267334, 46.361915316871176]
	}, {"id": 42341479070, "name": "San Vito", "coords": [11.019115447998047, 46.360834325415766]}, {
		"id": 9419247970,
		"name": "Mechel",
		"coords": [11.017956733703613, 46.34867540065957]
	}, {"id": 3635802430, "name": "Tavon", "coords": [11.09567642211914, 46.36321840142699]}, {
		"id": 12775828050,
		"name": "Casez",
		"coords": [11.075763702392578, 46.37578860945848]
	}, {"id": 3890164640, "name": "Salter", "coords": [11.106834411621094, 46.37947472960769]}, {
		"id": 14126767840,
		"name": "Tregiovo",
		"coords": [11.053833961486816, 46.4381230668497]
	}, {
		"id": 12159200630,
		"name": "Corte Inferiore",
		"coords": [11.031861305236816, 46.43972007049848]
	}, {"id": 647765360, "name": "Marcena", "coords": [11.01656198501587, 46.44264778895297]}, {
		"id": 12159203060,
		"name": "Corte Superiore",
		"coords": [11.013236045837402, 46.44816271001224]
	}, {"id": 12159200330, "name": "Lanza", "coords": [11.012420654296875, 46.45494840159341]}, {
		"id": 12159597100,
		"name": "Salobbi",
		"coords": [11.108057498931885, 46.453824903220664]
	}, {"id": 43633619010, "name": "Raina", "coords": [11.119129657745361, 46.459471726296954]}, {
		"id": 13017950670,
		"name": "Dovena",
		"coords": [11.130309104919434, 46.454105779986804]
	}, {"id": 12159595050, "name": "Carnalez", "coords": [11.101899147033691, 46.44199719844781]}, {
		"id": 12159596810,
		"name": "Traversara",
		"coords": [11.102564334869385, 46.43476624919006]
	}, {"id": 8593529470, "name": "Arsio", "coords": [11.096534729003906, 46.42709063371228]}, {
		"id": 647768050,
		"name": "Cloz",
		"coords": [11.086878776550293, 46.41891099458232]
	}, {
		"id": 43644933950,
		"name": "Santa Maria",
		"coords": [11.084067821502686, 46.41647017738006]
	}, {"id": 57903756600, "name": "Saorì", "coords": [11.09419584274292, 46.40257762845144]}, {
		"id": 57968218160,
		"name": "San Bartolomeo",
		"coords": [11.109366416931152, 46.39445350855368]
	}, {"id": 647768140, "name": "Don", "coords": [11.136617660522461, 46.38965837841209]}, {
		"id": 647765290,
		"name": "Ruffrè",
		"coords": [11.177773475646973, 46.41481331799744]
	}, {
		"id": 26210952260,
		"name": "Villini dell'Alpe",
		"coords": [11.187107563018799, 46.41811219370703]
	}, {
		"id": 27393627770,
		"name": "Mendola – Mendel",
		"coords": [11.206204891204834, 46.41755006755767]
	}, {"id": 647767330, "name": "Ronzone", "coords": [11.14889144897461, 46.42306752876945]}, {
		"id": 647766230,
		"name": "Malosco",
		"coords": [11.143527030944824, 46.43652601639374]
	}, {"id": 12159703040, "name": "Tret", "coords": [11.138012409210205, 46.478300091755045]}, {
		"id": 50367829460,
		"name": "Plaze",
		"coords": [11.1476469039917, 46.483116978630704]
	}, {
		"id": 15897932620,
		"name": "Cima Monticello",
		"coords": [11.12166166305542, 46.24930345287805]
	}, {"id": 647769320, "name": "Mezzocorona", "coords": [11.120781898498535, 46.2154316603835]}, {
		"id": 8892731040,
		"name": "Grumino",
		"coords": [11.124601364135742, 46.19825028922486]
	}, {"id": 8892759980, "name": "Grumo", "coords": [11.12917184829712, 46.19559167140284]}, {
		"id": 12909826810,
		"name": "Cadino",
		"coords": [11.15288257598877, 46.217822073484655]
	}, {"id": 647768200, "name": "Faedo", "coords": [11.16187334060669, 46.19229421250833]}, {
		"id": 8909017890,
		"name": "Sorni",
		"coords": [11.123249530792236, 46.17559593334738]
	}, {
		"id": 10715862740,
		"name": "Sorni Vecchia",
		"coords": [11.125438213348389, 46.17208924470273]
	}, {
		"id": 647769790,
		"name": "Nave San Rocco",
		"coords": [11.107263565063477, 46.16635324658509]
	}, {
		"id": 8645505320,
		"name": "Nave San Felice",
		"coords": [11.11168384552002, 46.163425576585695]
	}, {"id": 647769430, "name": "Zambana", "coords": [11.09621286392212, 46.152040363758914]}, {
		"id": 6725515100,
		"name": "Gresta",
		"coords": [11.291306018829346, 46.211289021089385]
	}, {"id": 2600028520, "name": "Dorà", "coords": [11.355721950531006, 46.25215236106814]}, {
		"id": 2534596780,
		"name": "Cela",
		"coords": [11.417477130889893, 46.292673917431244]
	}, {"id": 26000024070, "name": "Pozza", "coords": [11.344349384307861, 46.25157368856836]}, {
		"id": 9569560930,
		"name": "Via della Villa Alta",
		"coords": [11.288559436798096, 46.22175637777309]
	}, {
		"id": 647767680,
		"name": "Roverè della Luna",
		"coords": [11.16934061050415, 46.249986017278275]
	}, {
		"id": 3082277970,
		"name": "Monte di Mezzocorona",
		"coords": [11.12267017364502, 46.22484423800367]
	}, {"id": 9569265320, "name": "Masen", "coords": [11.167259216308594, 46.1765914355594]}, {
		"id": 5461562650,
		"name": "Ville",
		"coords": [11.156744956970215, 46.167720429964646]
	}, {"id": 4127753650, "name": "Serci", "coords": [11.140201091766357, 46.166085750210186]}, {
		"id": 10715859400,
		"name": "Maso Zancanar",
		"coords": [11.12091064453125, 46.16535755904391]
	}, {
		"id": 647768650,
		"name": "Fai della Paganella",
		"coords": [11.068940162658691, 46.179325259375986]
	}, {"id": 43644933960, "name": "Santo Stefano", "coords": [11.089099645614624, 46.42068606574179]},

		{"id": 12159594800, "name": "Vasio", "coords": [11.123013496398926, 46.430462713523895]}, {
		"id": 8583886600,
		"name": "Seio",
		"coords": [11.126307249069214, 46.41847461407292]
	}, {
		"id": 43593779310,
		"name": "Maso Morini",
		"coords": [11.106330156326294, 46.440762533741434]
	}, {"id": 12159595210, "name": "Rivo", "coords": [11.106051206588745, 46.4317715567216]}, {
		"id": 50078516150,
		"name": "Miauneri",
		"coords": [11.061161756515503, 46.445834091607594]
	}, {"id": 12159204790, "name": "Mione", "coords": [11.029704809188843, 46.43575704948023]}
		, {
		"id": 24288735800,
		"name": "Località Vernach",
		"coords": [10.852625370025635, 46.324141980884264]
	}, {"id": 9316305790, "name": "Todescat", "coords": [11.266651153564453, 46.22157822669158]}, {
		"id": 9316265010,
		"name": "Ferrai",
		"coords": [11.27073884010315, 46.21972245188624]
	}, {"id": 9316258820, "name": "Orion", "coords": [11.273775100708008, 46.22045734621054]}, {
		"id": 9316257650,
		"name": "Pinteri",
		"coords": [11.27469778060913, 46.21841594880047]
	}, {"id": 9325743650, "name": "Teaio", "coords": [11.256405115127563, 46.19386127282499]}, {
		"id": 2600075510,
		"name": "Montesover",
		"coords": [11.327118873596191, 46.216433864879576]
	}, {"id": 9569561210, "name": "Piazzoli", "coords": [11.323825120925903, 46.2143477741935]}, {
		"id": 12398995000,
		"name": "Maso Lio",
		"coords": [11.346205472946167, 46.262011111935635]
	}, {"id": 2600028560, "name": "Barcatta", "coords": [11.350443363189697, 46.250675992223705]}, {
		"id": 34197162930,
		"name": "Alpe Cermis",
		"coords": [11.503404378890991, 46.250512773127895]
	}, {"id": 14355644760, "name": "Cainari", "coords": [11.710728406906128, 46.13889093095807]}, {
		"id": 49691463560,
		"name": "Ronco Chiesa",
		"coords": [11.71444058418274, 46.14147051899727]
	}, {"id": 14355644750, "name": "Pugnai", "coords": [11.721382141113281, 46.150464678787785]}, {
		"id": 14355644730,
		"name": "Gasperi",
		"coords": [11.71815276145935, 46.15217414625431]
	}, {"id": 14355644780, "name": "Fosse", "coords": [11.71967625617981, 46.15354168199883]}, {
		"id": 14355644740,
		"name": "Lausen",
		"coords": [11.735082864761353, 46.15012277892302]
	}, {
		"id": 647765840,
		"name": "Canal San Bovo",
		"coords": [11.731992959976196, 46.15599424250453]
	}, {"id": 49691000630, "name": "Somprà", "coords": [11.727980375289917, 46.158677069872226]}, {
		"id": 7487519120,
		"name": "Prade",
		"coords": [11.740232706069946, 46.15718332340509]
	}, {"id": 7487520770, "name": "Cicona", "coords": [11.743783950805664, 46.161508388463204]}, {
		"id": 7487521650,
		"name": "Zortea",
		"coords": [11.74989938735962, 46.16312091162024]
	}, {"id": 43325176710, "name": "Revedea", "coords": [11.746455430984497, 46.153489656804254]}, {
		"id": 7487522130,
		"name": "Gobbera",
		"coords": [11.758289337158203, 46.14758819413282]
	}, {"id": 7408647620, "name": "Masi di Imer", "coords": [11.784167289733887, 46.14479332604881]}, {
		"id": 6978580750,
		"name": "Pontet",
		"coords": [11.774178743362427, 46.107063595144126]
	}, {"id": 39029148280, "name": "Pezze", "coords": [11.797106266021729, 46.145618421548335]}, {
		"id": 647769370,
		"name": "Imer",
		"coords": [11.79630160331726, 46.15105928218961]
	}, {"id": 647766200, "name": "Mezzano", "coords": [11.807996034622192, 46.15470851987004]}, {
		"id": 39011602360,
		"name": "Collegio Santa Croce",
		"coords": [11.818445920944214, 46.163299252292575]
	}, {"id": 23713745610, "name": "Molarén", "coords": [11.818596124649048, 46.16784674410562]}, {
		"id": 39020376420,
		"name": "Navoi",
		"coords": [11.830612421035767, 46.16980086121336]
	}, {"id": 647768870, "name": "Transacqua", "coords": [11.833723783493042, 46.17362716322529]}, {
		"id": 8832489670,
		"name": "Pieve",
		"coords": [11.826192140579224, 46.1743775329804]
	}, {
		"id": 647768600,
		"name": "Fiera di Primiero",
		"coords": [11.829464435577393, 46.17709660889324]
	}, {"id": 8832489570, "name": "Ormanico", "coords": [11.838133335113525, 46.17691088394233]}, {
		"id": 39019656670,
		"name": "Tressane",
		"coords": [11.832972764968872, 46.17874581894014]
	}, {"id": 39020376390, "name": "Fol", "coords": [11.837950944900513, 46.17934011674279]}, {
		"id": 647766800,
		"name": "Tonadico",
		"coords": [11.84049367904663, 46.1816875302666]
	}, {"id": 647769050, "name": "Siror", "coords": [11.8307626247406, 46.188662322750844]}, {
		"id": 39207987210,
		"name": "Valmesta",
		"coords": [11.809186935424805, 46.226529126954404]
	}, {"id": 39213017580, "name": "Col", "coords": [11.81078553199768, 46.254007039473265]}, {
		"id": 2537767880,
		"name": "San Martino di Castrozza",
		"coords": [11.80282473564148, 46.26233748120006]
	}, {"id": 39011602350, "name": "Nolesca", "coords": [11.826449632644653, 46.18973186122949]}, {
		"id": 24917630670,
		"name": "Toè",
		"coords": [11.888279914855957, 46.19019235055407]
	}, {
		"id": 39451041910,
		"name": "Maso Cereda",
		"coords": [11.89243197441101, 46.191076182158724]
	}, {
		"id": 39451041920,
		"name": "Passo Cereda",
		"coords": [11.904995441436768, 46.19351964263109]
	}, {"id": 39451041900, "name": "Padreterno", "coords": [11.916335821151733, 46.19646056344408]},  {
		"id": 12901851090,
		"name": "Mis di Mezzo",
		"coords": [11.92892074584961, 46.20465123793022]
	}, {
		"id": 39450714030,
		"name": "Mis di Sopra",
		"coords": [11.92592740058899, 46.20665602208646]
	}, {
		"id": 39450714040,
		"name": "Mis di Sotto",
		"coords": [11.931184530258179, 46.20319586730207]
	}, {"id": 18606047040, "name": "Matiuz", "coords": [11.93589448928833, 46.19374987190815]}, {
		"id": 12901885430,
		"name": "Sagron",
		"coords": [11.943190097808838, 46.1948044582044]
	}, {"id": 18612628040, "name": "Pante", "coords": [11.947492361068726, 46.193059181183514]}, {
		"id": 16304705790,
		"name": "Marcoi",
		"coords": [11.955721378326416, 46.194908430405235]
	}, {"id": 39451041890, "name": "Vori", "coords": [11.952588558197021, 46.19791611248846]},
		{"id": 7487519760, "name": "Caoria", "coords": [11.681127548217773, 46.195101521113486]}, {
		"id": 26269431570,
		"name": "Marco",
		"coords": [11.009845733642578, 45.84345009320725]
	}

	]


export default settlement_data;
