export const DE = {
	'VALLO ALPINO': 'VALLO ALPINO',
	'hello': 'Hallo',
	'hi': 'Griaß di',
	'Completed' : 'Fertiggestellt',
	'Barrier': 'Sperre',
	'Elevation': 'Meereshöhe',
	'Latitude':'Breitengrad',
	'Longitude' : 'Längengrad',
	'no bunker selected': 'kein Bunker ausgewählt',
	'Document': 'Dokument',
	'Audio': 'Audio',
	'Ext. link': 'Ext. Link',
	'construction progress': 'Baufortschritt',
	'intro':'Intro',
	'background':'Hintergrund',
	'help':'Hilfe',
	'impressum':'Impressum',
	'planned':'geplante Bunker',
	'started':'begonnene Bunker',
	'built':'fertiggestellte Bunker',
	'started or built':'Bau fortgeschritten/geplant',
	'details available':'Details vorhanden',
	'timeslider':'mit Zeitleiste',
	'overview':'Gesamtübersicht',
	'quicktour' : 'Quicktour',
	'stop quicktour' : 'STOP Quicktour',
	'Fig' : 'Abb.: ',
	'next' : 'weiter',
	'start' : 'Starten',
	'range' : 'Waffenradius',
	'':'',
};

export const EN = {
	'VALLO ALPINO': 'VALLO ALPINO',
	'hello': 'hello',
	'hi': 'hi',
	'Completed': 'Completed',
	'Barrier': 'Barrier',
	'Elevation': 'Elevation',
	'Latitude' : 'Latitude',
	'Longitude' : 'Longitude',
	'no bunker selected': 'no bunker selected',
	'Document': 'Document',
	'Audio': 'Audio',
	'Ext. link': 'Ext. link',
	'construction progress': 'construction progress',
	'intro':'intro',
	'background':'background',
	'help':'help',
	'impressum':'Legal notice',
	'planned':'planned bunkers',
	'started':'started bunkers',
	'built':'completed bunkers',
	'started or built':'construction started or planned',
	'details available':'details available',
	'timeslider':'with timeslider',
	'overview':'overview',
	'quicktour' : 'Quicktour',
	'stop quicktour' : 'STOP Quicktour',
	'Fig' : 'Fig.: ',
	'next' : 'next',
	'start' : 'continue',
	'range' : 'Weapon radius',
	'':'',
};

export const IT = {
	'VALLO ALPINO': 'VALLO ALPINO',
	'hello': 'pronto',
	'hi': 'ciao',
	'Completed' : 'Completato',
	'Barrier': 'Sbarramento',
	'Elevation': 'Altitudine sul livello del mare',
	'Latitude' : 'Latitudine',
	'Longitude' : 'Longitudine',
	'no bunker selected': 'nessun bunker selezionato',
	'Document': 'Documento',
	'Audio': 'audio',
	'Ext. link': 'link esterno',
	'construction progress': 'Avanzamento lavori',
	'intro':'Introduzione',
	'background':'Retroscena',
	'help':'Aiuto',
	'impressum':'Impressum',
	'planned':'Bunker pianificati',
	'started':'Bunker iniziati',
	'built':'Bunker completati',
	'started or built':'Bunker pianificato o costruzione iniziata',
	'details available':'Dettagli disponibili',
	'timeslider':'Con cronometro',
	'overview':'Panoramica',
	'quicktour' : 'Quicktour',
	'stop quicktour' : 'STOP Quicktour',
	'Fig' : 'Fig.: ',
	'next' : 'prossimo',
	'start' : 'Avanti',
	'range' : 'Portata delle armi',
	'limit':'Sorry, service not available, connection limit exceeded for this month!',
	'':'',
};
