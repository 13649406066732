import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useStore} from "./store";
import {useSpring, animated, a} from 'react-spring';

export const Intro = () => {

	const language = useStore(state => state.language);

	const setShow = useStore(state => state.setShow);

	const handleClick = (e) => {
		e.stopPropagation()
		setShow('background')
	}

	switch (language) {
		case 'DE':
			return (
			<div lang={'de'}>
				<p>
					Das Landesmuseum Festung Franzensfeste heißt sie herzlich willkommen im Projekt zum „Vallo Alpino”, dem Alpenwall in Südtirol.
				</p>
				<p>
					Navigieren Sie durch die dreidimensionale Bunkerlandschaft, zoomen Sie ein oder klicken Sie auf die rot markierten
					Bunkersymbole, um Details einzusehen. Bedienen Sie die Zeitleiste am unteren Bildrand, um die Baugeschichte zu verfolgen
					oder blenden Sie Satellitenbilder ein, um sich besser im Gelände zu orientieren.
				</p>
				<img src={'images/slider.jpg'} style={{height: '60px'}}></img>
				<img src={'images/sat.jpg'} style={{height: '60px', paddingLeft:'20px'}}></img>
				<p>
					Sind Sie an einer Bunkertour interessiert? Mit einem Klick auf <i>Quicktour</i> begleiten wir Sie entlang einer
					animierten Route von Norden nach Süden durch alle Bunkerlinien.
				</p>
				<img src={'images/quicktour.jpg'} style={{width: '200px'}}></img>
				<p>
					Lesen Sie mehr zum Hintergrund des Projekts unter <i><u><span onClick={handleClick}> „VALLO ALPINO“</span></u></i>.
				</p>

			</div>
			)
		case 'IT':
			return (
				<div lang={'it'}>
					<p>
						Il Museo Provinciale Forte di Fortezza vi dà il benvenuto nel progetto dedicato al Vallo Alpino in Alto Adige.
					</p>
					<p>
						Navigate nel paesaggio di bunker tridimensionale, zoomate su un’opera o cliccate sul simbolo del bunker evidenziato in rosso per scoprirne i dettagli. Utilizzate la linea temporale nel margine inferiore dell’immagine per ripercorrere la storia della sua costruzione o visualizzate le foto satellitari per orientarvi meglio nel territorio.
					</p>
					<img src={'images/slider.jpg'} style={{height: '60px'}}></img>
					<img src={'images/sat.jpg'} style={{height: '60px', paddingLeft:'20px'}}></img>
					<p>

						Siete interessati a un tour tra i bunker? Cliccando su <i>Quicktour</i>, vi accompagneremo lungo un percorso animato da nord a sud, attraverso tutte le linee delle opere difensive.

					</p>
					<img src={'images/quicktour.jpg'} style={{width: '200px'}}></img>
					<p>
						Ulteriori informazioni sul contesto del progetto sono riportate sotto <i><u><span onClick={handleClick}> "VALLO ALPINO"</span></u></i>.
					</p>
				</div>
			)
		case 'EN':
			return (
				<div lang={'en'}>
					<p>
						The Provincial Museum of Franzensfeste Fortress welcomes you to its project dedicated to the “Vallo Alpino”, South Tyrol’s so-called Alpine Wall.
					</p>
					<p>
						Browse through the three-dimensional bunker landscape, zoom in or click on the red bunker symbols to view the details. Use the timeline at the bottom of the screen to follow the story of the construction or display satellite images to better orientate yourself in the terrain.
					</p>
					<img src={'images/slider.jpg'} style={{height: '60px'}}></img>
					<img src={'images/sat.jpg'} style={{height: '60px', paddingLeft:'20px'}}></img>
					<p>
						Are you interested in a bunker tour? Click on <i>Quicktour</i> and we will guide you along an animated route from north to south through each of the bunker lines.
					</p>
					<img src={'images/quicktour.jpg'} style={{width: '200px'}}></img>
					<p>
						Read more about the project's background under <i><u><span onClick={handleClick}>'VALLO ALPINO'</span></u></i>.
					</p>
				</div>
			)
		default: return null

	}

}

export const Impressum = () => {

	const language = useStore(state => state.language);

	switch (language) {
		case 'DE':
			return (
				<div lang={'de'}>
					<strong>Herausgeber</strong>
					<br/>
					Südtiroler Landesmuseum Festung Franzensfeste
					<p>
						<strong>
							Direktor
						</strong>
						<br/>
						Emanuel Valentin
						<br/>Landesmuseum Festung Franzensfeste
						<br/>Brennerstraße
						<br/>I–39045 Franzensfeste (BZ)
						<br/>T +39 0472 057218
						<br/>info@franzensfeste.info
						<br/>Mehrwertsteuernummer: 02383790215
					</p>
					<p>
						<strong>
							Wissenschaftliche Projektleitung
						</strong>
						<br/>
						Heimo Prünster, Eppan (I) / Wien (A)
					</p>
					<p>
						<strong>
							Idee und Konzept der Visualisierung
						</strong>
						<br/>Heimo Prünster, Eppan (I) / Wien (A)
						<br/>Florian Windhager, Wien
						<br/>Michael Smuc, mindfactor IT solutions eU, Vienna (A)
					</p>
					<p>
						<strong>
							Umsetzung und Programmierung
						</strong><br/>
						Michael Smuc, mindfactor IT solutions eU, Wien (A)
					</p>
					<p>
						<strong>
							Texte
						</strong><br/>
						Heimo Prünster, Eppan (I) / Wien (A)
					</p>
					<p>
						<strong>
							Übersetzungen ins Italienische und Englische
						</strong><br/>
						Bonetti & Peroni, Übersetzungsagentur, Bozen (I)
					</p>
					<p>
						<strong>
							Bildnachweise
						</strong><br/>
						Die Bilder mit der Kennzeichnung Sammlung Heimo Prünster, AUSSME M3-289, L1-22, M3-532, L1-217,
						L1-78, M3-299, L1-78, M3-462, M3-448, M3-387, M3-292 stammen vom Archivio dell’Ufficio Storico
						dello Stato Maggiore dell’Esercito und deren Nutzung wurde mit der Genehmigungsnummer M_D
						AE1C1B2 REG2023 0226712 23-11-2023 ermächtigt.
					</p>
					<p>
						Das Bild mit der Kennzeichnung Sammlung Heimo Prünster, Comando Truppe Alpine stammt vom Comando
						Truppe Alpine – SM Sottocapo di Stato Maggiore Supporto und dessen Nutzung wurde mit der
						Genehmigungsnummer M_D A773390 REG2023 0062873 27-11-2023 ermächtigt.
					</p>
					<p>
						Das Bild mit der Kennzeichnung Sammlung Heimo Prünster, BArch RH 11-III/ 437-447 stammt vom
						Bundesarchiv und dessen Nutzung wurde von der Abteilung Militärarchiv, Referat MA 5 am
						17/11/2023 genehmigt.
					</p>
					<p>
						<strong>
							Copyright
						</strong><br/>
						Alle Texte, Fotos und Informationen sind urheberrechtlich geschützt.
					</p>
					<p>
						<strong>
							Nutzungsbedingungen
						</strong><br/>
						Eine Vervielfältigung und Verwendung der Texte, Bilder, Grafiken oder dieser Internetseiten oder
						Teile davon in anderen elektronischen oder gedruckten Publikationen und deren Veröffentlichung
						ist nicht gestattet.
					</p>
					<p>
						<strong>
							Haftungsausschluss
						</strong><br/>
						Alle auf dieser Internetseite bereitgestellten Informationen wurden nach bestem Wissen und
						Gewissen erarbeitet und geprüft. Eine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit
						und Verfügbarkeit der bereit gestellten Informationen können wir allerdings nicht übernehmen.
					</p>
					<p>
						<strong>
							Datenschutz
						</strong><br/>
						<a href="https://www.franzensfeste.info/cookies-datenschutz/"
						   target="_blank">https://www.franzensfeste.info/cookies-datenschutz/</a>
					</p>

				</div>
			)
		case 'IT':
			return (
				<div lang={'it'}>
					<strong>
						Editore
					</strong><br/>
					Museo Provinciale Forte di Fortezza
					<p>
						<strong>
							Direttore
						</strong>
						<br/>
						Emanuel Valentin
						<br/>Museo Provinciale Forte di Fortezza
						<br/>Via Brennero
						<br/>I-39045 Fortezza (BZ)
						<br/>T +39 0472 057218
						<br/>info@fortezza.info
						<br/>Part. IVA 02383790215

					</p>

					<p>
						<strong>
							Gestione scientifica del progetto
						</strong>
						<br/>
						Heimo Prünster, Appiano (I) / Vienna (A)
					</p>

					<p>
						<strong>
							Idea e concetto della visualizzazione
						</strong>
						<br/>
						Heimo Prünster, Appiano (I) / Vienna (A)
						<br/>Florian Windhager, Wien
						<br/>Michael Smuc, mindfactor IT solutions eU, Vienna (A)

					</p>

					<p>
						<strong>
							Realizzazione e programmazione
						</strong>
						<br/>
						Michael Smuc, mindfactor IT solutions eU, Vienna (A)
					</p>

					<p>
						<strong>
							Testi
						</strong>
						<br/>
						Heimo Prünster, Appiano (I) / Vienna (A)
					</p>

					<p>
						<strong>
							Traduzioni in italiano e inglese
						</strong>
						<br/>
						Bonetti & Peroni, Agenzia di traduzioni, Bolzano (I)
					</p>

					<p>
						<strong>
							Crediti fotografici
						</strong>
						<br/>
						Le immagini contrassegnate Raccolta Heimo Prünster, AUSSME M3-289, L1-22, M3-532, L1-217,
						L1-78, M3-299, L1-78, M3-462, M3-448, M3-387, M3-292 provengono dall'Archivio dell'Ufficio
						Storico dello Stato Maggiore dell'Esercito e il loro utilizzo è stato autorizzato con il
						numero di concessione M_D AE1C1B2 REG2023 0226712 23-11-2023 dello Stato Maggiore
						dell’Esercito – Ufficio Storico.
					</p>
					<p>
						L'immagine contrassegnata con Collezione Heimo Prünster, Comando Truppe Alpine proviene dal
						Comando Truppe Alpine - SM Sottocapo di Stato Maggiore Supporto e il suo utilizzo è stato
						autorizzato con il numero di permesso M_D A773390 REG2023 0062873 27-11-2023.
					</p>
					<p>
						L'immagine contrassegnata con Collezione Heimo Prünster, BArch RH 11-III/ 437-447 proviene
						dal “Bundesarchiv” e il suo utilizzo è stato autorizzato dalla “Abteilung Militärarchiv,
						Referat MA5” (Ripartizione Archivi Militari, Divisione MA 5) il 17/11/2023.
					</p>

					<p>
						<strong>
							Copyright
						</strong>
						<br/>
						Tutti i testi, le foto e le informazioni sono protetti da copyright.
					</p>
					<p>
						<strong>
							Condizioni di utilizzo
						</strong>
						<br/>
						Non è consentita la riproduzione e l'utilizzo di testi, immagini, grafici o di queste pagine
						Internet o di parti di esse in altre pubblicazioni elettroniche o stampate e la loro
						pubblicazione.

					</p>
					<p>
						<strong>
							Dichiarazione di non responsabilità
						</strong>
						<br/>
						Tutte le informazioni fornite da questo sito web sono state inserite con la massima
						attenzione, al meglio delle nostre conoscenze e dopo essere state scrupolosamente
						verificate. Tuttavia, non possiamo garantire l’attualità, l’esattezza, la completezza delle
						informazioni disponibili.
					</p>

					<p>
						<strong>
							Privacy
						</strong>
						<br/>
						<a href="https://www.franzensfeste.info/it/cookies-privacy/"
						   target="_blank">https://www.franzensfeste.info/it/cookies-privacy/</a>
					</p>
				</div>
			)
		case 'EN':
			return (
				<div lang={'en'}>

					<strong>
						Editor
					</strong>
					<br/>
					Provincial Museum Franzensfeste Fortress
					<p>
						<strong>
							Director
						</strong>
						<br/>
						Emanuel Valentin
						<br/>Provincial Museum Franzensfeste Fortress
						<br/>Brennerstraße / Via Brennero
						<br/>I–39045 Franzensfeste / Fortezza (BZ)
						<br/>T +39 0472 057218
						<br/>info@franzensfeste.info
						<br/>VAT no.: 02564240212

					</p>
					<p>
						<strong>
							Scientific project management
						</strong>
						<br/>
						Heimo Prünster, Eppan/Appiano (I) / Vienna (A)
					</p>

					<p>
						<strong>
							Idea and concept of visualisation
						</strong>
						<br/>
						Heimo Prünster, Eppan / Appiano (I) / Vienna (A)
						<br/>Florian Windhager, Wien
						<br/>Michael Smuc, mindfactor IT solutions eU, Vienna (A)
					</p>

					<p>
						<strong>
							Implementation and programming
						</strong>
						<br/>
						Michael Smuc, mindfactor IT solutions eU, Vienna (A)
					</p>

					<p>
						<strong>
							Text
						</strong>
						<br/>
						Heimo Prünster, Eppan / Appiano (I) / Vienna (A)
					</p>

					<p>
						<strong>
							Italian and English translations
						</strong>
						<br/>
						Bonetti & Peroni, Translation agency, Bozen / Bolzano (I)
					</p>
					<p>
						<strong>
							Image credits
						</strong>
						<br/>
						The images marked Collection Heimo Prünster, AUSSME M3-289, L1-22, M3-532, L1-217, L1-78,
						M3-299, L1-78, M3-462, M3-448, M3-387, M3-292 come from the Archivio dell'Ufficio Storico dello
						Stato Maggiore dell'Esercito and their use has been authorised with the approval number M_D
						AE1C1B2 REG2023 0226712 23-11-2023 of the Stato Maggiore dell’Esercito – Ufficio Storico.
					</p>
					<p>
						The image marked Collection Heimo Prünster, Comando Truppe Alpine is from the Comando Truppe
						Alpine - SM Sottocapo di Stato Maggiore Supporto and its use has been authorised with permission
						number M_D A773390 REG2023 0062873 27-11-2023.
					</p>
					<p>
						The image marked Collection Heimo Prünster, BArch RH 11-III/ 437-447 comes from the
						“Bundesarchiv” and its use was authorised by the “Abteilung Militärarchiv, Referat MA5”
						(Military Archives Department, Division MA 5) on 17/11/2023.
					</p>
					<p>
						<strong>
							Copyright
						</strong>
						<br/>
						All texts, photos and information are subject to copyright.
					</p>
					<p>
						<strong>
							Conditions of use
						</strong>
						<br/>
						Reproduction and use of the texts, images, graphics or these Internet pages or parts thereof in
						other electronic or printed publications and their publication is not permitted.
					</p>
					<p>
						<strong>
							Disclaimer
						</strong>
						<br/>
						All information provided on this website has been compiled and checked to the best of our
						knowledge and belief. We cannot however guarantee that the information provided will be kept
						constantly up to date, correct, complete or available.
					</p>
					<p>
						<strong>
							Privacy policy
						</strong>
						<br/>
						<a href="https://www.franzensfeste.info/en/privacy-policy/"
						   target="_blank">https://www.franzensfeste.info/en/privacy-policy/</a>

					</p>

				</div>
			)
		default:
			return null

	}

}


export const Background = () => {

	const language = useStore(state => state.language);


	switch (language) {
		case 'DE':
			return (
				<div lang={'de'}>

					<h3>
						PROJEKTBESCHREIBUNG
					</h3>
					<p>
						Die Datenvisualisierung entstand im Rahmen des ersten Forschungsprojekts zum sog. „Vallo Alpino” und macht repräsentative Teile davon öffentlich zugänglich. Das Projekt entstand im Kontext der Ausstellung „Eingebunkert - Bunkerizzato”, die seit Mai 2022 permanent im Landesmuseum Festung Franzensfeste zu sehen ist (<a href="https://www.franzensfeste.info/vom-bunker-zur-ausstellung-eine-reise-durch-die-geschichte-der-bunker/" target="_blank">Link</a>).
					</p>
					<p>
						Das Ziel des Forschungsprojekts (2019-22) war der Aufbau einer ersten Wissensbasis zum „Vallo Alpino” auf dem Gebiet von Südtirol und einer ausbaufähigen Sammlung, die als Basis für weitere Forschungen zum Thema dienen soll. Weitere Schwerpunkte waren die internationale Vernetzung sowie die Entwicklung einer zeitgemäßen Datenvisualisierung für eine niederschwellige Kommunikation der Forschungsergebnisse.
					</p>
					<p>
						Im September 2021 wurden eine internationale Konferenz und ein ExpertInnen-Workshop abgehalten, die sich mit Fragen zur Zukunft des „Vallo Alpino” auseinandergesetzt haben. Die Beiträge aller Vortragenden, die Ergebnisse des Workshops sowie eine Beschreibung des Forschungsprojekts und seiner Ergebnisse können Sie in der Begleitpublikation nachlesen, die um weitere interessante Fachbeiträge ergänzt wurde.

						Hier können Sie die Publikation herunterladen:
						&nbsp;
						<a href="https://valloalpino.info/api/uploads/Vallo_Alpino_Franzensfeste_ANSICHT.pdf"
						   target="_blank">Vallo Alpino. The Future? The Future!</a>
					</p>

					<p>
						Die Datenvisualisierung wird fortlaufend mit neuen Inhalten und Forschungsergebnissen aktualisiert. Besuchen Sie uns bald wieder, um neue Details zum „Vallo Alpino” zu entdecken!
					</p>
					<h3>
						DER „VALLO ALPINO“ – DIE ITALIENISCHEN ALPENBEFESTIGUNGEN AUS DEM ZWEITEN WELTKRIEG
					</h3>
					<h4>
						Kurzbeschreibung
					</h4>
					<p>
						Der „Vallo Alpino” ist ein militärisches Verteidigungssystem an den italienischen Alpengrenzen und stammt aus der Zeit des Faschismus. Er reichte vom ligurischen Meer bis an die Adria und bestand aus tausenden Bunkern. Diese sind in drei Gürtel gegliedert und ausgehend von der Landesgrenze bis zu 80 km ins Landesinnere angeordnet.
					</p>
					<p>
						Die ersten Arbeiten begannen 1931 an den Grenzübergängen und dienten offensiven Militärstrategien: der Anschluss Österreichs ans Deutsche Reich sollte um jeden Preis verhindert werden, um es als Pufferstaat zu erhalten. Der Anschluss Österreichs im März 1938 führte zu einer radikalen Kehrtwende: der „Vallo Alpino” wird von einer Basis für Militäroperationen im benachbarten Österreich zu einem System der Landesverteidigung umfunktioniert. Die Anzahl der bis Ende 1938 fertiggestellten 26 Bunkeranlagen sollte bis auf ca. 1000 erhöht werden, bis zum Ende der Bauarbeiten im Oktober 1942 wurden aber nur 306 Bunkeranlagen fertiggestellt, weitere 135 Einzelwerke blieben unvollendet.

					</p>
					<h4>
						Alleinstellungsmerkmale
					</h4>
					<p>
						Das herausragendste Merkmal des „Vallo Alpino” ist der Bau von Verteidigungsanlagen dieses Ausmaßes an der Grenze zum Deutschen Reich: Hier baute Italien gegen den eigenen Verbündeten. Im Volksmund trug er deshalb den treffenden Namen <strong>„Linea non mi fido“</strong> – was mit ‚Linie des Misstrauens‘ übersetzt werden könnte.
					</p>
					<p>
						Die Alpenbefestigungen kamen <strong>nie zum Einsatz</strong>, Südtirol blieb von Kriegshandlungen, Verwüstungen und all ihren traumatischen Folgen verschont. Auch die Besetzung Südtirols bzw. Norditaliens durch Hitler-Deutschland am 8. September 1943 verlief beinahe kampflos.
					</p>
					<p>
						Im Zuge der Errichtung des Verteidigungswalls gab es <strong>keine Zwangsarbeit</strong>: die Bauarbeiten wurden von zivilen italienischen Baufirmen durchgeführt, die das Vertrauen des Regimes genossen.
					</p>
					<p>
						Ein weiteres Spezifikum des Alpenwalls ist seine <strong>Unvollendetheit</strong>: ca. 25% der Bunkeranlagen blieben unvollendet. Noch heute harren 135 verwaiste Baustellen in der Südtiroler Landschaft ihres Daseins. Die Gründe dafür lagen in den unzureichenden finanziellen Mitteln und deren ineffizienter Verwendung, Knappheit an Rohstoffen, kriegsbedingtem Arbeitermangel und nicht zuletzt den völlig überdimensionierten Planungen, denen unklare Richtlinien zugrunde lagen.
					</p>
					<p>
						Der Umfang der Bauarbeiten erforderte einen außergewöhnlichen Materialaufwand: Allein in Südtirol wurden ca. <strong>1,7 Mio Kubikmeter</strong> Beton vergossen.
					</p>

					<p>
						Die <strong>Baukosten</strong> der in Südtirol realisierten Arbeiten beliefen sich auf ca. 1 Milliarde Lire, heute ca. 600 Mio €.
					</p>

					<p>
						Der überwiegende Teil der Bauarbeiten wurde unter strenger Geheimhaltung und in wenigen Monaten im Jahr 1940 durchgeführt. Die Baustellen waren im alpinen Gebiet kaum vor den Blicken zu verbergen. Im <strong>Herbst 1942 wurden die Bautätigkeiten eingestellt</strong> - infolge politischen Drucks Deutschlands, das bestens über die Tätigkeiten informiert war. Im Verborgenen liefen die Planungen jedoch weiter.
					</p>

					<p>
						Nach Einstellung der Arbeiten 1942 analysierte die Heeresspitze den Zustand des Verteidigungswalls und kam zum Schluss, dass der Bunkerwall im aktuellen Zustand die <strong>Sicherung der Grenze zu Deutschland in keiner Weise gewährleisten konnte</strong>.
					</p>

					<p>
					Erfahren Sie in den <strong>Zeitzeugen-Interviews</strong> mehr Details zum Zeitgeschehen, den Baufirmen, ihren Arbeitern und vielem mehr. Die Befragungen umfassten Personen aus sehr unterschiedlichen Altersgruppen, um die Unterschiede in der Rezeption der Bauten in verschiedenen Generationen zu erkunden: Ältere Menschen erlebten den Bau, den Faschismus und die militärische Nutzung noch selbst. Negative, emotionale Konnotationen sind hier häufiger als in jüngeren Generationen, die Bunker bereits vermehrt als abenteuerliche Freiräume wahrnehmen, in denen auch illegale Raves ausgetragen werden.

					</p>

					<p>
					Hier können Sie die Interviews einzeln abrufen. Neben dem Namen der oder des Interviewten ist das Geburtsjahr angegeben. Die Interviews sind nur in der Originalsprache verfügbar.

					</p>

					<ul>
						<li>
							<a href="https://valloalpino.info/api/uploads/1923_Rosa_Oberhollenzer_Niedermair.pdf"
							   target="_blank">1923 Rosa Oberhollenzer Niedermair</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1925_Matthias_Mair.pdf"
							   target="_blank">1925 Matthias Maier</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1929_Richard_Amort.pdf"
							   target="_blank">1929 Richard Amort</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1929_Karl_Weger.pdf"
							   target="_blank">1929 Karl Weger</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1931_M_B_und_1935_Notburga_Bacher.pdf"
							   target="_blank">1931 M. B. & 1935 Notburga Bacher</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1931_Franz_und_1933_Urban_Amort.pdf"
							   target="_blank">1931 Franz Amort & 1933 Urban Amort</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1932_Johann_Lercher.pdf"
							   target="_blank">1932 Johann Lercher</a>
						</li>
						<li>
						<a href="https://valloalpino.info/api/uploads/1933_Franz_Pichler.pdf"
						   target="_blank"></a>1933 Franz Pichler
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1933_Ivo_Hechensteiner.pdf"
							   target="_blank">1933 Ivo Hechensteiner</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1934_Bruno_Dorigatti.pdf"
							   target="_blank">1934 Bruno Dorigatti</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1935_S__M.pdf"
							   target="_blank">1935 S. M.</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1936_Irma_Pramstaller-Oberhammer.pdf"
							   target="_blank">1936 Irma Oberhammer</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1936_A__S.pdf"
							   target="_blank">1936 A. S.</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1937_Peter_von_Hellberg.pdf"
							   target="_blank">1937 Peter Hellberg</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1938_Bruno_Hosp.pdf"
							   target="_blank">1938 Bruno Hosp</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1938_Engelbert_Taferner.pdf"
							   target="_blank">1938 Engelbert Taferner</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1946_Leopold_Steurer.pdf"
							   target="_blank">1946 Leopold Steurer</a>
						</li>
						<li>1947 Hans Berger
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1948_Helmut_Stampfer.pdf"
							   target="_blank">1948 Helmut Stampfer</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1953_Hermes_Vigna.pdf"
							   target="_blank">1953 Hermes Vigna</a>

						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1972_Juliane_Stocker.pdf"
							   target="_blank">1972 Juliane Stocker</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1974_Esther_Stocker.pdf"
							   target="_blank">1974 Esther Stocker</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1984_Hannes_Goetsch.pdf"
							   target="_blank">1984 Hannes Götsch</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1997_Tobias_Joos.pdf"
							   target="_blank">1997 Tobias Joos</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/2002_Nikolai_Plangger.pdf"
							   target="_blank">2002 Nikolai Plangger</a>
						</li>
					</ul>


					<h3>
					PROJEKTDATEN
					</h3>

					<p>
					Titel: Die italienischen Alpenbefestigungen aus der Zeit des II. Weltkriegs – der sog. „VALLO ALPINO LITTORIO“
					</p>

					<p>
					Budget: Mittel des Forschungsfonds des Betriebs Landesmuseen und des Landesmuseums Festung Franzensfeste, insgesamt ca. € 130.000
					</p>

					<p>
					Projektleitung: Mag. Esther Erlacher, Mitarbeiterin des Landesmuseums Festung Franzensfeste
					</p>

					<p>
					Wissenschaftliche Leitung: Arch. Heimo Prünster
					</p>

					<p>
					Beratung und Konzeption Workshop: Prof. Dr. Gennaro Postiglione
					</p>

					<p>
					Recherche Zeitgeschichte: Dr. Giuseppe Spagnulo
					</p>

					<p>
					Beratung Zeitgeschichte: Prof. Dr. Andrea Di Michele, Dr. Giuseppe Spagnulo
					</p>

					<p>
					Beratung Datenorganisation: Bauforschung Tirol (Dipl.-Ing. Mag phil. Barbara Lanz und Dipl.-Ing. Sonja Mitterer), Dipl.-Ing. Katharina Kranewitter
					</p>

					<p>
					Datenvisualisierung: Dr. Florian Windhager (Beratung, Konzept, Design), Mindfactor - Mag. Michael Smuc (Beratung, Konzept, Design, Softwareentwicklung)
					</p>

					<p>
					Transkription Zeitzeugeninterviews: Dipl.-Ing. Katharina Kranewitter
					</p>

					<p>
					Assistenz Archivrecherchen und Datenorganisation: Florian Aufschnaiter
					</p>

					<p>
					Grafik: Landesmuseum Festung Franzensfeste, Benjamin Tomasi
					</p>

					<p>
					Übersetzungen: Bonetti & Peroni
					</p>

					<h4>
					Konsultierte Archive und Sammlungen (Auswahl):
					</h4>

					<p>
					Archivio Ufficio Storico – Stato Maggiore Esercito, Rom
					</p>

					<p>
					Istituto di Storia e di Cultura dell’Arma del Genio, Rom
					</p>

					<p>
					Archivio Centrale dello Stato, Rom
					</p>

					<p>
					Archivio Storico-diplomatico del Ministero degli Affari Esteri, Rom
					</p>

					<p>
					Comando Truppe Alpine, Bozen
					</p>

					<p>
					Bayerische Staatsbibliothek, München
					</p>

					<p>
					Sammlung Heimo Prünster
					</p>

					<p>
					Archiv des Landesamts für Vermögensverwaltung
					</p>

					<p>
					Landesbibliothek Tessmann – Digital
					</p>






				</div>
			)
		case 'IT':
			return (
				<div lang={'it'}>

					<h3>DESCRIZIONE DEL PROGETTO</h3>

					<p>
						La visualizzazione dei dati ha preso forma nell’ambito del primo progetto di ricerca sul Vallo Alpino, rendendone pubblicamente accessibili parti rappresentative. Il progetto affonda le sue radici nel contesto della mostra permanente “Eingebunkert – Bunkerizzato”, che può essere visitata presso il Museo Provinciale Forte di Fortezza dal maggio del 2022.
						 (<a href="https://www.franzensfeste.info/it/dal-bunker-alla-mostra-un-viaggio-nella-storia-dei-bunker/" target="_blank">Link</a>).
					</p>
					<p>
						Il progetto di ricerca (2019-22) aveva come obiettivo la creazione di un primo patrimonio di conoscenze di base sul Vallo Alpino in Alto Adige e di una raccolta scalabile, che intende fungere da punto di partenza per nuove attività di ricerca sul tema.  Ulteriori elementi chiave hanno coinciso con l’integrazione internazionale e lo sviluppo di una visualizzazione dei dati conforme ai tempi per una comunicazione a bassa soglia degli esiti della ricerca.
					</p>
					<p>
						Nel settembre 2021, si sono svolti una conferenza internazionale e un workshop di esperti, che si sono confrontati con la questione del futuro del Vallo Alpino. I contributi di tutti i relatori, le conclusioni del seminario, così come una descrizione del progetto di ricerca e dei suoi esiti possono essere consultati nella pubblicazione, integrata con ulteriori interessanti articoli tecnici. Il documento può essere scaricato al seguente link:
						&nbsp;
						<a href="https://valloalpino.info/api/uploads/Vallo_Alpino_Franzensfeste_ANSICHT.pdf"
						   target="_blank">Vallo Alpino. The Future? The Future!</a>
					</p>
					<p>
						La visualizzazione dei dati viene costantemente aggiornata con nuovi contenuti ed esiti dell’attività di ricerca. Tornate presto a trovarci per scoprire nuovi dettagli sul Vallo Alpino.
					</p>

					<h3>IL VALLO ALPINO – LA FORTIFICAZIONE ALPINA ITALIANA DELLA SECONDA GUERRA MONDIALE</h3>

					<h4>Breve descrizione</h4>
					<p>Il Vallo Alpino è un sistema di difesa militare posto sul confine alpino italiano, risalente
						all’epoca del fascismo. Composto da migliaia di bunker, si estendeva dal Mar Ligure
						all’Adriatico. Le opere erano suddivise in tre cinture e, partendo dalla frontiera, si
						addentravano sino a 80 km nell’interno del Paese.
					</p>
					<p>I primi lavori, iniziati nel 1931 in corrispondenza dei valichi di confine, erano finalizzati a
						una strategia militare offensiva: l’annessione dell’Austria al Reich doveva essere evitata a
						ogni costo, al fine di preservarne la funzione di Stato cuscinetto. L’Anschluss del 1938 innescò
						un radicale cambio di passo: il Vallo Alpino, da base per le operazioni militari nella vicina
						Austria, venne convertito in un sistema di difesa nazionale. Il numero dei bunker realizzati a
						fine 1938 (26) doveva essere incrementato, raggiungendo il migliaio. Tuttavia, al termine dei
						lavori, nell’ottobre 1942, solo 306 opere risultavano ultimate, mentre altre 135 rimasero
						incompiute.</p>


					<h4>Caratteristiche distintive</h4>
					<p>La caratteristica più rilevante del Vallo Alpino risiede nella creazione di un sistema di
						fortificazioni di tale entità al confine con il Reich: qui, l’Italia ha realizzato opere di
						difesa contro il proprio alleato che, nel linguaggio popolare, assunsero il nome di
						<strong>“Linea non mi fido”</strong>.
					</p>
					<p>Le fortificazioni alpine non sono <strong>mai state utilizzate</strong>. L’Alto Adige fu risparmiato dalle
						operazioni belliche, dalle devastazioni e da tutte le sue traumatiche conseguenze. Anche
						l’occupazione dell’Alto Adige e del Nord Italia da parte della Germania di Hitler, l’8 settembre
						1943, non incontrò di fatto resistenza.
					</p>
					<p>Nell’ambito della costruzione del vallo difensivo, non fu previsto il <strong>lavoro forzato</strong>. Le
						opere furono realizzate da imprese edili civili italiane, che godevano della fiducia del regime.
					</p>
					<p>Un’ulteriore specificità del Vallo Alpino è la sua <strong>incompiutezza</strong>: circa il 25% dei bunker non è
						stato completato. Tutt’oggi, 135 cantieri abbandonati nel paesaggio altoatesino reclamano la
						loro esistenza. I motivi sono riconducibili alle risorse finanziarie insufficienti e al loro
						inefficiente
						utilizzo, alla penuria di materie prime, alla carenza di forza lavoro dovuta alla guerra e, non
						in ultimo, a una progettazione ampiamente sovradimensionata, basata su direttive poco chiare.
					</p>
					<p>L’entità dei lavori richiedeva un impiego di materiali eccezionale: solo in Alto Adige, sono
						stati posti in opera <strong>1,7 milioni di metri cubi di calcestruzzo</strong>.
					</p>
					<p><strong>I costi dei lavori</strong> realizzati in Alto Adige ammontavano a circa 1 miliardo di lire, pari a circa
						600 milioni di euro.
					</p>
					<p>La parte preponderante dei lavori si è svolta nel massimo riserbo e nell’arco di pochi mesi nel
						1940.
					</p>
					<p>
						Nell’area alpina, raramente i cantieri erano al riparo da sguardi indiscreti. <strong>L’attività
						costruttiva venne interrotta nell’autunno del 1942</strong>, in seguito alla pressione politica della
						Germania, che era ben informata su quanto stava accadendo. Tuttavia, la progettazione proseguì
						in segreto.
					</p>
					<p>Dopo l’interruzione dei lavori nel 1942, i vertici militari analizzarono lo stato del vallo
						difensivo, giungendo alla conclusione che l’opera <strong>non potesse in alcun modo garantire la
							sicurezza del confine con la Germania</strong>.
					</p>


					<p>
						Le interviste ai <strong>testimoni dell’epoca</strong> svelano maggiori dettagli sugli avvenimenti dell’epoca, ma
						anche sulle imprese costruttive e la loro manodopera. Al fine di appurare le differenze nella
						percezione delle opere tra le diverse generazioni, le interviste hanno coinvolto persone di
						varie fasce di età: tra i più anziani, che hanno vissuto in prima persona il fascismo, la
						costruzione dei bunker e il loro utilizzo militare, le connotazioni emozionali negative emergono
						molto più frequentemente rispetto alla popolazione giovane, che percepisce i bunker come
						avventurosi spazi liberi, in cui organizzare anche rave illegali.
					</p>
					<p>
						Qui potete richiamare le singole interviste. Unitamente al nome, è riportato anche l’anno di
						nascita dell’intervistato/a. Le interviste sono disponibili unicamente nella lingua originale.
					</p>

					<ul>
						<li>
							<a href="https://valloalpino.info/api/uploads/1923_Rosa_Oberhollenzer_Niedermair.pdf"
							   target="_blank">1923 Rosa Oberhollenzer Niedermair</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1925_Matthias_Mair.pdf"
							   target="_blank">1925 Matthias Maier</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1929_Richard_Amort.pdf"
							   target="_blank">1929 Richard Amort</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1929_Karl_Weger.pdf"
							   target="_blank">1929 Karl Weger</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1931_M_B_und_1935_Notburga_Bacher.pdf"
							   target="_blank">1931 M. B. e 1935 Notburga Bacher</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1931_Franz_und_1933_Urban_Amort.pdf"
							   target="_blank">1931 Franz Amort e 1933 Urban Amort</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1932_Johann_Lercher.pdf"
							   target="_blank">1932 Johann Lercher</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1933_Franz_Pichler.pdf"
							   target="_blank"></a>1933 Franz Pichler
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1933_Ivo_Hechensteiner.pdf"
							   target="_blank">1933 Ivo Hechensteiner</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1934_Bruno_Dorigatti.pdf"
							   target="_blank">1934 Bruno Dorigatti</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1935_S__M.pdf"
							   target="_blank">1935 S. M.</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1936_Irma_Pramstaller-Oberhammer.pdf"
							   target="_blank">1936 Irma Oberhammer</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1936_A__S.pdf"
							   target="_blank">1936 A. S.</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1937_Peter_von_Hellberg.pdf"
							   target="_blank">1937 Peter Hellberg</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1938_Bruno_Hosp.pdf"
							   target="_blank">1938 Bruno Hosp</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1938_Engelbert_Taferner.pdf"
							   target="_blank">1938 Engelbert Taferner</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1946_Leopold_Steurer.pdf"
							   target="_blank">1946 Leopold Steurer</a>
						</li>
						<li>1947 Hans Berger
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1948_Helmut_Stampfer.pdf"
							   target="_blank">1948 Helmut Stampfer</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1953_Hermes_Vigna.pdf"
							   target="_blank">1953 Hermes Vigna</a>

						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1972_Juliane_Stocker.pdf"
							   target="_blank">1972 Juliane Stocker</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1974_Esther_Stocker.pdf"
							   target="_blank">1974 Esther Stocker</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1984_Hannes_Goetsch.pdf"
							   target="_blank">1984 Hannes Götsch</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1997_Tobias_Joos.pdf"
							   target="_blank">1997 Tobias Joos</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/2002_Nikolai_Plangger.pdf"
							   target="_blank">2002 Nikolai Plangger</a>
						</li>
					</ul>

					<h3>
						DATI PROGETTUALI
					</h3>
					<p>
					Titolo: Il sistema italiano di fortificazioni alpine della Seconda Guerra Mondiale, il cosiddetto “VALLO ALPINO LITTORIO (VAL)”
					</p>
					<p>
					Budget: risorse del fondo per la ricerca di Azienda Musei provinciali e Museo Provinciale Forte di Fortezza, per un totale di ca. € 130.000
					</p>
					<p>
					Direzione progettuale: Esther Erlacher, collaboratrice del Museo Provinciale Forte di Fortezza
					</p>
					<p>
					Direzione scientifica: Heimo Prünster
					</p>
					<p>
					Consulenza e concezione workshop: Gennaro Postiglione
					</p>
					<p>
					Consulenza storia contemporanea: Andrea Di Michele, Giuseppe Spagnulo
					</p>
					<p>
					Ricerche relative alla storia contemporanea: Giuseppe Spagnulo
					</p>
					<p>
					Consulenza organizzazione dati: Bauforschung Tirol (Barbara Lanz e Sonja Mitterer), Katharina Kranewitter
					</p>
					<p>
					Visualizzazione dati: Florian Windhager (consulenza, concept, design), Mindfactor - Michael Smuc (consulenza, concept, design, sviluppo software)
					</p>
					<p>
					Trascrizione interviste ai testimoni dell’epoca: Katharina Kranewitter
					</p>
					<p>
					Assistenza ricerche d’archivio e organizzazione dati: Florian Aufschnaiter
					</p>
					<p>
					Traduzioni: Bonetti & Peroni
					</p>

					<h4>
					Archivi e raccolte consultate (selezione):
					</h4>

					<p>
					Archivio Ufficio Storico – Stato Maggiore Esercito, Roma
					</p>
					<p>
					Istituto di Storia e di Cultura dell’Arma del Genio, Roma
					</p>
					<p>
					Archivio Centrale dello Stato, Roma
					</p>
					<p>
					Archivio Storico-diplomatico del Ministero degli Affari Esteri, Roma
					</p>
					<p>
					Comando Truppe Alpine, Bolzano
					</p>
					<p>
					Bayerische Staatsbibliothek, Monaco
					</p>
					<p>
					Raccolta Heimo Prünster
					</p>
					<p>
					Archivio della Ripartizione Amministrazione del patrimonio della Provincia Autonoma Bolzano – Alto Adige
					</p>
					<p>
					Biblioteca provinciale Tessmann – Digital
					</p>

				</div>
			)
		case 'EN':
			return (
				<div lang={'en'}>
					<h3>
						PROJECT DESCRIPTION
					</h3>
					<p>
						The data visualisation arose as part of the first research project into the so-called “Vallo
						Alpino”, with the aim of making representative parts of it accessible to the public. The project
						was developed in the context of the exhibition “Eingebunkert – Bunkerizzato – Bunkered”, since
						May 2022 a permanent display at the Provincial Museum of Franzensfeste Fortress:
						(<a href="https://www.franzensfeste.info/vom-bunker-zur-ausstellung-eine-reise-durch-die-geschichte-der-bunker/" target="_blank">Link</a>)
					</p>

					<p>
						The aim of the research project (2019-22) was to develop an initial knowledge base for the
						“Vallo Alpino” in South Tyrol and thus create an expandable collection to serve as a basis for
						further research into the topic. Other focal points were international networking and the
						development of a contemporary data visualisation for a low-threshold communication of the
						research results.
					</p>

					<p>
						An international conference and expert workshop were held In September 2021 to address questions
						on the future of the “Vallo Alpino”. The contributions of each speaker, the results of the
						workshop and a description of the research project with its results can be read in the
						accompanying publication, which has been supplemented with further expert contributions.
						The publication can be downloaded here:
						&nbsp;
						<a href="https://valloalpino.info/api/uploads/Vallo_Alpino_Franzensfeste_ANSICHT.pdf"
						   target="_blank">Vallo Alpino. The Future? The Future!</a>
					</p>

					<p>
						The data visualisation is continuously updated with new content and research results. Come back
						soon to discover new details about the “Vallo Alpino”!
					</p>

					<h3>
						THE “VALLO ALPINO” – THE ITALIAN ALPINE FORTIFICATIONS OF THE SECOND WORLD WAR
					</h3>

					<h4>
						Brief description
					</h4>

					<p>
						The “Vallo Alpino” is a military defensive system located on Italy’s Alpine frontiers that dates
						back to the Fascist era. It stretched from the Ligurian to the Adriatic Sea and consisted of
						thousands of bunkers, divided into three belts that were situated from the national border up to
						80 km inland.
					</p>
					<p>
						The first works began in 1931 at the border crossings and were intended for offensive military
						strategies: the annexation of Austria to the German Reich was to be prevented at all costs so as
						to preserve the former as a buffer state. The Anschluss of Austria in March 1938 led to a
						radical turnaround: the “Vallo Alpino” was transformed from a base in support of military
						operations in neighbouring Austria into a system of national defence. The 26 bunkers completed
						by the end of 1938 were to be increased to approximately 1,000 but, when construction ended in
						October 1942, only 306 bunkers had been completed, while another 135 individual works remained
						unfinished.
					</p>

					<h4>
						Particular features
					</h4>

					<p>
						The most notable feature of the “Vallo Alpino” is the building of defensive works of this scale
						by Italy on the border with the German Reich, its ally at the time. It was therefore popularly
						and aptly named the <strong>“Linea non mi fido”</strong> – the “Line of Mistrust”.

					</p>

					<p>
						The Alpine fortifications were <strong>never used</strong> and South Tyrol was spared fighting, devastation and
						all the consequent trauma. The occupation of South Tyrol and northern Italy by Hitler’s forces
						took place practically without resistance on 8 September 1943.
					</p>
					<p>
						<strong>No forced labour</strong> was used in the construction of the defensive wall: the works were carried out
						by Italian civilian contractors that enjoyed the confidence of the Fascist regime.
					</p>
					<p>
						Another particular feature of the Alpine Wall is its <strong>incompleteness</strong>: some 25% of the bunkers
						remained unfinished. Even today, 135 abandoned construction sites remain in the South Tyrolean
						landscape. The reasons were insufficient financial resources and their inefficient use; scarcity
						of raw materials; a shortage of workers due to the war effort; and, last but not least, utterly
						outsized planning based upon unclear guidelines.
					</p>
					<p>
						The construction works required extraordinary amounts of material: South Tyrol alone saw the
						casting of some <strong>1.7 million cubic metres of concrete</strong>.
					</p>
					<p>
						The <strong>construction costs</strong> of the works in South Tyrol amounted to around one billion lire,
						approximately € 600 million at today’s prices.
					</p>
					<p>
						Most of the construction work was carried out under conditions of strict secrecy during a few
						months in 1940. The construction sites could hardly be hidden from view in the Alpine region.
					</p>
					<p>
						<strong>Construction activities</strong> ceased in autumn 1942 following political pressure from Germany, which
						was well informed about these activities. Planning continued in secret, however.
					</p>
					<p>
						After work stopped in 1942, the army’s top brass analysed the condition of the defensive wall
						and concluded that, in its current state, the bunker wall <strong>could in no way guarantee the
						security of the border with Germany</strong>.
					</p>
					<p>
						The <strong>eyewitness interviews</strong> offer more details about contemporary events, the construction firms,
						their workers and much more besides. The interviews include people from very different age
						groups so as to explore the differences in how the structures are perceived by different
						generations: older people experienced the construction, the Fascist regime and the military use
						of the bunkers themselves, with negative emotional connotations thus more common among them than
						younger generations, who increasingly see the bunkers as open spaces to explore and places where
						illegal raves can also be held.
					</p>
					<p>
						You can access the individual interviews here. The year of birth appears next to the name of the
						interviewee. The interviews are only available in the original language.
					</p>

					<ul>
						<li>
							<a href="https://valloalpino.info/api/uploads/1923_Rosa_Oberhollenzer_Niedermair.pdf"
							   target="_blank">1923 Rosa Oberhollenzer Niedermair</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1925_Matthias_Mair.pdf"
							   target="_blank">1925 Matthias Maier</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1929_Richard_Amort.pdf"
							   target="_blank">1929 Richard Amort</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1929_Karl_Weger.pdf"
							   target="_blank">1929 Karl Weger</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1931_M_B_und_1935_Notburga_Bacher.pdf"
							   target="_blank">1931 M. B. and 1935 Notburga Bacher</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1931_Franz_und_1933_Urban_Amort.pdf"
							   target="_blank">1931 Franz Amort and 1933 Urban Amort</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1932_Johann_Lercher.pdf"
							   target="_blank">1932 Johann Lercher</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1933_Franz_Pichler.pdf"
							   target="_blank"></a>1933 Franz Pichler
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1933_Ivo_Hechensteiner.pdf"
							   target="_blank">1933 Ivo Hechensteiner</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1934_Bruno_Dorigatti.pdf"
							   target="_blank">1934 Bruno Dorigatti</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1935_S__M.pdf"
							   target="_blank">1935 S. M.</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1936_Irma_Pramstaller-Oberhammer.pdf"
							   target="_blank">1936 Irma Oberhammer</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1936_A__S.pdf"
							   target="_blank">1936 A. S.</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1937_Peter_von_Hellberg.pdf"
							   target="_blank">1937 Peter Hellberg</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1938_Bruno_Hosp.pdf"
							   target="_blank">1938 Bruno Hosp</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1938_Engelbert_Taferner.pdf"
							   target="_blank">1938 Engelbert Taferner</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1946_Leopold_Steurer.pdf"
							   target="_blank">1946 Leopold Steurer</a>
						</li>
						<li>1947 Hans Berger
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1948_Helmut_Stampfer.pdf"
							   target="_blank">1948 Helmut Stampfer</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1953_Hermes_Vigna.pdf"
							   target="_blank">1953 Hermes Vigna</a>

						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1972_Juliane_Stocker.pdf"
							   target="_blank">1972 Juliane Stocker</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1974_Esther_Stocker.pdf"
							   target="_blank">1974 Esther Stocker</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1984_Hannes_Goetsch.pdf"
							   target="_blank">1984 Hannes Götsch</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/1997_Tobias_Joos.pdf"
							   target="_blank">1997 Tobias Joos</a>
						</li>
						<li>
							<a href="https://valloalpino.info/api/uploads/2002_Nikolai_Plangger.pdf"
							   target="_blank">2002 Nikolai Plangger</a>
						</li>
					</ul>

					<h3>
					PROJECT DATA
					</h3>
					<p>
					Title: The Italian Alpine fortifications of the Second World War – the so-called “VALLO ALPINO LITTORIO”
					</p>
					<p>
					Budget: funds from the research budget of the Department of Provincial Museums and the Provincial Museum of Franzensfeste Fortress, totalling approx. € 130,000
					</p>
					<p>
					Project management: Esther Erlacher of the Provincial Museum of Franzensfeste Fortress
					</p>
					<p>
					Scientific direction: Heimo Prünster
					</p>
					<p>
					Consultation and conception workshop: Gennaro Postiglione
					</p>
					<p>
					Advice on contemporary history: Andrea Di Michele, Giuseppe Spagnulo
					</p>
					<p>
					Research into contemporary history: Giuseppe Spagnulo
					</p>
					<p>
					Advice on data organisation: Bauforschung Tirol (Barbara Lanz and Sonja Mitterer), Katharina Kranewitter
					</p>
					<p>
					Data visualisation: Florian Windhager (consulting, concept, design), Mindfactor – Michael Smuc (consulting, concept, design, software development)
					</p>
					<p>
					Transcription of eyewitness interviews: Katharina Kranewitter
					</p>
					<p>
					Assistance with archive research and data organisation: Florian Aufschnaiter
					</p>
					<p>
					Translations: Bonetti & Peroni
					</p>

					<h4>
					Archives and collections consulted (selection):
					</h4>
					<p>
					Historical Office of the Army General Staff, Rome
					</p>
					<p>
					Historical and Cultural Institute of the Corps of Engineers, Rome
					</p>
					<p>
					Central State Archive, Rome
					</p>
					<p>
					Historical-Diplomatic Archive of the Ministry of Foreign Affairs, Rome
					</p>
					<p>
					Alpine Troops HQ, Bozen
					</p>
					<p>
					Bavarian State Library, Munich
					</p>
					<p>
					Heimo Prünster Collection
					</p>
					<p>
					Archive of the Provincial Department for Estate Management of the Autonomous Province of Bolzano – South Tyrol
					</p>
					<p>
					Tessmann Provincial Library – Digital
					</p>

				</div>
			)
		default: return null

	}

}


export const Help = () => {

	const language = useStore(state => state.language);


	switch (language) {
		case 'DE':
			return (
				<div lang={'de'}>

					{/*<h3>HINTERGRUND</h3>*/}
					<h4>Was ist abgebildet?</h4>
					<p>
						Diese Datenvisualisierung stellt den Bestand an Einzelwerken (Bunker) der ständigen Befestigung
						des
						„Vallo Alpino“ in Südtirol dar. Die Daten zu den einzelnen Werken umfassen ihre
						Erfassungsnummer,
						den Fertigstellungsgrad, den Namen der Sperre, der sie angehören, ihre geografische Lage und die
						Meereshöhe des Standorts.
					</p>
					<p>
						Rot umrandete Einzelwerke enthalten zusätzliches Informationsmaterial wie Fotos, Pläne, Videos,
						Texte oder andere Dokumente.
					</p>
					<p>
						Keine Berücksichtigung in der Darstellung finden hingegen Panzerhindernisse, Wehrkasernen (sog.
						„Casermette“), Unterstände, Artilleriestellungen und alle weiteren Elemente der nicht ständigen
						Befestigung, Kasernen sowie der gesamte zugehörige Straßenbau, der das Rückgrat des „Vallo
						Alpino”
						bildet.
					</p>
					<p>
						Die Datenvisualisierung wird konstant weiterentwickelt und mit neuen Forschungsdaten befüllt.
					</p>
					<h4>
						Fertigstellungsgrad
					</h4>

					<p>
						Eine der Besonderheiten des „Vallo Alpino” ist sein Fertigstellungsgrad: Ein gutes Viertel des
						monumentalen Bauwerks blieb unvollendet. Er ist einer der Hauptgründe für die geringe
						Funktionstüchtigkeit des Verteidigungssystems.

					</p>
					Das Forschungsprojekt hat zu diesem interessanten Aspekt Daten erhoben und grafisch aufgearbeitet.
					Mit dem Fertigstellungsgrad wird sichtbar gemacht, wie weit der Bau jedes einzelnen Südtiroler
					Bunkers fortgeschritten ist.
					<p>
						Der Fertigstellungsgrad umfasst drei Stadien:
					</p>
					<p>
						<strong>Geplant</strong>: Zu einem Bunker liegen Planungen vor, baulich wurde jedoch noch nichts
						umgesetzt.
					</p>
					<p>
						<strong>Begonnen</strong>: Der Bau eines Bunkers hat begonnen, wurde aber noch nicht
						abgeschlossen.
					</p>
					<p>
						<strong>Fertiggestellt</strong>: Ein Bunker ist baulich fertiggestellt. Seine Ausstattung ist
						dabei nicht
						berücksichtigt.
					</p>
					<p>
						Der Fertigstellungsgrad kann auf zwei Arten angezeigt werden:
					</p>
					<p>
						<strong>Dynamisch</strong>:
					</p>
					<p>
						Mit dem Schieberegler der Zeitleiste am unteren Bildrand können die beiden
						Fertigstellungsgrade <i>Geplant</i> (orange) und <i>Fertiggestellt</i> (dunkelbraun) angezeigt
						werden.
					</p>
					<p>
						Bewegt man den Schieberegler, ändert sich die Anzeigefarbe jener Bunker, die fertiggestellt
						wurden, von orange (Geplant) auf dunkelbraun (Fertiggestellt). Alle übrigen Bunker, die ihre
						Farbe nicht ändern, blieben entweder Planungen auf Papier oder unfertige Gebäude.
					</p>
					<p>
						<strong>Statisch</strong>:
					</p>
					<p>
						Diese Darstellungsform ist zwar statisch, aber detailgenauer. Sie ermöglicht die Anzeige aller
						drei Fertigstellungsgrade: <i>Geplant</i> (orange), <i>Begonnen</i> (rot)
						und <i>Fertiggestellt</i> (dunkelbraun).
						Hier sind nun eigens auch jene Bunker farblich erfasst, die unvollendet geblieben sind.
					</p>
					<p>
						Die Daten zur Fertigstellung wurden nach Verfügbarkeit eingetragen, die Datenlage war
						teilweise sehr dünn. Der dargestellte Zeitpunkt der Fertigstellung könnte in einigen Fällen
						mehrere Monate früher gelegen haben, mit Sicherheit aber nicht später.
					</p>
					<h4>
						Erfasster Zeitraum
					</h4>
					<p>
						Die Darstellung des Baufortschritts umfasst einen Zeitraum von Jahresende 1938 bis Mitte April
						1942.
					</p>
					<p>
						Die ersten Bauarbeiten in Südtirol begannen bereits 1931, doch bis Ende 1938 entstanden
						insgesamt nur 26 Einzelwerke an den Grenzübergängen, zu denen noch keine genauen Daten
						vorliegen.
					</p>
					<p>
						Mussolini befürchtete Ansprüche Deutschlands auf das Territorium Südtirols. Als Österreich im
						Mai 1938 ans Deutsche Reich angeschlossen wird, vollzieht Italien eine Kehrtwende in der
						militärischen Ausrichtung: Der zuerst offensiv angelegte „Vallo Alpino” sollte den Erhalt
						Österreichs als selbständigen Pufferstaat zu Deutschland sichern. Unmittelbar nach dem
						„Anschluss“ verwirft Italien seine offensive Strategie und verwandelt den „Vallo Alpino” in ein
						monumentales Verteidigungssystem.
					</p>
					<p>
						Die Umsetzung des epochalen Betonwalls nahm erst Ende 1939 seinen Anfang und ist vollumfänglich
						in der Visualisierung abgebildet.
					</p>
					<p>
						Die endgültige Einstellung der Bauarbeiten erfolgte im Oktober 1942, sechs Monate nach der bis
						dato letzten, verfügbaren Aufzeichnung. Die Bautätigkeit von Mitte April bis Ende Oktober war
						vernachlässigbar gering, sodass diese Daten von Mitte April 1942 den Endpunkt der Visualisierung
						bilden.
					</p>

					<h4>
						Lage der Einzelwerke
					</h4>
					<p>
						Die geografische Lage der Einzelwerke im Gelände wurde aus der Interpolation aller verfügbaren
						Lagepläne ermittelt. Die Übertragung der Positionen von den Lageplänen in die
						Datenvisualisierung erfolgte als händische Annäherung.
					</p>
					<p>
						Das Ziel war, die Abweichungen so gering wie möglich zu halten, dennoch können die Abweichungen
						erheblich sein. Diese ergeben sich aus der Überlagerung des perspektivisch aufgebauten Geländes
						der Datenvisualisierung (Zentralprojektion) und originalen, zweidimensionalen Lageplänen
						(Parallelprojektion), die teilweise sehr ungenaue Positionsangaben enthalten. Die Abweichung der
						Position von Einzelwerken in der Datenvisualisierung und ihrer realen Position in der Landschaft
						beträgt im Idealfall 0 Meter, kann aber auch mehr als 50 Meter betragen.
					</p>
					<h4>
						Hinweis
					</h4>
					<p>
						Möchten Sie Bunker besichtigen, empfehlen wir Ihnen eine Führung im Bunker Nr. 3 nahe dem
						Landesmuseum Festung Franzensfeste. Diese Anlage wurde eigens für Führungen adaptiert und
						entspricht den gängigen Sicherheitsvorschriften. Hier können Sie sich für eine Führung
						anmelden: <a href="https://www.franzensfeste.info/geheimnisvolle-unterwelten-exkursion-in-den-bunker/"
						             target="_blank">Anmeldung</a>
					</p>
					<p>
						Wir weisen darauf hin, dass sich Südtirols Bunkeranlagen in privatem oder öffentlichem Besitz
						befinden und nicht frei zugänglich sind. Für das Betreten von Bunkern ist eine Genehmigung des
						Eigentümers nötig. Wir empfehlen generell, vom Betreten von Bunkeranlagen abzusehen. Die
						Innenräume sind nicht beleuchtet, nicht gesichert und bergen viele Gefahrenquellen. Bei Bunkern,
						die nicht fertiggestellt wurden, besteht zudem häufig Einsturzgefahr.
					</p>
					<p>
						Wir verweisen noch auf weitere, öffentlich zugängliche Bunker in Südtirol:
					</p>
					<p>
						Bunker an der Etschquelle – Mooseum in Moos im Passeier – Gampenbunker am Deutschnonsberg –
						Bunker Museum bei Toblach
					</p>

				</div>
			)
		case 'IT':
			return (
				<div lang={'it'}>

					{/*<h3>HINTERGRUND</h3>*/}
					<h4>Cosa viene visualizzato?</h4>
					<p>
						La visualizzazione illustra il patrimonio di opere (bunker) della fortificazione permanente del Vallo Alpino in Alto Adige. I dati relativi alle singole opere comprendono il loro numero identificativo, il grado di completamento, il nome dello sbarramento di appartenenza, l’ubicazione geografica e l’altitudine del sito.
					</p>
					<p>
						Le opere contornate di rosso includono materiale informativo aggiuntivo come foto, progetti, testi o altri documenti.
					</p>
					<p>
						Sono invece escluse dalla presentazione ostacoli anticarro, casermette, rifugi, postazioni di artiglieria, così come tutti gli elementi della fortificazione non permanente, le caserme e la relativa opera di costruzione stradale, che costituisce l’ossatura del Vallo Alpino.
					</p>
					<p>
						La visualizzazione dei dati viene costantemente ampliata e integrata con nuovi dati di ricerca.
					</p>
					<h4>
						Grado di completamento
					</h4>

					<p>
						Una delle particolarità del Vallo Alpino coincide con la sua percentuale di completamento: oltre un quarto della monumentale opera resta incompiuto. Lo stato di avanzamento dei lavori è tra le principali cause della ridotta funzionalità del sistema difensivo.

					</p>
					Il progetto di ricerca ha raccolto una serie di informazioni su questo interessante aspetto, rielaborandole graficamente. La percentuale di completamento offre una dimensione concreta e tangibile dello stato di avanzamento dei lavori di ogni singolo bunker dell’Alto Adige.
					<p>
						Sono previsti tre stadi di completamento.
					</p>
					<p>
						<strong>Progettato</strong>: i progetti del bunker sono stati elaborati, ma non implementati in termini costruttivi.
					</p>
					<p>
						<strong>Iniziato</strong>: la realizzazione del bunker è cominciata, ma non è stata ultimata.
					</p>
					<p>
						<strong>Ultimato</strong>: la costruzione del bunker è stata ultimata. La sua dotazione non viene presa in considerazione.
					</p>
					<p>
						Il grado di completamento può essere visualizzato secondo due modalità.
					</p>
					<p>
						<strong>Dinamico</strong>
					</p>
					<p>
						Il cursore della linea temporale posizionata nel margine inferiore dell’immagine consente di
						visualizzare i due stadi di completamento <i>progettato</i> (arancione)
						e <i>ultimato</i> (marrone scuro).
					</p>
					<p>
						Spostando il cursore, la tonalità di visualizzazione dei bunker la cui costruzione è stata
						completata passa da arancione (progettato) a marrone scuro (ultimato). Le restanti opere, che
						non cambiano colore, sono rimaste solo sulla carta o non sono state terminate.
					</p>
					<p>
						<strong>Statico</strong>
					</p>
					<p>
						Questa forma di raffigurazione è statica, ma più dettagliata e consente di visualizzare tutti e
						tre gli stadi di realizzazione: <i>progettato</i> (arancione), <i>iniziato</i> (rosso)
						e <i>ultimato</i> (marrone scuro).
					</p>
					<p>
						Qui è prevista un’acquisizione cromatica ad hoc anche dei bunker rimasti incompiuti.
					</p>
					<p>
						I dati relativi al completamento sono stati inseriti in base alla disponibilità: le informazioni
						cui attingere erano in parte molto esigue. In alcuni casi, il momento del completamento
						dell’opera rappresentato potrebbe collocarsi svariati mesi prima, ma sicuramente non in un
						momento successivo.
					</p>
					<h4>
						Lasso di tempo rilevato
					</h4>
					<p>
						La rappresentazione dell’avanzamento dei lavori comprende un arco temporale che si estende dalla
						fine del 1938 alla metà di aprile del 1942.
					</p>
					<p>
						I primi lavori di costruzione, in Alto Adige, iniziarono già nel 1931 ma, alla fine del 1938,
						erano sorte complessivamente solo 26 opere singole in corrispondenza dei valichi di frontiera,
						su cui non sono ancora disponibili dati precisi.
					</p>
					<p> Mussolini temeva le rivendicazioni della Germania sul territorio dell’Alto Adige. Quando
						l’Austria, nel maggio del 1938, fu annessa al Reich, l’Italia impresse una drastica inversione
						di rotta al proprio orientamento militare: il Vallo Alpino, inizialmente concepito con intento
						offensivo, doveva assicurare il mantenimento dell’Austria quale Stato cuscinetto autonomo per la
						difesa del confine settentrionale. Subito dopo l’annessione, l’Italia accantonò la propria
						strategia offensiva, convertendo il Vallo Alpino in un monumentale sistema di difesa.
					</p>
					<p> La realizzazione dell’epocale vallo di cemento ebbe inizio solo alla fine del 1939 ed è
						integralmente riportata nella visualizzazione.
					</p>
					<p> La definitiva sospensione dei lavori ebbe luogo nell’ottobre del 1942, sei mesi dopo l’ultimo
						documento ad oggi disponibile. L’attività costruttiva da metà aprile a fine ottobre fu
						trascurabile: i dati della metà di aprile del 1942, pertanto, rappresentano il punto finale
						della visualizzazione.
					</p>

					<h4>
						Posizione delle singole opere
					</h4>
					<p>
						L’ubicazione geografica delle singole opere sul terreno è stata desunta dall’interpolazione di
						tutte le planimetrie disponibili. Il trasferimento delle posizioni dalle planimetrie alla
						visualizzazione dei dati si è svolto mediante allineamento manuale.

					</p>
					<p>
						L’obiettivo era la minimizzazione degli scostamenti, che tuttavia possono essere rilevanti. Le
						discrepanze emergono dalla sovrapposizione tra il terreno rappresentato in modo prospettico
						nella visualizzazione dei dati (proiezione centrale) e le planimetrie originali bidimensionali
						(proiezione parallela), che talvolta contengono indicazioni alquanto imprecise sull’ubicazione.
						La divergenza tra il collocamento delle singole opere nella visualizzazione dei dati e la loro
						reale posizione nel paesaggio è idealmente pari a 0 metri, ma può superare i 50 m.

					</p>

					<h4>
						Avvertenza
					</h4>
					<p>
						Se volete visitare i bunker, raccomandiamo una visita guidata al bunker n. 3 nei pressi del Museo
						Provinciale Forte di Fortezza. La struttura è stata appositamente adattata allo scopo e ottempera
						alle norme vigenti in materia di sicurezza. La visita al bunker n. 3 può essere prenotata qui:
						<a href="https://www.franzensfeste.info/it/mondi-sotterranei-misteriosi-escursione-nel-bunker/" target="_blank" >Link</a>
					</p>
					<p>
						Vi preghiamo di tenere presente che i bunker dell’Alto Adige sono di proprietà pubblica o
						privata e non sono liberamente fruibili. Per entrare nei bunker, è necessaria l’autorizzazione
						del proprietario. In generale, l’accesso alle opere è sconsigliato: gli ambienti interni non
						sono illuminati, né messi in sicurezza e celano molte insidie. Nei bunker non ultimati, inoltre,
						sussiste spesso il pericolo di crolli.
					</p>
					<p>
						Vi rimandiamo ad altri bunker dell’Alto Adige accessibili al pubblico:
					</p>
					<p>
						Bunker alla sorgente dell’Adige – Mooseum a Moso in Passiria – Gampen Bunker in Alta Val di Non
						– Bunker Museum presso Dobbiaco
					</p>


				</div>
			)
		case 'EN':
			return (
				<div lang={'en'}>
					{/*<h3>HINTERGRUND</h3>*/}
					<h4>What is being visualised?</h4>
					<p>
						This data visualisation represents the inventory of individual works (bunkers) of the permanent
						fortification known as the “Vallo Alpino” in South Tyrol. The data on individual works include
						their registration number, the degree of completion, the name of the barrier to which they
						belong, their geographical location and the site’s height above sea level.
					</p>
					<p>
						Individual works that are outlined in red include additional information, such as photos, plans,
						videos, texts or other documents.
					</p>
					<p>
						The representation however does not take account of any tank traps, military quarters, shelters,
						artillery positions nor of any other non-permanent fortification elements, barracks, or the
						entire associated road construction forming the backbone of the “Vallo Alpino”.
					</p>
					<p>
						The data visualisation is constantly being further developed and updated with new research data.
					</p>

					<h4>
						Degree of completion
					</h4>
					<p>
						One of the peculiarities of the “Vallo Alpino” is its degree of completion: at least one quarter
						of this monumental structure remained unfinished and was one of the main reasons for the reduced
						functionality of the defensive system.

					</p>
					<p>
						The research project has collected data on this interesting aspect and processed it graphically.
						The degree of completion visualises the construction progress of each individual South Tyrolean
						bunker.

					</p>
					<p>
						The degree of completion comprises three stages:
					</p>

					<p>
						<strong>Planned</strong>: there are plans for a bunker, but nothing has been implemented yet.
					</p>
					<p>
						<strong>Started</strong>: the construction of a bunker has begun but is not yet completed.
					</p>
					<p>
						<strong>Completed</strong>: the bunker is structurally complete. Its equipment is not taken into account.
					</p>
					<p>
						The degree of completion can be displayed in two ways:
					</p>
					<p>
						<strong>Dynamic</strong>:
					</p>
					<p>
						The timeline slider at the bottom of the screen can be used to display the two degrees of
						completion, <i>Planned</i> (orange) and <i>Completed</i> (dark brown).

					</p>
					<p>
						Moving the slider changes the display colour of the bunkers that have been completed from orange
						(Planned) to dark brown (Completed). All other bunkers that do not change colour remain as
						either planned on paper or as unfinished buildings.

					</p>

					<p>
						<strong>Static</strong>:
					</p>
					<p>
						This type of display is static, but more detailed. It displays all three degrees of completion:
						<i>Planned</i> (orange), <i>Started</i> (red) and <i>Completed</i> (dark brown).
					</p>
					<p>
						The bunkers that remained unfinished have been individually colour-coded here.
					</p>
					<p>
						The completion dates have been entered according to availability; the data situation was in part
						very imprecise. The completion date shown may in some cases have been several months earlier,
						but certainly not later.
					</p>


					<h4>
						Period covered
					</h4>
					<p>
						The description of construction progress covers the period from the end of 1938 to mid-April
						1942.
					</p>
					<p>
						The first construction work in South Tyrol began as early as 1931 but, by the end of 1938, only
						26 individual works (for which no precise data is yet available) had been built at the border
						crossings.
					</p>
					<p>
						Mussolini feared German claims over South Tyrol. When the German Reich annexed Austria in May
						1938, Italy made a U-turn in its military orientation: the “Vallo Alpino” had initially been
						designed in offensive terms, intended to ensure the maintenance of Austria as an independent
						buffer state against Germany. Immediately after the Anschluss, however, Italy abandoned its
						offensive strategy and transformed the “Vallo Alpino” into a monumental defensive system.
					</p>
					<p>
						The realisation of the epochal concrete wall was only begun at the end of 1939 and is fully
						depicted in the visualisation.
					</p>
					<p>
						Construction work finally ceased in October 1942, six months after the last record available to
						date. Construction activity from mid-April to the end of October was negligible, so the data
						from mid-April 1942 represent the end point of the visualisation.
					</p>


					<h4>
						Location of the individual works
					</h4>

					<p>
						The geographical location of the individual works was determined via the interpolation of all
						available site plans. The positions were transferred from the site plans to the data
						visualisation by means of a manual approximation.
					</p>
					<p>
						The aim was to minimise the deviations; these can still be considerable, however. They are the
						result of a superimposition of the terrain represented in perspective mode from the data
						visualisation (central projection) and from original, two-dimensional site plans (parallel
						projection), which may sometimes contain very inaccurate position information. The ideal
						deviation of the position of individual works from their actual position in the landscape in the
						data visualisation is zero meters but may exceed 50 meters.
					</p>

					<h4>
						Note
					</h4>
					<p>
						If you wish to visit the bunkers, we recommend a guided tour of Bunker no. 3 near the
						Franzensfeste Fortress, which has been specially adapted for guided tours and complies with
						current safety regulations. You can register for a guided tour here:
						<a href="https://www.franzensfeste.info/geheimnisvolle-unterwelten-exkursion-in-den-bunker/"
						   target="_blank">Anmeldung</a>
					</p>
					<p>
						Please note that South Tyrol’s bunkers are either privately or publicly owned and are not freely
						accessible. Permission from the owner is required to enter the bunkers. In general, we recommend
						that you do not enter the bunkers: the interiors are unlit, insecure and feature many sources of
						danger. In addition, incomplete bunkers are often in danger of collapse.
					</p>

					<p>
						Other bunkers in South Tyrol that are open to the public:
					</p>
					<p>
						Bunkers at the source of the Etsch River – Mooseum in Moos im Passeier – Gampenbunker at
						Deutschnonsberg – Bunker Museum near Toblach
					</p>


				</div>
			)
		default: return null

	}

}


export const Onboarding = () => {

	const language = useStore(state => state.language);

	const [isOpen, setIsOpen] = useState(false);

	const isOnboarding = useStore(state => state.isOnboarding);
	const setIsOnboarding = useStore(state => state.setIsOnboarding);

	const [show, setShow] = useState(true);
	const [closed, setClosed] = useState(false)

//	const delay = firstModalCompleted ? isMo ? 1500 : 4000 : 99999999999;
	const delay = 5000;

//	console.log(language)

	useEffect(() => {
		setTimeout(() => {
//			window.scrollTo(0, 100);
		}, delay - 1000)

	}, []);

//	useEffect(() => {
//		if (clickedHelp) {
//			setShow(false)
//			setClosed(true)
//			setIsOnboarding(false)
//		}
//	}, [clickedHelp])

	const handleShowForm = event => {
		event.stopPropagation()
		setShow(!show)
//		if (event.currentTarget.id === 'helpText') {
////			console.log('FIRED')
//			setShowInfo(true);
//			setShowMediaOverlay(true);
//		}
		setTimeout(() => {
			setClosed(true)
			setIsOnboarding(false)
		}, 1000)
	}

	useEffect(()=>{
		if (!isOnboarding) {
			setTimeout(() => {
				setIsOnboarding(false)
				setClosed(true)
			}, 1000)
		}
	},[isOnboarding])

	const {s} = useSpring({
		from: {s: 0},
		to: {s: show ? 1 : 0},
		delay: show ? delay : 10,
		onStart: () => { setIsOnboarding(true) }
	})

	return (
		<>
			{!closed &&
				<div style={{ position: 'absolute', top: '2.5em', left: '-1em'}}>
					<a.div className="onboardingcontainer"
					       style={{
							   left: '8em',
						       opacity: s,
					       }}
					>
						<div
							className={'Xbuttonright'} style={{}}
							style={{
								position: 'relative',
								top: '-0.5em',
								fontSize: '2em'
							}}
							onClick={handleShowForm}
						>&times;
						</div>
						<div className={'onboardingText'}
						     style={{position: 'relative', top: '-1em'}}
						>
							{language === "DE" &&
								<div>
									<p>Mit einem Klick auf „Quicktour“ beginnen Sie eine interaktive Tour, die Sie vom
										Brennerpass bis nach Bozen durch alle Bunkerlinien hindurch führt.
										An den sechs Stationen werden verschiedene Bunker mit Fotos, Plänen und anderen
										Dokumenten vorgestellt. </p>
									<p>Gute Reise!</p>
								</div>
							}
							{language === "IT" &&
								<div>
									<p>Cliccando su “Start”, inizierete un tour interattivo, che si snoda dal Passo del Brennero sino a Bolzano, attraverso tutte le linee delle opere difensive. Nelle sei stazioni, attraverso foto, progetti ed altri documenti, vengono presentati diversi bunker.</p>
									<p>Buon viaggio!</p>
								</div>
							}
							{language === "EN" &&
								<div>
									<p>Click on “Start” to begin an interactive tour that will take you through each of the bunker lines from the Brenner Pass down to Bozen. The six stops present various bunkers with photos, plans and other documents. </p>
									<p>Enjoy your journey!</p>
								</div>
							}

						</div>
					</a.div>
					<a.div className={'triangle-down'}
					       style={{
							   position: 'relative',
							   top: '-4.1em',
							   left: '-3em',
						       borderBottom: `30px solid #4A4B59DD`,
						       opacity: s,
					       }}
					>
					</a.div>
				</div>
			}
		</>
	)
}

