import React, {useEffect} from 'react';
import mapboxgl from 'mapbox-gl';
import * as d3 from "d3";
import mySVG from '../images/bunker3.svg';
import {useStore} from "./store";

class d3Comp extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mapLoaded: false,
			currDataLoaded: false,
			initDone: false
		}
		this.project = this.project.bind(this);
	}

	// Project GeoJSON coordinate to the map's current state
	project = (d) => {
		const {map} = this.props.states;
		return map.project(new mapboxgl.LngLat(+d[0], +d[1]));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

//		console.log("%c ---> D3Comp: state updated", "color: blue", this.state);

		const {map} = this.props.states;

		if (this.props.states.bunkerLoaded !== prevProps.states.bunkerLoaded && this.props.states.bunkerLoaded === true) {

			const col1 = "#f78c0d";
			const col2 = "#883333";

			// Get Mapbox map canvas container
			var canvas = map.getCanvasContainer();
			// Overlay d3 on the map
			var svg = d3.select(canvas).append("svg");

			// Call the update function
			const update = () => {
//				console.log("update");

				const zoom = map.getZoom();

				var rescale = d3.scaleLinear()
					.domain([1, 22])
					.range([-0.5, +3]);

				// get icon size
				iconWidth = bunkerIcons.select("*").attr("width") * rescale(zoom);
				iconHeight = bunkerIcons.select("*").attr("height") * rescale(zoom);

				const that = this;
				bunkerIcons.each(function (d) {
					let transformX = that.project(d.geometry.coordinates).x - iconWidth / 2;
					let transformY = that.project(d.geometry.coordinates).y - iconHeight / 2;


					// XXX set to 1 to make bunker icons visible
					var visibility = 0;

					if (transformX < 0.1 || transformX > 5000) {
						visibility = 0;
						transformY = -1;
						transformX = -1;
					}
					;
					if (transformY < 0.1 || transformY > 5000) {
						visibility = 0;
						transformY = -1;
						transformX = -1;
					}
					;

//					console.log(visibility, transformX, transformY, rescale(zoom));
					d3.select(this)
						.style("fill-opacity", visibility)
						.style("stroke-opacity", visibility)
						.attr("transform", d => "translate(" + transformX + "," + transformY + ") scale(" + rescale(zoom) + ")");
				});

				circles
					.attr("r", (d) => { return (20 * rescale(zoom)) })
					.attr("cx", (d) => { return this.project(d.geometry.coordinates).x })
					.attr("cy", (d) => { return this.project(d.geometry.coordinates).y });
			}

			map.on("viewreset", update);
			map.on("move", update);
			map.on("moveend", update);

			var mySource = map.getSource('bunker');
//			var mySourcefakes = map.getSource('ms111');

			var bunkergroup = svg.append("g").attr("class", "bunkerGroup");

			var iconWidth, iconHeight;

			const loadSVG = d3.svg(mySVG)
				.then(data => {
					data.documentElement.id = "bunkerIconTemplate";
					bunkergroup.node().append(data.documentElement);
				});

			loadSVG.then(() => {
				draw(mySource.serialize().data);
//				draw(mySourcefakes.serialize().data);
			});

			var bunkerIcons, circles;

			function draw(data) {

				// clone icons
				bunkerIcons = svg.selectAll(".bunkerIcons")
					.data(data.features)
					.enter()
					.append((d) => {return bunkergroup.clone(true).attr("id", d.properties.id).node()})
					.attr("id", d => {return "bunkerID" + d.properties.id});

				// delete bunker template icon (which is the first one)
				svg.select(".bunkerGroup").remove();

				// add circles for selection
				circles = svg.selectAll("circle")
					.data(data.features)
					.enter()
					.append("circle")
					.attr("class", "markerCircle")
					.on("click", (e, d) => {
						//console.log(e, d);
						//console.log("Bunker ID from d3.js: " + d.properties.id);
					});

				const lowerBound = useStore.getState().timesliderStart;
				const upperBound = useStore.getState().timesliderStop;

				// Example: recolor icons & the circle which shows the selection
				bunkerIcons.select("path").each(function (d) {
					d3.select(this).attr("fill", (d) => d.properties.built > upperBound || d.properties.built < lowerBound ? col1 : col2);
				});
				circles.attr("stroke", d => d.properties.built > upperBound || d.properties.built < lowerBound ? col1 : col2)

				update();
			}

		}
	}

	render() {
		return (
			<div>
				<UpdateCircles />
			</div>
		)
	};
}

function UpdateCircles() {

	const col1 = "#f78c0d";
	const col2 = "#883333";

	const lowerBound = useStore(state => state.timesliderStart)
	const upperBound = useStore(state => state.timesliderStop)

	useEffect(() => {

		const circles = d3.selectAll(".markerCircle");

		if (circles.nodes().length > 10) {
			circles.attr("stroke", d => {
				return d.properties !== undefined && (d.properties.built > upperBound || d.properties.built < lowerBound) ? col1 : col2
			}
			)
		}

	}, [lowerBound, upperBound])

	return null
}

export default d3Comp;
