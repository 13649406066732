import React from 'react';
import mapboxgl from 'mapbox-gl';

import '../css/story.css';

import directions from '../data/flight';
import {useStore} from "./store";

const selectedlayers = useStore.getState().selectedlayers;

//const [language, setLanguage] = useState(lang);

class Story extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			lang: this.props.lang,
			startStory: this.props.startStory,
			guiData: this.props.guiData,
			init: false,
			storyData: {
				status: "stopped",
				station: 0,
				content: null
			}
		}
	}

	resetFlightData() {
		let out = {
			storyData: {
				status: "stopped", station: 0, content: null
			}
		};
		this.props.storyCallback(out);
		this.setState(out);
	}


	updateFlightData(status, d, currDirection) {
		let content = ["empty"];
		let bunker = -1;
		if (currDirection.content !== undefined) content = currDirection.content;
		if (currDirection.bunker !== undefined) bunker = currDirection.bunker;
		// console.log("bunker: ", bunker);
		let out = {
			storyData: {
				status: status, station: d, content: content, direction: currDirection, bunker: bunker
			}
		};
		this.props.storyCallback(out);
		this.setState(out);
	}

	disableInteraction = () => {
//		console.log("###### interaction disabled");
		this.props.map.boxZoom.disable();
		this.props.map.scrollZoom.disable();
		this.props.map.dragPan.disable();
		this.props.map.dragRotate.disable();
		this.props.map.keyboard.disable();
		this.props.map.doubleClickZoom.disable();
		this.props.map.touchZoomRotate.disable();
	}

	enableInteraction = () => {
//		console.log("#### interaction enabled");
		this.props.map.boxZoom.enable();
		this.props.map.scrollZoom.enable();
		this.props.map.dragPan.enable();
		this.props.map.dragRotate.enable();
		this.props.map.keyboard.enable();
		this.props.map.doubleClickZoom.enable();
		this.props.map.touchZoomRotate.enable();
	}

	fly = (d) => {

		if (this.props) {
			const {map} = this.props;
		} else return;

		const currDirection = directions[d];

		if (!currDirection.action) {
			return
		}

		switch (currDirection.action) {

			case "showContent":
//				console.log("showContent");
				setTimeout(() => {
					this.updateFlightData("started", d, currDirection);
					useStore.setState({isStory: true});
				}, currDirection.time);
				this.enableInteraction();
				break;
			case "move":
				setTimeout(() => {
					this.updateFlightData("started", d, currDirection);
					this.disableInteraction();
					if (currDirection.bunker !== -1) {
						this.props.map.setPaintProperty(
							'bunker-circle-outline',
							'circle-stroke-opacity',
							['match', ['get', 'db_id'], "" + currDirection.bunker, 1, 0.0]
						)
					} else if (currDirection.bunker !== -1) {
//						this.props.map.setPaintProperty('bunker-circle-outline', 'circle-stroke-opacity', 0);
					}
					if (currDirection !== undefined && currDirection.fly) {
						let adaptedDirection = {...currDirection};
						adaptedDirection.fly.speed = adaptedDirection.fly.speed * this.props.guiData.animationSpeed;
						this.props.map.flyTo(adaptedDirection.fly, {flightstate: 1})
					} else if (currDirection !== undefined && currDirection.pan) {
						let adaptedDirection = {...currDirection};
						adaptedDirection.pan.speed = adaptedDirection.pan.duration * this.props.guiData.animationSpeed;
						this.props.map.panTo(adaptedDirection.pan.destination, currDirection.pan.animation, {flightstate: 2})
					} else {
						let adaptedDirection = {...currDirection};
						adaptedDirection.ease.speed = adaptedDirection.ease.duration * this.props.guiData.animationSpeed;
						this.props.map.easeTo(currDirection.ease, {flightstate: 2})
					}
				}, currDirection.time);
				break;
			case "end":
				this.enableInteraction();
				this.updateFlightData("finished", d, currDirection);
				useStore.setState({isStory: true});
				break;
			default:
				this.updateFlightData("started", d, currDirection);
				break;
		}
	}


	componentDidMount(props) {
	}

	componentDidUpdate(prevProps, prevState) {

//		console.log("%c ---> story: state updated", "color: blue", this.state, prevState, this.props, prevProps);

		let remoteAction = useStore.getState().remoteAction;

		const {map} = this.props;

		if (remoteAction == 'ending') {
			this.props.map.stop()
			this.props.map.off('moveend')
			setTimeout(()=>{
				this.props.map.easeTo({center: [11.5, 46.6], zoom: 8.3, duration: 2000, pitch: 0, bearing: 0, padding: {left: 0}});
			}, 1000)
			selectedlayers.map((d) => map.setLayoutProperty(d, 'visibility', 'visible'));
			setTimeout(()=>{
				// console.log('###########################')
				useStore.setState({remoteAction: 'none'});
			}, 5000)
		}

		if (remoteAction === 'next') {
			const newstate = useStore.getState().setRemoteAction('none');
			this.fly(this.state.storyData.station + 1);
		}

		if (this.props.startStory !== prevProps.startStory && this.props.startStory === true) {

			// selectedlayers.map((d) => map.setLayoutProperty(d, 'visibility', 'none'));
			if (!this.state.init) {
				map.on('moveend', (e) => {
					if (e.flightstate) {
						this.fly(this.state.storyData.station + 1);
					}
				});
				this.setState({init: true})
			}
			// start journey
			setTimeout (()=> {this.fly(0)}, 100)
		}
	}

	render() {
		return (
			<div className='Story'></div>
		)
	};
}

export default Story
