import metadata from "../metadata.json";
import preval from "preval.macro";
import React, {useLayoutEffect, useState, useEffect} from "react";

/*

SETUP:

npm install preval.macro

add/change in /package.json
"build:demo": "node generate-build-id.js && env-cmd -f .env.demo npm run build",
"build:production": "node generate-build-id.js && env-cmd -f .env.production npm run build",

then generate two files:

/generate-build-id.js

var fs = require('fs');
console.log("Incrementing build number...");
fs.readFile('src/metadata.json',function(err,content){
	if(err) throw err;
	var metadata = JSON.parse(content);
	metadata.build = metadata.build + 1;
	fs.writeFile('src/metadata.json',JSON.stringify(metadata),function(err){
		if(err) throw err;
		console.log("Current build number: " + metadata.build);
	})
});


/src/metadata.json

{"version":"0.1","build":2}

=> done!
 */

export function logVersion (appName) {
	let name = appName ?? ''
	const zeroPad = (num, places) => String(num).padStart(places, '0')
	return console.log(name+' v'+metadata.version+'b'+zeroPad(metadata.build, 3), preval`module.exports = new Date().toLocaleString();`)
}



export function Version () {

	const zeroPad = (num, places) => String(num).padStart(places, '0')

	const style = {fontSize: '0.9em', margin: '5px', color: 'grey'};
	
	return (
	<div style={style}>
		v{metadata.version}.b{zeroPad(metadata.build, 3)}, {preval`module.exports = new Date().toLocaleString();`}
	</div>
	)
	
}


