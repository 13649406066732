import create from 'zustand';

export const useStore = create((set, get) => ({


	language: 'EN',
	setLanguage: (language) => set((state) => ({language})),

	// ---------------
	// progress colors
	// ---------------

	colors_desc:    "white",          stroke_desc:      '#ea161a',  opacity_desc_hex: '#ea161aff', opacity_desc: 0.55,
	colors_sel:     "white",          stroke_sel:       '#EA1B5D',
//	colors_0:       "white",          stroke_0:         'gray',
	colors_0:       "#e67e0f",         stroke_0:        '#CD6D0F',
	colors_1:       "#e67e0f",         stroke_1:        '#CD6D0F',
	colors_2:       "#bf0f0f",        stroke_2:         '#910f0f',
	colors_3:       "#4f1303",          stroke_3:       '#360F03',
	colors_notbuilt:'#4f1303',        stroke_notbuilt:  '#360F03',
	colors_built:   '#e67e0f',        stroke_built:     '#CD6D0F',

	setStroke_desc: (stroke_desc) => set((state) => ({stroke_desc})),
	setStroke_sel: (stroke_sel) => set((state) => ({stroke_sel})),
	setColors0: (colors_0) => set((state) => ({colors_0})),
	setColors1: (colors_1) => set((state) => ({colors_1})),
	setColors2: (colors_2) => set((state) => ({colors_2})),
	setColors3: (colors_3) => set((state) => ({colors_3})),
	setColors_notbuilt: (colors_notbuilt) => set((state) => ({colors_notbuilt})),
	setColors_built: (colors_built) => set((state) => ({colors_built})),

	setStroke0: (stroke_0) => set((state) => ({stroke_0})),
	setStroke1: (stroke_1) => set((state) => ({stroke_1})),
	setStroke2: (stroke_2) => set((state) => ({stroke_2})),
	setStroke3: (stroke_3) => set((state) => ({stroke_3})),
	setStroke_notbuilt: (stroke_notbuilt) => set((state) => ({stroke_notbuilt})),
	setStroke_built: (stroke_built) => set((state) => ({stroke_built})),


	// ---------------
	// init
	// ---------------

	initialized3D: false,
	setInitialized3D: (initialized3D) => set((state) => ({initialized3D})),


	isOnboarding: true,
	setIsOnboarding: (d) => set(state => ({isOnboarding: d})),

	// ---------------
	// interaction
	// ---------------

	selected: -1,
	setSelected: (selected) => set((state) => ({selected})),
	selectedElevation: 0,
	setSelectedElevation: (selectedElevation) => set((state) => ({selectedElevation})),

	timesliderStart: -1,
	setTimesliderStart: (timesliderStart) => set((state) => ({timesliderStart})),
	timesliderStop: -1,
	setTimesliderStop: (timesliderStop) => set((state) => ({timesliderStop})),
	remoteAction: 'none',
	setRemoteAction: (remoteAction) => set((state) => ({remoteAction})),

	builtprogress: 1,  // 1... with timeline, 2 ... overview
	setBuiltprogress: (builtprogress) => set((state) => ({builtprogress})),

	isStory: false,
	setIsStory: (isStory) => set((state) => ({isStory})),

	show: '',
	setShow: (show) => set((state) => ({show})),


	// ---------------
	// fetch data
	// ---------------

	bunkers: undefined,
	setBunkers: (bunkers) => set((state) => ({bunkers})),

	content: undefined,
	setContent: (content) => set ((content) => ({content})),
	img: undefined,
	setImg: (img) => set ((img) => ({img})),


 // layers
	selectedlayers: [
		"airport-label",
		"block-number-label",
		"building-number-label",
//				"contour-label",
//				"country-label",
		"ferry-aerialway-label",
		"natural-line-label",
		"natural-point-label",
		"path-pedestrian-label",
		"poi-label",
		"road-label-simple",
//				"settlement-major-label",
		"settlement-minor-label",
		"settlement-subdivision-label",
		"state-label",
		"transit-label",
		"water-line-label",
		"water-point-label",
		"waterway-label",
	]



}));
