// helpers file
import _ from 'lodash';
import {useStore} from './components/store';
import {IT, EN, DE} from "./lang/dict";

export function tt (string) {
	return "trans"+string+"trans"+useStore.getState().language;
}

export function t (arg) {

	const lang = useStore.getState().language;
	const tag=arg;

//	const [t, setT] = useStore('');

//	console.log("--> ", tag);

	var t = "untranslated_"+arg+"_"+useStore.getState().language;

	if (lang === 'IT') {
		Object.keys(IT).forEach((key) => {
			if (key === tag) {
//				console.log("xxx", key, IT[key])
				t = IT[key];
			}
		});
	}
	else if (lang === 'DE') {
		Object.keys(DE).forEach((key) => {
			if (key === tag) {
//				console.log("xxx", key, DE[key])
				t = DE[key];
			}
		});
	}
	else if (lang === 'EN') {
		Object.keys(IT).forEach((key) => {
			if (key === tag) {
//				console.log("xxx", key, EN[key])
				t = EN[key];
			}
		});
	}

//	console.log("trans --> ", tag, t, lang);
	return t
}

export function ColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

// compares 2 objects, returns array of diff keys
export function getObjectDiff (obj1, obj2) {
	const diff = Object.keys(obj1).reduce((result, key) => {
		if (!obj2.hasOwnProperty(key)) {
			result.push(key);
		} else if (_.isEqual(obj1[key], obj2[key])) {
			const resultKeyIndex = result.indexOf(key);
			result.splice(resultKeyIndex, 1);
		}
		return result;
	}, Object.keys(obj2));

	return diff;
}

// get unique array values
export function toUnique(a, b, c) { //array,placeholder,placeholder
	b = a.length;
	// eslint-disable-next-line
	while (c = --b)
		while (c--) a[b] !== a[c] || a.splice(c, 1);
	return a // not needed ;)
}


export const getYear = number => {
	let year;

	let range, diff;

	let lowerQuarterStart = 1880;
	let lowerQuarterStop  = 1940;

	let upperQuarterStart = 1944;
	let upperQuarterStop  = 2020;

	if (number < 1) return 1880;
	if (number > 99) return 2040;

	// number < 20
	if (number < 20) {
		range = lowerQuarterStop-lowerQuarterStart;
		diff = 20-number;
		year = lowerQuarterStop - (range/20)*diff;
	}
	//number > 80
	else if (number > 80) {
		range = upperQuarterStop-upperQuarterStart;
		diff = number-80;
		year = upperQuarterStart + (range/20)*diff;
	}
	// number between 20 - 80
	else {
		range = upperQuarterStart-lowerQuarterStop;
		diff = 20-number;
		year = lowerQuarterStop - (range/60)*diff;
	}

	return year;

};

export const getRoundedYear = number => (Math.round(getYear(number) * 10)) / 10;

export const getNumber = year => {

	let number, range, diff;

	let lowerQuarterStart = 1880;
	let lowerQuarterStop  = 1940;

	let upperQuarterStart = 1944;
	let upperQuarterStop  = 2020;

	if (year < lowerQuarterStart) return 0;
	if (year > upperQuarterStop) return 100;

	// number < 20
	if (year < lowerQuarterStop) {
		range = lowerQuarterStop-lowerQuarterStart;
		diff = year-lowerQuarterStart;
		number = 0 + (20/range)*diff;
	}
	//number > 80
	else if (year > upperQuarterStart) {
		range = upperQuarterStop-upperQuarterStart;
		diff = year - upperQuarterStart;
		number = 80 + (20 / range)*diff;
	}
	// number between 20 - 80
	else {
		range = upperQuarterStart-lowerQuarterStop;
		diff = year - lowerQuarterStop;
		number = 20 + (60/range)*diff;
	}

	return number;

}
